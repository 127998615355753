<script>
  export let providerInput = {};
  import { jsonHeaders } from "../shared/utils.js";
  export let url;
  let amountInINR = 0;

  const onPaymentSuccess = (response) => {
    fetch(url, {
      method: "POST",
      headers: jsonHeaders(),
      body: JSON.stringify(response),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.errors) {
          console.log("Errors occured", resp.errors);
        } else {
          location.href = `/admin/payments/${resp.payment.id}`;
        }
      })
      .catch((err) => {
        console.log("caught err", err);
      });
  };

  const onPayClick = () => {
    const options = {
      key: providerInput["api_key"], // Enter the Key ID generated from the Dashboard
      amount: providerInput["amount"], // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: providerInput["currency"],
      name: providerInput["name"],
      description: providerInput["description"],
      image: providerInput["image"],
      order_id: providerInput["order"]["id"], //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      handler: onPaymentSuccess,
      prefill: {
        name: providerInput["prefill"]["name"],
        email: providerInput["prefill"]["email"],
        contact: providerInput["prefill"]["phone"],
      },
      notes: providerInput["prefill"],
      theme: {
        color: "#3399cc",
      },
    };
    var rzp = new Razorpay(options);
    rzp.on("payment.failed", function (response) {
      // handle error as well
      console.log(response);
    });
    rzp.open();
  };
  $: {
    amountInINR = providerInput.amount / 100;
  }
</script>

<button
  id="rzp-button1"
  on:click|preventDefault={onPayClick}
  class="button-primary">Pay INR {amountInINR}</button
>
