<script>
  import FilterProperty from "./FilterProperty.svelte";
  import { createEventDispatcher } from "svelte";
  export let attributes;

  const dispatch = createEventDispatcher();

  let deactivatedProperties = [];

  const toggleProperty = (event) => {
    let property = event.detail.property;
    let show = event.detail.show;
    let index = deactivatedProperties.indexOf(property);

    if (show) {
      deactivatedProperties = [
        ...deactivatedProperties.slice(0, index),
        ...deactivatedProperties.slice(index + 1, deactivatedProperties.length),
      ];
    } else {
      deactivatedProperties = deactivatedProperties.concat(property);
    }

    dispatch("filter", { deactivatedProperties: deactivatedProperties });
  };
</script>

<div class="col-span-full flex flex-wrap mb-8">
  {#each attributes as attribute (attribute.kind)}
    <div class="mr-16">
      <h4 class="text-gray-500 text-xs font-medium mb-1">{attribute.kind}</h4>
      <ul class="flex mb-4">
        {#each attribute.properties as property}
          <FilterProperty {property} on:toggle={toggleProperty} />
        {/each}
      </ul>
    </div>
  {/each}
</div>
