<script>
  export let shipping_partners;
</script>

<div class="border border-primary-300 bg-white mt-8 rounded p-4 col-span-2">
  <h3 class="text-lg font-semibold mb-4">Delivery Partners</h3>
  <div class="border border-primary-300">
    <input type="text" bind:value={shipping_partners} />
  </div>
</div>
