import EditMaterial from "./EditMaterial.svelte";
import EditMaterialPropertySets from "./EditMaterialPropertySets.svelte";
import AddBrandCatalogForm from "./AddBrandCatalogForm.svelte";
import EditBrandCatalogForm from "./EditBrandCatalogForm.svelte";
import CatalogCategories from "./CatalogCategories.svelte";
import CatalogFolder from "./CatalogFolder.svelte";
import CatalogCategory from "./CatalogCategory.svelte";
import CatalogReorder from "./CatalogReorder.svelte";
import StoreDetails from "./StoreDetails.svelte";
import StoreCatalogs from "./StoreCatalogs.svelte";
import BrandCatalogs from "./BrandCatalogs.svelte";
import StoreBrands from "./StoreBrands.svelte";
import CatalogMount from "./CatalogMount.svelte";
import AddMaterialUploadForm from "./AddMaterialUploadForm";
import ShowMaterialUploadOutput from "./ShowMaterialUploadOutput.svelte";
import ManageAddons from "./ManageAddons.svelte";
import LiteLicensePacks from "./lite_license/LiteLicensePacks.svelte";
import LiteLicensePacksPurchase from "./lite_license/LiteLicensePacksPurchase.svelte";
import EditLiteLicensePack from "./lite_license/EditLiteLicensePack.svelte";
import NewLiteLicensePackPurchase from "./lite_license/NewLiteLicensePackPurchase.svelte";
import ResourceSequences from "./ResourceSequences.svelte";
import TermsConditions from "./TermsConditions.svelte";
import UpiDetails from "./UpiDetails.svelte";
import BankDetails from "./BankDetails.svelte";
import NewMaterial from "./NewMaterial.svelte";
export default {
  EditMaterial,
  EditMaterialPropertySets,
  AddBrandCatalogForm,
  EditBrandCatalogForm,
  CatalogCategories,
  CatalogFolder,
  CatalogCategory,
  CatalogReorder,
  StoreDetails,
  StoreCatalogs,
  BrandCatalogs,
  StoreBrands,
  CatalogMount,
  AddMaterialUploadForm,
  ShowMaterialUploadOutput,
  ManageAddons,
  LiteLicensePacksPurchase,
  LiteLicensePacks,
  EditLiteLicensePack,
  NewLiteLicensePackPurchase,
  ResourceSequences,
  TermsConditions,
  UpiDetails,
  BankDetails,
  NewMaterial,
};
