<script>
  export let data = {},
    titles = [];
  let dataPoints = [];

  const updateData = (data) => {
    dataPoints = data;
  };

  $: updateData(data);
</script>

<table class="w-full">
  <thead>
    <tr class="border-b border-gray-100">
      {#each titles as title, i}
        <th class="font-medium text-xs uppercase px-4 {title.css}"
          >{title.label}</th
        >
      {/each}
    </tr>
  </thead>
  <tbody class="text-sm leading-loose">
    {#each dataPoints as values}
      <tr class="border-b border-gray-100">
        {#each values as value, i}
          <td class="px-4 {titles[i] ? titles[i].css : ''}">{value}</td>
        {/each}
      </tr>
    {/each}
  </tbody>
</table>
