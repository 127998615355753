<script>
  import { jsonHeaders } from "../shared/utils.js";
  import { fade } from "svelte/transition";
  import CategorySelect from "./../categories/CategorySelect.svelte";
  import EditComponentCategory from "./EditComponentCategory.svelte";

  export let categories, formUrl, categoriesUrl;

  let showAddCategory = false,
    selectedCategory = false;

  const showCategoriesList = () => (showAddCategory = true);
  const hideCategoriesList = () => (showAddCategory = selectedCategory = false);
  const url = (category) => `${formUrl}/${category.id}`;
  const selectCategory = (category) => (selectedCategory = category);

  const add = (selectedCategory) => {
    if (formUrl && selectedCategory) {
      fetch(formUrl, {
        method: "POST",
        headers: jsonHeaders(),
        body: JSON.stringify({ category_id: selectedCategory.id }),
      })
        .then((response) => response.json())
        .then((data) => {
          categories = categories.concat(data);
          hideCategoriesList();
        });
    } else {
      hideCategoriesList();
    }
  };

  const remove = (category) => {
    let index = categories.indexOf(category);

    categories = [
      ...categories.slice(0, index),
      ...categories.slice(index + 1, categories.length),
    ];
  };

  $: add(selectedCategory);
</script>

<table class="w-full border border-gray-300 mt-6">
  <thead>
    <tr>
      <th class="px-4 py-2 font-normal text-sm text-primary-500 text-left"
        >Category</th
      >
      <th class="px-4 py-2 font-normal text-sm text-primary-500 text-left"
        >Number of Materials</th
      >
      <th>&nbsp;</th>
    </tr>
  </thead>
  <tbody>
    {#each categories as category (category.id)}
      <EditComponentCategory
        {category}
        {formUrl}
        on:remove={(e) => remove(category)}
      />
    {/each}
    {#if categories.length === 0}
      <tr class="border-t border-gray-300">
        <td colspan="3" class="h-10" />
      </tr>
    {/if}
  </tbody>
</table>

<button class="mt-4 button-subtle" on:click={showCategoriesList}
  >+ Add Category</button
>

{#if showAddCategory}
  <div
    transition:fade
    class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 fixed z-10 top-0 right-0 bottom-0 left-0"
  >
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" />
    <CategorySelect
      bind:activeCategory={selectedCategory}
      url={categoriesUrl}
      bind:show={showAddCategory}
      popupKind="modal"
      cssClasses={"inline-block align-middle w-96"}
    />
  </div>
{/if}
