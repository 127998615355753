<style>
  .container {
    max-width: 36rem;
    margin: 4vh auto 8vh 0;
  }
</style>

<script>
  import { jsonHeaders } from "../shared/utils.js";
  import AddLink from "./AddLink.svelte";
  import FileUpload from "../forms/FileUpload.svelte";

  export let propertySet, attributes, showEditMedium, editingMedium;
  let showAddLink = false;
  let selectedLabel = null;
  let media = propertySet.media || [];
  let labels = [];
  if (attributes && attributes.length > 0) {
    labels = attributes.map((attribute) => attribute.value);
  }

  const getMedium = (label) => {
    return media.find((medium) => {
      return medium.label === label;
    });
  };

  const deleteMedium = (mediumId) => {
    let confirmed = confirm("Are you sure you want to delete this Medium?");

    if (confirmed) {
      let mediumUrl = `/admin/media/${mediumId}`;
      fetch(mediumUrl, {
        method: "DELETE",
        headers: jsonHeaders(),
      }).then((response) => {
        if (response.status === 200) {
          media = media.filter((medium) => medium.id != mediumId);
        }
      });
    }
  };

  const updatePositions = () => {
    let i = 0;
    let mediaWithPosition = [];
    for (; i < media.length; i++) {
      let mediumJSON = {
        id: media[i].id,
        position: i,
      };
      mediaWithPosition[i] = mediumJSON;
    }
    let mediaUrl = `/admin/media/`;
    let payload = { media: mediaWithPosition };
    fetch(mediaUrl, {
      method: "PATCH",
      headers: jsonHeaders(),
      body: JSON.stringify(payload),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
      });
  };
</script>

<AddLink bind:showAddLink bind:media {propertySet} {selectedLabel} />
<div class="container rounded-lg p-10 text-left border border-primary-300">
  <h3 class="text-lg font-semibold mb-2">{propertySet.name}</h3>
  <div class="row-span-3 pt-6 col-start-3">
    {#if labels.length > 0}
      {#each labels as label}
        <div data-test-label={label}>{label}</div>
        {#if getMedium(label)}
          <div
            on:click|preventDefault={() => {
              editingMedium = getMedium(label);
              showEditMedium = true;
            }}
            data-test-medium={getMedium(label).id}
            class="button button-subtle"
          >
            {getMedium(label).id}
          </div>
          <a
            href="#"
            on:click|preventDefault={deleteMedium(getMedium(label).id)}
            data-test-delete-medium="{label}-{getMedium(label).id}"
            class="button button-subtle">Delete</a
          >
        {:else}
          <FileUpload
            attachmentType="PropertySet"
            kind="primary"
            id={propertySet.id}
            {label}
          />
          OR
          <div
            on:click|preventDefault={() => {
              showAddLink = true;
              selectedLabel = label;
            }}
            data-test-add-link="{label}-{propertySet.name}"
            class="button button-subtle"
          >
            Add Link
          </div>
        {/if}
      {/each}
    {:else}
      {#each media as medium}
        <div
          on:click|preventDefault={() => {
            editingMedium = medium;
            showEditMedium = true;
          }}
          data-test-medium={medium.id}
          class="button button-subtle"
        >
          {medium.id}
        </div>
        <a
          href="#"
          on:click|preventDefault={deleteMedium(medium.id)}
          data-test-delete-medium={medium.id}
          class="button button-subtle">Delete</a
        >
      {/each}
      <FileUpload
        attachmentType="PropertySet"
        kind="primary"
        id={propertySet.id}
      />
      OR
      <div
        on:click|preventDefault={() => {
          showAddLink = true;
        }}
        data-test-add-link={propertySet.name}
        class="button button-subtle"
      >
        Add Link
      </div>
    {/if}
    <a
      href="#"
      on:click|preventDefault={updatePositions}
      class="button button-subtle">Update Positions</a
    >
  </div>
</div>
