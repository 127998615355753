<style>
  .active.dark {
    background-color: #525980;
    text-color: #fff;
  }

  .active.light {
    background-color: #ebeafe;
  }
</style>

<script>
  export let field,
    options,
    error,
    style = "dark";
</script>

<div
  class="flex rounded-md bg-primary-100 overflow-hidden {style == 'dark'
    ? 'shadow-inner'
    : 'border border-gray-200'}"
>
  {#each options as option}
    <label
      class="flex-1 relative m-0 cursor-pointer block px-3 py-1 leading-normal rounded-md text-center {field ===
      option.value
        ? 'active'
        : ''} {style} "
    >
      <input
        type="radio"
        id="product_option"
        bind:group={field}
        value={option.value}
        class="absolute opacity-0 left-0"
      />
      <span class="mt-1 {option.icon ? `icon icon-${option.icon}` : ''}"
        >{option.label}</span
      >
    </label>
  {/each}
</div>

{#if error}<span class="field-error">{error}</span>{/if}
