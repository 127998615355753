<script>
  import { jsonHeaders } from "./shared/utils.js";
  export let stockLocation, formUrl, regions, regionsUrl;
  import PostalRegions from "../svelte/PostalRegions.svelte";
  import DeliveryPartners from "../svelte/DeliveryPartners.svelte";
  import { onMount } from "svelte";

  let shipping_partners = "",
    name = "",
    apiInProgress = false,
    errors = {},
    priority;

  const submitData = () => {
    apiInProgress = true;
    errors = {};
    const dataToPost = {
      stock_location: {
        name,
        shipping_partners: shipping_partners
          .split(",")
          .map((item) => item.trim())
          .filter((item) => item.length > 0),
        priority,
      },
    };
    let isNew = stockLocation.id ? false : true;
    let method = "";
    let url = "";
    if (stockLocation.id) {
      method = "PATCH";
      url = `${formUrl}/${stockLocation.id}`;
    } else {
      method = "POST";
      url = formUrl;
    }

    fetch(url, {
      method: method,
      headers: jsonHeaders(),
      body: JSON.stringify(dataToPost),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.stock_location) {
          stockLocation = data.stock_location;
          buildData();
          if (isNew) {
            window.history.replaceState(
              null,
              null,
              `${formUrl}/${stockLocation.id}/edit`
            );
          }
        } else {
          errors = data.errors;
          console.log(data.errors);
        }
      })
      .finally(() => {
        apiInProgress = false;
      });
  };

  const buildData = () => {
    if (stockLocation.preferences) {
      shipping_partners = (
        stockLocation.preferences.shipping_partners || []
      ).join(", ");
      priority = stockLocation.preferences.priority;
    }
    name = stockLocation.name || "";
  };

  onMount(() => {
    buildData();
  });
</script>

<div class="p-8">
  <h2 class="h2 mt-4 mb-8 w-full overflow-hidden">
    <a href="/admin/stock_locations" class="float-left w-8 pt-1">←</a>
    <input
      bind:value={name}
      type="text"
      id="name"
      class="float-left w-10/12 bg-transparent border-none"
    />
    {#if errors.name}<span class="field-error">{errors.name}</span>{/if}
  </h2>
  <div class="grid grid-cols-3 gap-8">
    <div class="col-span-2">
      <DeliveryPartners bind:shipping_partners />
      {#if errors.shipping_partners}<span class="field-error"
          >{errors.shipping_partners}</span
        >{/if}
      {#if stockLocation.id}
        <PostalRegions {regions} bind:stockLocation formUrl={regionsUrl} />
      {/if}
    </div>
    <div class="col-span-1">
      <div
        class="border border-primary-300 bg-white mt-8 rounded p-4 col-span-2"
      >
        <h3 class="text-lg font-semibold mb-4">Priority</h3>
        <div class="border border-primary-300">
          <input type="text" id="priority" bind:value={priority} />
          {#if errors.priority}<span class="field-error">{errors.priority}</span
            >{/if}
        </div>
      </div>
    </div>
  </div>
  {#if apiInProgress}
    <p>Updating...</p>
  {:else}
    <a
      href="#"
      on:click|preventDefault={submitData}
      class="button button-primary mt-4">Save</a
    >
  {/if}
</div>
