<script>
  import DatePicker from "./forms/DatePicker.svelte";
  import Field from "./forms/Field.svelte";

  export let form_values, filter_options;

  const clearTimeframe = () => {
    form_values.start_date = null;
    form_values.end_date = null;
  };
</script>

<input type="hidden" value={form_values.tab_name} name="tab_name" />
<div class="grid gap-4 grid-cols-5 w-full">
  <Field
    bind:field={form_values.email}
    inputClass="input-sm"
    label="Email"
    name="email"
  />

  <div class=" mr-4 hidden">
    <label for="" class=" text-primary-600">Payment Method</label>
    <select
      bind:value={form_values.payment_method}
      name="payment_method"
      class=""
    >
      <option value="">All</option>
      {#each filter_options.filters.filter_payment_methods as option}
        <option value={option}>
          {option}
        </option>
      {/each}
    </select>
  </div>

  <div class=" mr-4 ">
    <label for="" class=" text-primary-600">Delivery Method</label>
    <select
      bind:value={form_values.delivery_method}
      name="delivery_method"
      class=""
    >
      <option value="">All</option>
      {#each filter_options.filters.filter_delivery_methods as option}
        <option value={option}>
          {option}
        </option>
      {/each}
    </select>
  </div>

  <div class=" mr-4 hidden ">
    <label for="" class=" text-primary-600">Tracking Status</label>
    <select
      bind:value={form_values.tracking_status}
      name="tracking_status"
      class=""
    >
      <option value="">All</option>
      {#each filter_options.filters.filter_tracking_status as option}
        <option value={option.key}>
          {option.value}
        </option>
      {/each}
    </select>
  </div>

  <div class=" mr-4 ">
    <label for="" class=" text-primary-600">Per page</label>
    <select
      bind:value={form_values.per_page_count}
      name="per_page_count"
      class=""
    >
      {#each filter_options.filters.per_page_count as option}
        <option value={option}>
          {option}
        </option>
      {/each}
    </select>
  </div>
</div>

<input
  type="date"
  name="start_date"
  bind:value={form_values.start_date}
  class="hidden"
/>
<input
  type="date"
  name="end_date"
  bind:value={form_values.end_date}
  class="hidden"
/>
<Field
  bind:field={form_values.sku}
  inputClass="input-sm"
  label="SKU"
  name="sku"
/>

<div class="actions col-span-8 hidden">
  <div class="flex">
    <label for="" class=" flex-auto text-primary-600">Timeframe</label>
    <a
      href="#"
      on:click|stopPropagation={clearTimeframe}
      class="mt-2 mb-1 text-primary-800 underline">Clear</a
    >
  </div>

  <DatePicker
    bind:start_date={form_values.start_date}
    bind:end_date={form_values.end_date}
  />
</div>

{#if filter_options.filters.shipment_statuses && filter_options.filters.shipment_statuses.length > 0}
  <div class="actions col-span-8">
    <div class="flex">
      <label for="" class=" text-primary-600">Shipment Status</label>
      <select
        bind:value={form_values.shipment_status}
        name="shipment_status"
        class=""
      >
        {#each filter_options.filters.shipment_statuses as option}
          <option value={option}>
            {option}
          </option>
        {/each}
      </select>
    </div>
  </div>
{/if}
