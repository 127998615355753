<script>
  import { jsonHeaders } from "../shared/utils.js";
  import ProductTabs from "./ProductTabs.svelte";
  import List from "../property_sets/List.svelte";
  import Table from "../property_sets/Table.svelte";
  import Media from "../property_sets/Media.svelte";
  import ChooseTemplate from "../property_sets/ChooseTemplate.svelte";
  import FromScratch from "../property_sets/FromScratch.svelte";
  import EditMedium from "../property_sets/EditMedium.svelte";

  export let product, productKinds, url, propertySetsUrl, propertySets;

  let showChooseTemplate = false,
    showFromScratch = false,
    showEditMedium = false,
    editingMedium = null,
    properties = productKinds.find((kind) => kind.name === product.kind),
    productPropertySets = propertySets.filter(
      (propertySet) => propertySet.product_id == product.id
    ),
    propertySetTemplates = propertySets.filter(
      (propertySet) => propertySet.is_template == true
    ),
    productUrl = `${url}/${product.id}`;

  const deletePropertySet = (propertySetId) => {
    let confirmed = confirm(
      "Are you sure you want to delete this PropertySet?"
    );

    if (confirmed) {
      let taxCodeUrl = `${propertySetsUrl}/${propertySetId}/`;
      fetch(taxCodeUrl, {
        method: "DELETE",
        headers: jsonHeaders(),
      }).then((response) => {
        if (response.status === 200) {
          productPropertySets = productPropertySets.filter(
            (propertySet) => propertySet.id != propertySetId
          );
        }
      });
    }
  };
</script>

<div class="p-8 grid grid-cols-3 gap-0">
  <ChooseTemplate
    bind:showChooseTemplate
    bind:productPropertySets
    {product}
    {propertySetTemplates}
    {propertySetsUrl}
  />
  <FromScratch
    bind:showFromScratch
    bind:productPropertySets
    {propertySetsUrl}
    productId={product.id}
  />
  <EditMedium bind:showEditMedium bind:editingMedium />
  <h2 class="h2 mt-4 mb-8 w-full overflow-hidden">
    <a href="/admin/products" class="float-left w-8 pt-1">←</a>
    <input
      placeholder="Enter Product Name"
      bind:value={product.name}
      type="text"
      id="name"
      class="float-left w-10/12 bg-transparent border-none"
      disabled
    />
  </h2>

  <ProductTabs
    url={productUrl}
    selectedTab={"property_sets"}
    hasComponents={properties.has_components}
  />

  <div
    class="col-span-3 grid grid-cols-3 gap-y-8 gap-x-16 bg-white text-primary-800 border border-primary-300 p-8"
  >
    <div class="col-span-2">
      <button
        data-test-choose-template="choose-template"
        on:click|preventDefault={() => (showChooseTemplate = true)}
      >
        Choose a template
      </button>
    </div>
    <div class="col-span-2">OR</div>
    <div class="col-span-2">
      <button
        data-test-from-scratch="from-scratch"
        on:click|preventDefault={() => (showFromScratch = true)}
      >
        Create from scratch
      </button>
    </div>
  </div>

  <div
    class="col-span-3 grid grid-cols-3 gap-y-8 gap-x-16 bg-white text-primary-800 border border-primary-300 p-8"
  >
    {#each productPropertySets as productPropertySet}
      <div class="col-span-2">
        <h3
          class="h2 mt-4 mb-8 w-full overflow-hidden"
          data-test-product-property-set={productPropertySet.name}
        >
          {productPropertySet.name}
        </h3>
        <a
          href="#"
          on:click|preventDefault={deletePropertySet(productPropertySet.id)}
          data-test-delete-product-property-set={productPropertySet.name}
          class="button button-subtle">Delete</a
        >
        {#if productPropertySet.kind == "list"}
          <List
            propertySet={productPropertySet}
            attributes={productPropertySet.info}
            {propertySetsUrl}
            autoSave={true}
          />
        {:else if productPropertySet.kind === "table"}
          <Table
            propertySet={productPropertySet}
            attributes={productPropertySet.info}
            {propertySetsUrl}
            autoSave={true}
          />
        {:else if productPropertySet.kind === "media"}
          <Media
            propertySet={productPropertySet}
            attributes={productPropertySet.info}
            bind:showEditMedium
            bind:editingMedium
          />
        {/if}
      </div>
    {/each}
  </div>
</div>
