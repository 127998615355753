<style>
  .kind-modal {
    height: 80vh;
  }

  .kind-modal .categories-container {
    height: calc(80vh - 5.9rem);
    overflow: auto;
  }

  .kind-select {
    height: 60vh;
    overflow: auto;
    margin-top: -75px;
  }
</style>

<script>
  import { jsonHeaders } from "../shared/utils.js";

  import CategorySelectFolder from "./CategorySelectFolder.svelte";
  import { onMount } from "svelte";
  export let activeCategory,
    product,
    productUrl,
    url,
    show,
    cssClasses,
    popupKind;

  let searchInput,
    apiUpdateInProgress = false;
  onMount(() => {});
  const hideForm = () => (show = false);
  const selectProductCategory = () => {
    apiUpdateInProgress = true;
    product.category_id = activeCategory.id;
    product.move_to_category_id = activeCategory.id;
    product.category = activeCategory;
    console.log(product);
    fetch(`${productUrl}`, {
      method: "PATCH",
      headers: jsonHeaders(),
      body: JSON.stringify({
        product: product,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if ("errors" in data) {
          errors = data.errors;
        } else {
          location.reload();
        }
      })
      .catch((error) => {
        console.log("product category errors", error);
      })
      .finally(() => {
        apiUpdateInProgress = false;
      });
  };
</script>

<div
  on:click={hideForm}
  class="block fixed inset-0 bg-black bg-opacity-75 {popupKind === 'modal'
    ? 'opacity-75'
    : 'opacity-5'}"
/>

<div
  class="{cssClasses} kind-{popupKind} container bg-white rounded-lg text-left shadow-xl transform transition-all border border-primary-200"
>
  {#if popupKind === "modal"}
    <button
      on:click={hideForm}
      class="absolute top-0 right-0 -mt-2 -mr-2 p-1 text-center h-8 w-8 icon icon-cross bg-50 bg-white rounded-full border border-primary-200"
      >close</button
    >
  {/if}
  <form on:submit|preventDefault={selectProductCategory} action=".">
    <div
      class={apiUpdateInProgress
        ? "absolute z-10 top-0 left-0 bottom-0 right-0 bg-white opacity-50"
        : ""}
    >
      <div
        class="loader loader-default {apiUpdateInProgress ? 'is-active' : ''}"
        data-text="Saving Category..."
        data-blink
      />
    </div>
    <div class="py-1 px-2 pb-4 border-b border-primary-200">
      <h3 class="text-sm p-1">Select Product Line</h3>
    </div>
    <div class="pl-4 max-h-96 overflow-y-scroll categories-container">
      <CategorySelectFolder bind:activeCategory {url} showRadioButtons="true" />
    </div>
    {#if !apiUpdateInProgress}
      <div class="bg-primary-100 p-6">
        <input type="submit" value="Apply" class="button-primary" />
        <a href="#" on:click={hideForm} class="button-secondary ml-6">Cancel</a>
      </div>
    {/if}
  </form>
</div>
