<script>
  import Field from "./../forms/Field.svelte";
  import debounce from "lodash/debounce";
  export let filters, url, account_id, account_details, state;
  let selectedAccountType,
    accountSearchResults = [],
    searchTerm = "";
  let debounceQuery = debounce(() => {
    if (state === "search-account") {
      console.log(`Need to search - ${searchTerm}`);
      const searchUrl = `${url}?query=${searchTerm}`;
      fetch(searchUrl)
        .then((resp) => resp.json())
        .then((dat) => {
          accountSearchResults = (dat || { accounts: [] }).accounts;
          console.log(accountSearchResults);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, 300);
  const onAccountClick = (account) => {
    onAccountSelected(account.id, account);
  };
  const onAccountSelected = (accountId, accountDetails) => {
    filters.account_id = accountId;
    account_id = accountId;
    account_details = accountDetails;
    state = "account-selected";
    searchTerm = "";
    selectedAccountType = "";
  };
  const onSelectAccount = () => {
    state = "select-account";
    if (account_id === "all") {
      selectedAccountType = "all";
    } else {
      selectedAccountType = "individual";
    }
    searchTerm = "";
  };
  const onAccountTypeSelectedAll = () => {
    selectedAccountType = "all";
  };

  const onAccountTypeSelectedIndividual = () => {
    selectedAccountType = "individual";
  };
  const onSearchAccount = () => {
    if (selectedAccountType === "all") {
      onAccountSelected("all", {});
    } else {
      state = "search-account";
    }
  };
  const onCancelSelectAccountTpe = () => {
    onAccountSelected(account_id, account_details);
  };
  const onCancelSearchAccount = () => {
    onSelectAccount();
  };

  $: {
    debounceQuery(searchTerm);
  }
</script>

<div class="col-span-12">
  {#if state === "account-selected"}
    <div>
      <p>Account Selected</p>
      <p>Account: {account_id}</p>
      {#if account_details.name && account_details.address_string}
        <p>
          Account Details : {account_details.name} - {account_details.address_string}
        </p>
      {/if}
    </div>
    <div on:click={onSelectAccount}>
      <p>Switch</p>
    </div>
  {:else if state === "select-account"}
    <div on:click={onAccountTypeSelectedAll}>
      <p>All Stores {selectedAccountType === "all" ? "✅" : ""}</p>
    </div>
    <div on:click={onAccountTypeSelectedIndividual}>
      <p>Individual Store {selectedAccountType === "individual" ? "✅" : ""}</p>
    </div>
    <div>
      <div on:click={onSearchAccount}><p>View</p></div>
      <div on:click={onCancelSelectAccountTpe}><p>Cancel</p></div>
    </div>
  {:else if state === "search-account"}
    {#if selectedAccountType == "individual"}
      <div>
        <p>Individual Store</p>
      </div>
      <div on:click={onCancelSearchAccount}>
        <p>Switch</p>
      </div>
    {/if}
    <div>
      <Field bind:field={searchTerm} name="search" label="Search Account" />
    </div>
    <div>
      {#each accountSearchResults as account (account.id)}
        <div on:click={() => onAccountClick(account)}>
          <p>Name: {account.name}</p>
          <p>Address: {account.address_string}</p>
        </div>
      {/each}
    </div>
  {/if}
</div>
