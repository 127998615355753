<script>
  import NewPropertySet from "./NewPropertySet.svelte";
  import { fade } from "svelte/transition";

  export let showFromScratch, productPropertySets, propertySetsUrl, productId;
</script>

{#if showFromScratch}
  <div
    class="fixed z-10 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      />

      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
      />
      <div class="float-right relative">
        <div
          transition:fade
          class="flex items-center justify-center bg-opacity-75 fixed z-10 top-0 right-0 bottom-0 left-0"
        >
          <div
            class="container w-128 p-8 bg-white rounded-lg text-left shadow-xl transform transition-all border border-primary-200"
          >
            <a
              href="#"
              on:click={(e) => {
                showFromScratch = false;
              }}
              class="absolute top-0 right-0 -mt-2 -mr-2 p-1 text-center h-8 w-8 bg-white rounded-full shadow-md"
              data-test-close-from-scratch="close-from-scratch">×</a
            >
            <h2 class="font-medium capitalize text-primary-800">
              Create Property Set
            </h2>

            <NewPropertySet
              url={propertySetsUrl}
              bind:showFromScratch
              bind:productPropertySets
              {productId}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
{/if}
