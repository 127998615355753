<style>
  .container {
    max-width: 48rem;
    margin: 12vh auto 8vh auto;
  }
</style>

<script>
  import { flip } from "svelte/animate";
  import { createEventDispatcher } from "svelte";
  import TagField from "./../forms/TagField.svelte";

  export let attributes;
  let hovering = false;

  const dispatch = createEventDispatcher();

  attributes = [{ kind: "", properties: [] }];

  const submitForm = () => dispatch("message", {});

  const refresh = () => (attributes = attributes);
  const addAttribute = () =>
    (attributes = attributes.concat({ kind: "", properties: [] }));

  const removeAttribute = (i) => {
    attributes.splice(i, 1);
    refresh();
  };

  const moveAttributeUp = (i) => {
    attributes.splice(i - 1, 0, attributes.splice(i, 1)[0]);
    refresh();
  };

  const drop = (event, target) => {
    event.dataTransfer.dropEffect = "move";
    const start = parseInt(event.dataTransfer.getData("text/plain"));
    const newAttributeslist = attributes;

    if (start < target) {
      newAttributeslist.splice(target + 1, 0, newAttributeslist[start]);
      newAttributeslist.splice(start, 1);
    } else {
      newAttributeslist.splice(target, 0, newAttributeslist[start]);
      newAttributeslist.splice(start + 1, 1);
    }
    attributes = newAttributeslist;
    hovering = null;
  };

  const dragstart = (event, i) => {
    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.dropEffect = "move";
    const start = i;
    event.dataTransfer.setData("text/plain", start);
  };
</script>

<div class="container rounded-lg p-10 text-left border border-primary-300">
  <h3 class="text-lg font-semibold mb-2">Add Variants Attributes</h3>

  <ul class="my-4 py-4">
    {#each attributes as { kind, properties }, i (i)}
      <li
        class="flex mb-2"
        animate:flip
        draggable={true}
        on:dragstart={(event) => dragstart(event, i)}
        on:drop|preventDefault={(event) => drop(event, i)}
        ondragover="return false"
        on:dragenter={() => (hovering = i)}
        class:bg-secondary-200={hovering === i}
      >
        <span class="icon icon-drag mt-10 mr-2 -ml-6 cursor-move">move</span>

        <div class="w-64 mr-2">
          <label for="" class="label text-xs font-medium">
            Attribute type
            {#if i === 0}
              (size, color etc.)
            {/if}
          </label>
          <input
            bind:value={kind}
            type="text"
            name="kind"
            class="block w-full"
          />
        </div>

        <div class="w-3/5 mr-2">
          <label for="" class="label text-xs text-medium">Properties</label>
          <TagField bind:tags={properties} />
        </div>

        <span class="inline-block w-1/6">
          {#if attributes.length > 1}
            <button
              on:click|preventDefault={(event) => removeAttribute(i)}
              class="icon icon-trash mt-9 ml-3">delete</button
            >
          {/if}
          {#if i + 1 === attributes.length}
            <button
              on:click|preventDefault={addAttribute}
              class="icon icon-plus mt-9 ml-3 -mr-8">add</button
            >
          {/if}
        </span>
      </li>
    {/each}
  </ul>
</div>
