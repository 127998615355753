<script>
  import { jsonHeaders } from "../../../shared/utils.js";
  export let payment, url;
  let hasReminderSent = false;
  const sendReminder = () => {
    const confirmed = window.confirm(
      "Are you sure you want to send reminder through sms and email?"
    );

    if (confirmed) {
      fetch(`${url}/${payment.payable_id}/send_reminder_link_payment`, {
        method: "POST",
        headers: jsonHeaders(),
        body: JSON.stringify({
          payment_id: payment.id,
        }),
      }).then(() => {
        hasReminderSent = true;
      });
    }
  };

  const reconcilePayment = () => {
    const confirmed = window.confirm(
      "Are you sure you want to check current status with provider?"
    );
    if (confirmed) {
      fetch(`${url}/${payment.payable_id}/reconcile_link_payment`, {
        method: "POST",
        headers: jsonHeaders(),
        body: JSON.stringify({
          payment_id: payment.id,
        }),
      }).then(() => {
        location.href = url;
      });
    }
  };
</script>

<ul class=" py-6 mt-0 flex">
  <li class="mr-12">
    <p class="text-xs text-primary-500 mb-0">Payment By Link</p>
    <p class="mb-0">Razorpay</p>
  </li>
  <li class="flex-auto">
    <p class="text-xs text-primary-500 mb-0">Status</p>
    <p class="mb-0">{payment.status}</p>
  </li>

  <li class="flex-auto">
    <p class="text-xs text-primary-500 mb-0">Link</p>
    <p class="mb-0">{payment.provider_details.payment_link}</p>
  </li>
  <li>
    <a
      href="#"
      class="button-transparant underline mx-4"
      on:click|preventDefault={sendReminder}>Send reminder</a
    >
    {#if hasReminderSent}
      <p>Reminder sent!</p>
    {/if}
    <a
      href="#"
      class="button-primary mx-4"
      on:click|preventDefault={reconcilePayment}>Reconcile status</a
    >
  </li>
</ul>
