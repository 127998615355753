<script>
  export let selectedTab,
    hasComponents,
    url,
    showMarketing = true;
</script>

<ul class="tabs col-span-3">
  <li
    class="inline-block px-4 {selectedTab === 'details'
      ? 'text-gray-900 border-b-2 border-gray-900 font-medium'
      : 'text-gray-500'}"
  >
    <a href="{url}/edit">Details</a>
  </li>
  {#if hasComponents}<li
      class="inline-block px-4 text-gray-500 {selectedTab === 'components'
        ? 'text-gray-900 border-b-2 border-gray-900 font-medium'
        : 'text-gray-500'}"
    >
      <a href="./components">Components</a>
    </li>{/if}
  <li
    class="inline-block px-4 {selectedTab === 'property_sets'
      ? 'text-gray-900 border-b-2 border-gray-900 font-medium'
      : 'text-gray-500'}"
  >
    <a href="{url}/edit?tab=property_sets">Properties & Media</a>
  </li>
  {#if showMarketing}
    <li
      class="inline-block px-4 {selectedTab === 'marketing'
        ? 'text-gray-900 border-b-2 border-gray-900 font-medium'
        : 'text-gray-500'}"
    >
      <a href="{url}/edit?tab=marketing">Marketing</a>
    </li>
  {/if}
</ul>
