<script>
  import { jsonHeaders } from "../shared/utils.js";
  import { fade } from "svelte/transition";
  import Field from "../forms/Field.svelte";

  export let showForm, taxNames, url;

  let countryCode = "IN";
  let errors = {};
  let taxCode = {};
  let igst = "";
  let igst_error = "";
  let cgst = "";
  let cgst_error = "";
  let sgst = "";
  let sgst_error = "";
  let cess = "";
  let cess_error = "";
  const createTaxCode = () => {
    const taxCode_obj = {
      name: taxCode.name,
      country_code: countryCode,
      tax_rates: {
        igst: igst,
        cgst: cgst,
        sgst: sgst,
        cess: cess,
      },
    };
    fetch(url, {
      method: "POST",
      headers: jsonHeaders(),
      body: JSON.stringify({ tax_code: taxCode_obj }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if ("errors" in data) {
          errors = data.errors;
          if (errors.tax_rates) {
            igst_error = errors.tax_rates[0].igst;
            cgst_error = errors.tax_rates[0].cgst;
            sgst_error = errors.tax_rates[0].sgst;
            cess_error = errors.tax_rates[0].cess;
          }
        } else {
          errors = {};
          showForm = false;
          window.location.href = url;
          console.log(data);
        }
      });
  };
</script>

{#if showForm}
  <div
    class="fixed z-10 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      />

      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
      />
      <div class="float-right relative">
        <div
          transition:fade
          class="flex items-center justify-center bg-opacity-75 fixed z-10 top-0 right-0 bottom-0 left-0"
        >
          <form
            on:submit|preventDefault
            class="container md:w-6/12 lg:w-5/12 bg-white rounded-lg text-left shadow-xl transform transition-all border border-primary-200"
          >
            <h2
              class="font-medium text-lg capitalize  border-b border-gray-200 p-8 "
            >
              New Tax Code
            </h2>
            <div class="p-8">
              <p class="mb-8">
                <Field
                  bind:field={taxCode.name}
                  label="Tax Code Name"
                  name="taxCode[name]"
                  error={errors.name}
                />
              </p>
              {#each taxNames.IN as taxName}
                {#if taxName == "igst"}
                  <div class="flex content-start w-9/12">
                    <div class="mr-12 mt-2">
                      <p class="text-sm text-primary-600">Tax Name</p>
                      <p class="font-bold text-primary-600 text-xs mt-3">
                        IGST
                      </p>
                    </div>
                    <div class="flex-auto relative">
                      <Field
                        bind:field={igst}
                        label={"Tax Percentage"}
                        name="igst"
                        error={igst_error}
                      />
                    </div>
                  </div>
                {/if}
                {#if taxName == "cgst"}
                  <div class="flex content-start mt-4  w-9/12">
                    <div class="mr-12 mt-2">
                      <p class="text-sm text-primary-600">Tax Name</p>
                      <p class="font-bold text-primary-600 text-xs mt-3">
                        CGST
                      </p>
                    </div>
                    <div class="flex-auto relative">
                      <Field
                        bind:field={cgst}
                        label={"Tax Percentage"}
                        name="cgst"
                        error={cgst_error}
                      />
                    </div>
                  </div>
                {/if}
                {#if taxName == "sgst"}
                  <div class="flex content-start mt-4 w-9/12">
                    <div class="mr-12 mt-2">
                      <p class="text-sm text-primary-600">Tax Name</p>
                      <p class="font-bold text-primary-600 text-xs mt-3">
                        SGST
                      </p>
                    </div>
                    <div class="flex-auto relative">
                      <Field
                        bind:field={sgst}
                        label={"Tax Percentage"}
                        name="sgst"
                        error={sgst_error}
                      />
                    </div>
                  </div>
                {/if}
                {#if taxName == "cess"}
                  <div class="flex content-start mt-4 w-9/12">
                    <div class="mr-12 mt-2">
                      <p class="text-sm text-primary-600">Tax Name</p>
                      <p class="font-bold text-primary-600 text-xs mt-3">
                        CESS
                      </p>
                    </div>
                    <div class="flex-auto relative">
                      <Field
                        bind:field={cess}
                        label={"Tax Percentage"}
                        name="cess"
                        error={cess_error}
                      />
                    </div>
                  </div>
                {/if}
              {/each}
            </div>
            <div class="px-8 py-4 bg-primary-100 border-t border-primary-200">
              <button
                on:click={createTaxCode}
                class="button-primary mr-6"
                id="create-taxcode">Save</button
              >
              <a
                href="#"
                on:click={(e) => {
                  showForm = false;
                }}
                class="button-transparent"
                id="close-add-taxcode">Cancel</a
              >
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
{/if}
