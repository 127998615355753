<script>
  import DatePicker from "./forms/DatePicker.svelte";
  import Field from "./forms/Field.svelte";

  export let form_values, filter_options;

  const clearTimeframe = () => {
    form_values.start_date = null;
    form_values.end_date = null;
  };
</script>

<div class="grid grid-cols-4 gap-4">
  <div class="actions col-span-4">
    <div class="flex w-60">
      <label for="" class=" flex-auto text-primary-600">Timeframe</label>
      <a
        href="#"
        on:click|stopPropagation={clearTimeframe}
        class="mt-2 mb-1 text-primary-800 underline">Clear</a
      >
    </div>

    <DatePicker
      bind:start_date={form_values.start_date}
      bind:end_date={form_values.end_date}
    />
  </div>
  <div class="col-span-4 grid grid-cols-4 gap-4">
    <Field
      bind:field={form_values.email}
      inputClass="input-sm"
      label="Email"
      name="email"
    />

    <Field
      bind:field={form_values.phone_number}
      inputClass="input-sm"
      label="Phone number"
      name="phone_number"
    />

    <Field
      bind:field={form_values.coupon_code}
      inputClass="input-sm"
      label="Coupon Code"
      name="coupon_code"
    />

    <Field
      bind:field={form_values.sku}
      inputClass="input-sm"
      label="SKU"
      name="sku"
    />
  </div>

  <div>
    <label for="" class=" text-primary-600">Payment Method</label>
    <select bind:value={form_values.payment_type} name="payment_type" class="">
      <option value="">All</option>
      {#each filter_options.filters.filter_payment_types as option}
        <option value={option}>
          {option}
        </option>
      {/each}
    </select>
  </div>
  <input
    type="date"
    name="start_date"
    bind:value={form_values.start_date}
    class="hidden"
  />
  <input
    type="date"
    name="end_date"
    bind:value={form_values.end_date}
    class="hidden"
  />

  <div>
    <label for="" class="w-24 mr-4 text-primary-600">Order Status</label>
    <select bind:value={form_values.order_status} name="order_status" class="">
      <option value="">All</option>
      {#each filter_options.filters.filter_order_options as option}
        <option value={option}>
          {option}
        </option>
      {/each}
    </select>
  </div>

  <div>
    <label for="" class=" text-primary-600">Per page</label>
    <select
      bind:value={form_values.per_page_count}
      name="per_page_count"
      class=""
    >
      {#each filter_options.filters.per_page_count as option}
        <option value={option}>
          {option}
        </option>
      {/each}
    </select>
  </div>

  <div class="">
    <label for="" class="w-24 mr-4 text-primary-600">Payment</label>
    <select
      bind:value={form_values.payment_status}
      name="payment_status"
      class=""
    >
      <option value="">All</option>
      {#each filter_options.filters.filter_payment_options as option}
        <option value={option}>
          {option}
        </option>
      {/each}
    </select>
  </div>
</div>
