<script>
  import map from "lodash/map";
  import LineGraph from "./LineGraph.svelte";
  import RadioButtonsPill from "../forms/RadioButtonsPill.svelte";
  import Table from "./Table.svelte";
  import { jsonHeaders, dasherize } from "../shared/utils.js";
  import Tabs from "../shared/Tabs.svelte";

  export let title, url, colspan, filters;

  let show = "graph",
    breakup = "day",
    metadata,
    data = [],
    selectedTab,
    tableData;

  let displayOptions = [
    { value: "graph", label: "graph", icon: "graph" },
    { value: "table", label: "table", icon: "table" },
  ];

  let titles = [
    { label: "Current Period", css: "text-left" },
    { label: "", css: "text-right" },
    { label: "Previous Period", css: "text-left" },
    { label: "", css: "text-right" },
  ];

  const params = (filters) =>
    map(filters, (value, key) => {
      if (value) {
        return `${key}=${value}`;
      } else {
        return false;
      }
    })
      .filter((item) => item)
      .join("&");

  const dataUrl = (filters) => `${url}?${params(filters)}`;

  const getData = (filters) => {
    fetch(dataUrl(filters), {
      method: "GET",
      headers: jsonHeaders(),
    })
      .then((response) => response.json())
      .then((json) => {
        metadata = json.metadata;
        data = json.data;
        selectedTab = json.data[0];
      });
  };

  const updateTableData = (selectedTab) => {
    if (selectedTab) {
      tableData = selectedTab.current.map((p, i) => [
        formatDate(p.timestamp),
        p.value,
        selectedTab.previous[i] &&
          formatDate(selectedTab.previous[i].timestamp),
        selectedTab.previous[i] && selectedTab.previous[i].value,
      ]);
    }
  };

  const formatDate = (dateString) => {
    let date = new Date(dateString);
    let day = date.getDate();
    let month = date.toLocaleString("default", { month: "long" });
    return `${day} ${month}`;
  };

  $: getData(filters);
  $: updateTableData(selectedTab);
</script>

<div
  id={`chart-container-${dasherize(title)}`}
  class="col-span-{colspan} border border-gray-200 p-4 text-primary-800 rounded"
>
  <div class="controls float-right w-72 grid grid-cols-2 gap-3">
    <RadioButtonsPill
      options={displayOptions}
      bind:field={show}
      style="light"
    />
    <select bind:value={filters.period} class="select-small" name="" id="">
      <option value="1d">Daily</option>
      <option value="1w">Weekly</option>
      <option value="1M">Monthly</option>
    </select>
  </div>

  {#if data.length == 1}
    <h3 class="text-lg font-medium mb-4">{title}</h3>
  {:else}
    <Tabs tabs={data} bind:selectedTab />
  {/if}

  {#if metadata}
    {#if show === "graph"}
      <LineGraph
        id={`line-graph-${dasherize(selectedTab.title)}`}
        {url}
        {metadata}
        callouts={selectedTab.callouts}
        current={selectedTab.current}
        previous={selectedTab.previous}
        maxValue={selectedTab.max_value}
        title={selectedTab.title}
      />
    {:else}
      <Table {url} data={tableData} {titles} />
    {/if}
  {/if}
</div>
