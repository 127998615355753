<script>
  import { jsonHeaders } from "../../shared/utils.js";
  import Field from "../../forms/Field.svelte";
  export let resourceSequences, resourceSequencesUrl;
  let apiInProgress;
  let quotationObj = {
      quotation: resourceSequences.find(
        (resourceSequence) => resourceSequence.kind === "Vizstore::Quotation"
      ),
      errors: null,
    },
    quotationUrl = `${resourceSequencesUrl}/${quotationObj.quotation.id}`,
    quotationInvoiceObj = {
      quotationInvoice: resourceSequences.find(
        (resourceSequence) =>
          resourceSequence.kind === "Vizstore::QuotationInvoice"
      ),
      errors: null,
    },
    quotationInvoiceUrl = `${resourceSequencesUrl}/${quotationInvoiceObj.quotationInvoice.id}`,
    deliveryChallanObj = {
      deliveryChallan: resourceSequences.find(
        (resourceSequence) =>
          resourceSequence.kind === "Vizstore::DeliveryChallan"
      ),
      errors: null,
    },
    deliveryChallanUrl = `${resourceSequencesUrl}/${deliveryChallanObj.deliveryChallan.id}`;

  const updateResourceSequences = () => {
    apiInProgress = true;
    // Quotation ----
    let prom1 = fetch(quotationUrl, {
      method: "PATCH",
      headers: jsonHeaders(),
      body: JSON.stringify({
        prefix: quotationObj.quotation.prefix,
        next_number: quotationObj.quotation.next_number,
      }),
    })
      .then((response) =>
        response.json().then((data) => ({
          code: response.status,
          responseJson: data,
        }))
      )
      .then((data) => {
        if (data.code != 200) {
          quotationObj.errors = data.responseJson.errors;
        }
      });

    // Invoice ----
    let prom2 = fetch(quotationInvoiceUrl, {
      method: "PATCH",
      headers: jsonHeaders(),
      body: JSON.stringify({
        prefix: quotationInvoiceObj.quotationInvoice.prefix,
        next_number: quotationInvoiceObj.quotationInvoice.next_number,
      }),
    })
      .then((response) =>
        response.json().then((data) => ({
          code: response.status,
          responseJson: data,
        }))
      )
      .then((data) => {
        if (data.code != 200) {
          quotationInvoiceObj.errors = data.responseJson.errors;
        }
      });

    // DeliveryChallan ----
    let prom3 = fetch(deliveryChallanUrl, {
      method: "PATCH",
      headers: jsonHeaders(),
      body: JSON.stringify({
        prefix: deliveryChallanObj.deliveryChallan.prefix,
        next_number: deliveryChallanObj.deliveryChallan.next_number,
      }),
    })
      .then((response) =>
        response.json().then((data) => ({
          code: response.status,
          responseJson: data,
        }))
      )
      .then((data) => {
        if (data.code != 200) {
          deliveryChallanObj.errors = data.responseJson.errors;
        }
      });
    Promise.all([prom1, prom2, prom3]).finally(() => {
      apiInProgress = false;
    });
  };
</script>

<div class="border-t border-gray-300 pt-12 mt-12">
  <h3 class="text-lg font-semibold mb-4">Prefixes</h3>
  <form on:submit|preventDefault={updateResourceSequences} action=".">
    <div class="grid grid-cols-2 gap-2 ">
      <div>
        <h3 class="text-sm font-semibold uppercase text-gray-600">
          Quotation Number Prefix
        </h3>
        <div class="flex items-end">
          <Field
            bind:field={quotationObj.quotation.prefix}
            containerCss="w-2/5"
          />
          <Field
            bind:field={quotationObj.quotation.next_number}
            containerCss="ml-4 w-2/5"
          />
        </div>
        {#if quotationObj.errors}
          <span class="field-error">{JSON.stringify(quotationObj.errors)}</span>
        {/if}
      </div>
      <div>
        <h3 class="text-sm font-semibold uppercase text-gray-600">
          Invoice Number Prefix
        </h3>
        <div class="flex items-end">
          <Field
            bind:field={quotationInvoiceObj.quotationInvoice.prefix}
            containerCss="w-2/5"
          />
          <Field
            bind:field={quotationInvoiceObj.quotationInvoice.next_number}
            containerCss="ml-4 w-2/5"
          />
        </div>
        {#if quotationInvoiceObj.errors}
          <span class="field-error"
            >{JSON.stringify(quotationInvoiceObj.errors)}</span
          >
        {/if}
      </div>
      <div>
        <h3 class="text-sm font-semibold uppercase text-gray-600">
          Delivery Challan Number Prefix
        </h3>
        <div class="flex items-end">
          <Field
            bind:field={deliveryChallanObj.deliveryChallan.prefix}
            containerCss="w-2/5"
          />
          <Field
            bind:field={deliveryChallanObj.deliveryChallan.next_number}
            containerCss="ml-4 w-2/5"
          />
        </div>
        {#if deliveryChallanObj.errors}
          <span class="field-error"
            >{JSON.stringify(deliveryChallanObj.errors)}</span
          >
        {/if}
      </div>
    </div>
    <button class="button-primary mt-6" disabled={apiInProgress ? true : false}
      >{apiInProgress ? "Updating Prefixes" : "Update Prefixes"}</button
    >
  </form>
</div>
