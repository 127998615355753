<style>
  .searchActive {
    @apply bg-white rounded-lg shadow-lg border border-gray-100 relative z-20;
  }

  .section-separator::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(0%, -50%);
    background-color: #e5e7eb;
    height: 1px;
    width: 50%;
  }
  .section-separator::after {
    content: "";
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(0, -50%);
    background-color: #e5e7eb;
    height: 1px;
    width: 50%;
  }
</style>

<script>
  import { jsonHeaders } from "../shared/utils.js";
  import Field from "./../forms/Field.svelte";

  export let selectedCustomer,
    shippingAddress,
    showReviewOrder,
    redirection_url,
    showCustomerForm = true;

  let url,
    searchActive = false,
    search,
    customerList = [],
    customer = {},
    errors = {},
    customerCreateInProgress = false;

  const onFocus = () => {
    searchActive = true;
  };
  const onBlur = () => {
    searchActive = false;
    customerList = [];
  };
  const selectCustomer = (customer) => {
    selectedCustomer = customer;
    if (selectedCustomer.preferred_address) {
      shippingAddress = selectedCustomer.preferred_address;
      if (shippingAddress.name === "default") {
        shippingAddress.name = customer.name;
      }
    }
    customerList = [];
    searchActive = false;
    if (showCustomerForm === true) {
      showCustomerForm = !showCustomerForm;
    }
  };

  const cancelCustomerCreation = () => {
    if (redirection_url) {
      location.href = redirection_url;
    } else {
      showCustomerForm = !showCustomerForm;
    }
  };

  const searchCustomers = () => {
    if (search) {
      url = `/admin/customers?phone=${search}`;
    }
    fetch(url, {
      method: "GET",
      headers: jsonHeaders(),
    })
      .then((response) => response.json())
      .then((data) => {
        customerList = data;
      });
  };

  const createCustomer = () => {
    customerCreateInProgress = true;
    const customer_obj = {
      name: customer.name,
      phone_number: customer.phone_number,
      email: customer.email,
      addresses: [
        {
          name: customer.name,
          country_code: "IN",
          phone: customer.phone_number,
          email: customer.email,
          line1: customer.line1,
          line2: customer.line2,
          city: customer.city,
          state_name: customer.state,
          postal_code: customer.postal_code,
        },
      ],
    };
    url = "/admin/customers";
    fetch(url, {
      method: "POST",
      headers: jsonHeaders(),
      body: JSON.stringify({ customer: customer_obj }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if ("errors" in data) {
          errors = data.errors;

          console.log(errors);
        } else {
          if (redirection_url) {
            location.href = `${url}/${data.customer.id}`;
          } else {
            errors = {};
            customer = data.customer;
            selectCustomer(customer);
          }
        }
      })
      .finally(() => {
        customerCreateInProgress = false;
      });
  };

  $: if (search) {
    searchCustomers();
  }
</script>

{#if selectedCustomer || (showReviewOrder && selectedCustomer)}
  <div class="pt-4 px-2 block">
    <p class="text-sm text-primary-600 capitalize">{selectedCustomer.name}</p>
    <div class="flex text-primary-800">
      <div class="text-xs w-3/5">
        <p class="flex items-start">
          <span class="icon icon-mail mr-2" />{selectedCustomer.email}
        </p>
        <p class="flex items-start">
          <span class="icon icon-phone mr-2" />{selectedCustomer.phone_number}
        </p>
      </div>
      <div class="flex items-start text-xs ml-4 w-2/5">
        {#if selectedCustomer.addresses}
          <span class="icon icon-address mr-2" />{selectedCustomer.addresses[0]
            .line1}, {selectedCustomer.addresses[0].city}
          {selectedCustomer.addresses[0].postal_code}
        {/if}
      </div>
    </div>
  </div>
{:else}
  <div
    on:click={onBlur}
    class={searchActive
      ? "block fixed z-20 inset-0 bg-black bg-opacity-30 modal"
      : ""}
  />

  {#if !showCustomerForm}
    <p class="text-sm text-secondary-400 ml-2 mt-4">
      Search for an existing customer
    </p>
    <div
      class="transform transition-all {searchActive ? 'searchActive' : 'pb-4'}"
    >
      <form action="" method="GET" class={searchActive ? "px-8 py-4" : ""}>
        <input
          type="search"
          bind:value={search}
          on:focus={onFocus}
          name="search"
          autocomplete="off"
          placeholder="Search by phone number"
          class=" rounded-full text-sm"
        />
      </form>
      <div
        class={searchActive
          ? "pl-4 max-h-96 overflow-y-scroll customers-container"
          : "hidden"}
      >
        {#each customerList as customer}
          <a
            href="#"
            on:click|preventDefault={selectCustomer(customer)}
            class="py-4 px-8 border-b border-secondary-200 block"
          >
            <p class="text-sm text-primary-600 capitalize">{customer.name}</p>
            <div class="flex text-primary-800">
              <div class="text-xs w-3/5">
                <p class="flex items-start">
                  <span class="icon icon-mail mr-2" />{customer.email}
                </p>
                <p class="flex items-start">
                  <span class="icon icon-phone mr-2" />{customer.phone_number}
                </p>
                <p class="flex items-start">
                  {#if customer.addresses}
                    <span class="icon icon-home mr-2" />{customer.addresses[0]
                      .line1}, {customer.addresses[0].city}
                    {customer.addresses[0].postal_code}
                  {/if}
                </p>
              </div>
              <!-- <div class=" ml-4 w-2/5">
                <h4 class="text-xs uppercase text-primary-500">Recent Order</h4>
                <p class="text-xs text-primary-800 mb-0 mt-4">12 Sep 2021</p>
                <a
                  href="/order/A1232323"
                  class="text-xs text-primary-800 underline">A1233424</a
                >
              </div> -->
            </div>
          </a>
        {/each}
      </div>
    </div>

    <div class="flex justify-center section-separator relative my-6 mx-10">
      <p
        class="relative z-10 px-1 text-sm bg-white mt-2 w-12 text-center text-primary-600 font-semibold"
      >
        OR
      </p>
    </div>
    <span class="h2 font-normal mr-1">+</span>
    <button
      on:click={() => (showCustomerForm = !showCustomerForm)}
      class="underline font-medium text-primary-800 text-sm"
    >
      Add a new customer</button
    >
  {/if}
  <div>
    {#if showCustomerForm}
      <h2 class="h2 text-primary-800 my-8 ml-2">New Customer</h2>
      <form on:submit|preventDefault>
        <div
          class="grid col ml-2 {redirection_url
            ? 'grid-cols-2 gap-x-12 border border-primary-300 p-8'
            : ''}"
        >
          <Field
            bind:field={customer.name}
            label="Customer Name"
            name="customer[name]"
            error={errors.name}
          />
          <Field
            bind:field={customer.phone_number}
            label="Phone Number"
            name="customer[phone_number]"
            error={errors.phone_number}
          />
          <Field
            bind:field={customer.email}
            label="Email"
            name="customer[email]"
            error={errors.email}
          />
          <Field
            bind:field={customer.line1}
            label="Address Line 1"
            name="customer[line1]"
            error={errors.line1}
          />
          <Field
            bind:field={customer.line2}
            label="Address Line 2"
            name="customer[line2]"
            error={errors.line2}
          />
          <Field
            bind:field={customer.postal_code}
            label="Postal Code"
            name="customer[postal_code]"
            error={errors.postal_code}
          />
          <Field
            bind:field={customer.city}
            label="City"
            name="customer[city]"
            error={errors.city}
          />
          <Field
            bind:field={customer.state}
            label="State"
            name="customer[state]"
            error={errors.state}
          />
        </div>
        <div class="mt-8 ml-10">
          {#if !customerCreateInProgress}
            <button on:click={createCustomer} class="button-primary"
              >Create Customer</button
            >
            <button on:click={cancelCustomerCreation} class="button ml-4">
              Cancel</button
            >
          {/if}
        </div>
      </form>
    {/if}
  </div>
{/if}
{#if customerCreateInProgress}
  <div class="absolute z-10 top-0 left-0 bottom-0 right-0">
    <div
      class="loader loader-default is-active"
      data-text="Creating Customer"
    />
  </div>
{/if}
