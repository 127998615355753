<script>
  import { onMount } from "svelte";
  import { jsonHeaders } from "./shared/utils";

  export let action = { url: "", name: "" };
  export let secondaryAction = { url: "", name: "" };
  let selectAllButton,
    selectInputs,
    selectedCount,
    actionInProgress = false;

  const changeState = () => {
    let selectedIds = selectedItems();
    submit(selectedIds);
  };

  const changeSecondaryState = () => {
    let selectedIds = selectedItems();
    secondarySubmit(selectedIds);
  };

  const submit = (selectedIds) => {
    actionInProgress = true;
    fetch(action.url, {
      method: "POST",
      headers: jsonHeaders(),
      body: JSON.stringify({ ids: selectedIds }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (action.redirect_url) {
          location.href = action.redirect_url;
        } else {
          location.reload();
        }
      })
      .finally(() => {
        actionInProgress = false;
      });
  };

  const secondarySubmit = (selectedIds) => {
    actionInProgress = true;
    if (secondaryAction.download) {
      fetch(secondaryAction.url, {
        method: "POST",
        headers: jsonHeaders(),
        body: JSON.stringify({ ids: selectedIds }),
      })
        .then((response) => response.blob())
        .then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          a.download = secondaryAction.filename;
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
        })
        .finally(() => {
          actionInProgress = false;
        });
    } else {
      fetch(secondaryAction.url, {
        method: "POST",
        headers: jsonHeaders(),
        body: JSON.stringify({ ids: selectedIds }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if (secondaryAction.redirect_url) {
            location.href = secondaryAction.redirect_url;
          } else {
            location.reload();
          }
        })
        .finally(() => {
          actionInProgress = false;
        });
    }
  };

  const selectAllChildren = (event) => {
    if (event.target.checked == true) {
      selectInputs.forEach(function (val) {
        val.checked = true;
      });
    } else {
      selectInputs.forEach(function (val) {
        val.checked = false;
      });
    }
    showSelectedCount();
  };

  const cancel = () => {
    selectAllButton.checked = false;
    selectAllButton.indeterminate = false;
    selectInputs.forEach(function (val) {
      val.checked = false;
    });
    showSelectedCount();
  };

  const selectParent = () => {
    showSelectedCount();
    if (selectedCount === 0) {
      selectAllButton.indeterminate = false;
      selectAllButton.checked = false;
    } else if (selectedCount < selectInputs.length) {
      selectAllButton.indeterminate = true;
      selectAllButton.checked = false;
    } else {
      selectAllButton.indeterminate = false;
      selectAllButton.checked = true;
    }
  };

  const showSelectedCount = () => {
    let count = 0;
    selectInputs.forEach(function (val) {
      if (val.checked == true) {
        count++;
      }
    });
    selectedCount = count;
  };

  const selectedItems = () => {
    return Array.from(selectInputs)
      .filter((item) => item.checked)
      .map((item) => item.id);
  };

  onMount(() => {
    selectAllButton = document.querySelector(".select-all-inputs");
    selectInputs = document.querySelectorAll(".select-input");
    if (selectAllButton) {
      selectAllButton.addEventListener("change", selectAllChildren);
    }
    if (selectInputs) {
      selectInputs.forEach((item) => {
        item.addEventListener("change", selectParent);
      });
    }
  });
</script>

{#if selectedCount > 0}
  <div
    class="absolute bottom-0 left-0 right-0 flex items-center bg-white shadow-2xl border-t border-gray-100 px-8 py-4"
  >
    <p class="flex-auto">{selectedCount} Selected</p>
    {#if actionInProgress}
      <div class="loader loader-default is-active" data-text="In Progress" />
    {:else}
      <a
        href="#"
        on:click|preventDefault={changeState}
        class="button button-primary mr-4">{action.name}</a
      >
      {#if secondaryAction}
        <a
          href="#"
          on:click|preventDefault={changeSecondaryState}
          class="button button-primary mr-4">{secondaryAction.name}</a
        >
      {/if}
      <a href="#" on:click|preventDefault={cancel} class="button button-danger"
        >Cancel</a
      >
    {/if}
  </div>
{/if}
