<script>
  import { jsonHeaders } from "../shared/utils.js";
  import { fade } from "svelte/transition";
  import Field from "../forms/Field.svelte";

  export let showEditMedium, editingMedium;

  let errors = null;

  const updateMedium = () => {
    let mediumUrl = `/admin/media/${editingMedium.id}`;
    let payload = {
      caption: editingMedium.caption,
      alt_text: editingMedium.alt_text,
      custom_css: editingMedium.custom_css,
    };
    fetch(mediumUrl, {
      method: "PATCH",
      headers: jsonHeaders(),
      body: JSON.stringify(payload),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if ("errors" in data) {
          errors = data.errors;
          console.log(data);
        } else {
          errors = {};
          console.log(data);
        }
      });
  };

  const deleteMedium = (mediumId) => {
    let confirmed = confirm("Are you sure you want to delete this Medium?");

    if (confirmed) {
      let mediumUrl = `/admin/media/${mediumId}`;
      fetch(mediumUrl, {
        method: "DELETE",
        headers: jsonHeaders(),
      }).then((response) => {
        if (response.status === 200) {
          showEditMedium = false;
        }
      });
    }
  };
</script>

{#if showEditMedium}
  <div
    class="fixed z-10 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      />

      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
      />
      <div class="float-right relative">
        <div
          transition:fade
          class="flex items-center justify-center bg-opacity-75 fixed z-10 top-0 right-0 bottom-0 left-0"
        >
          <div
            class="container w-128 p-8 bg-white rounded-lg text-left shadow-xl transform transition-all border border-primary-200"
          >
            <a
              href="#"
              on:click={(e) => {
                showEditMedium = false;
              }}
              class="absolute top-0 right-0 -mt-2 -mr-2 p-1 text-center h-8 w-8 bg-white rounded-full shadow-md"
              data-test-close-edit-medium="close-edit-medium">×</a
            >

            {#if editingMedium && editingMedium.label}
              <h2 class="font-medium capitalize text-primary-800">
                {editingMedium.label}
              </h2>
            {:else}
              <h2 class="font-medium capitalize text-primary-800">
                Edit Medium
              </h2>
            {/if}
            <form action="" method="GET" class="m-4">
              {#if errors}
                {errors}
              {/if}
              <Field
                bind:field={editingMedium.caption}
                name="caption"
                label="Caption"
              />
              <Field
                bind:field={editingMedium.alt_text}
                name="altText"
                label="Alt text"
              />
              <Field
                bind:field={editingMedium.custom_css}
                name="customCSS"
                label="Custom CSS"
              />
              <button
                data-test-update-medium="update-medium"
                on:click|preventDefault={updateMedium}
                class="button-primary"
              >
                Save
              </button>
              <a
                href="#"
                on:click|preventDefault={deleteMedium(editingMedium.id)}
                data-test-delete-medium-from-edit-page="{editingMedium.label}-{editingMedium.id}"
                class="button button-subtle">Delete</a
              >
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
{/if}
