<script>
  import { onMount } from "svelte";

  onMount(() => {
    const selectItem = document.querySelector(
      "#bulk_upload_bulk_upload_processor"
    );
    const resourceItem = document.querySelector(".bulk_upload_input_resource");
    const groupingItem = document.querySelector(
      "#bulk_upload_input_skip_grouping_container"
    );
    resourceItem.style.visibility = "hidden";
    groupingItem.style.visibility = "hidden";
    const processorChangeListener = (ev) => {
      if (ev.target.value === "BulkUpload::CatalogCsvUploadProcessor") {
        resourceItem.style.visibility = "visible";
        groupingItem.style.visibility = "visible";
      } else {
        resourceItem.style.visibility = "hidden";
        groupingItem.style.visibility = "hidden";
      }
    };
    const resourceChangeListener = (ev) => {
      if (ev.target.value === "variants") {
        groupingItem.style.visibility = "visible";
      } else {
        groupingItem.style.visibility = "hidden";
      }
    };
    selectItem.addEventListener("change", processorChangeListener);
    resourceItem.addEventListener("change", resourceChangeListener);
    return () => {
      selectItem.removeEventListener("change", processorChangeListener);
      resourceItem.removeEventListener("change", resourceChangeListener);
    };
  });
</script>
