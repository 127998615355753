<style>
  .selected-category {
    background: #fafafa;
    border-top: 1px solid #ecedf2;
    border-bottom: 1px solid #ecedf2;
  }

  .selected-category input[type="radio"]:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    content: "";
    display: inline-block;
    visibility: visible;
  }

  .selected-category input[type="radio"]:checked:after {
    width: 16px;
    height: 16px;
    border-radius: 16px;
    top: -1px;
    left: -1px;
    position: relative;
    background-color: blue;
    content: "";
    display: inline-block;
    visibility: visible;
  }
</style>

<script>
  export let activeCategory,
    url,
    parentCategory,
    showRadioButtons = false;
  import { jsonHeaders } from "../shared/utils.js";
  let categories = [];

  function getContents() {
    fetch(requestUrl(), {
      method: "GET",
      headers: jsonHeaders(),
    })
      .then((response) => response.json())
      .then((data) => (categories = data.categories));
  }

  const toggleCategory = (category) => {
    category.expand_category = category.expand_category ? false : true;
    categories = categories;
  };

  const selectCategory = (category) => {
    if (category.sub_categories_count > 0) {
      toggleCategory(category);
    }
    activeCategory = category;
  };
  const requestUrl = () =>
    parentCategory ? `${url}/${parentCategory.id}` : url;

  $: getContents();
</script>

<ul class="">
  {#each categories as category (category.id)}
    <li
      on:click|stopPropagation={(e) => {
        selectCategory(category);
      }}
      class="pl-6 cursor-pointer relative {activeCategory &&
      category.id === activeCategory.id
        ? 'selected-category'
        : ''}"
    >
      {#if category.sub_categories_count > 0}
        <span
          class="absolute -left-4 top-1 w-12 h-10 icon  opacity-50 hover:opacity-100 icon-chevron-{category.expand_category
            ? 'down'
            : 'right'}"
          on:click|stopPropagation={(e) => {
            toggleCategory(category);
          }}>></span
        >
      {/if}
      <label
        for={activeCategory && activeCategory.id
          ? activeCategory.id
          : "new-category"}
        class="flex items-center pr-6"
      >
        <span class="flex-auto block py-3 px-2 rounded-l">{category.name}</span>
        {#if showRadioButtons}
          <input
            type="radio"
            name="category_id"
            checked={activeCategory && category.id === activeCategory.id
              ? true
              : false}
            value={activeCategory && activeCategory.id}
          />
        {/if}
      </label>

      {#if category.expand_category}
        <svelte:self
          bind:activeCategory
          parentCategory={category}
          {url}
          showRadioButtons="true"
        />
      {/if}
    </li>
  {/each}
</ul>
