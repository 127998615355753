<script>
  export let field,
    label,
    error,
    name = "",
    required = false,
    placeholder = "",
    containerCss = "",
    inputCss = "";
</script>

<p class={containerCss}>
  {#if label}
    <label for={name}>{label}</label>
  {/if}

  <textarea
    bind:value={field}
    id={name}
    {name}
    {placeholder}
    class="w-full {inputCss}"
    {required}
  />
  {#if error}<span class="field-error">{error}</span>{/if}
</p>
