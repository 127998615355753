<script>
  import debounce from "lodash/debounce";
  import EditComponentCategories from "./EditComponentCategories.svelte";
  import Field from "./../forms/Field.svelte";
  import { jsonHeaders } from "../shared/utils.js";
  import { createEventDispatcher } from "svelte";
  import { fade } from "svelte/transition";

  export let component,
    formUrl,
    categoryList,
    categoriesUrl,
    showDeleteComponent;

  const dispatch = createEventDispatcher();
  let url = `${formUrl}/${component.id}`;

  const save = debounce(() => {
    fetch(url, {
      method: "PATCH",
      headers: jsonHeaders(),
      body: JSON.stringify({ component: component }),
    });
  }, 2000);

  const remove = () => {
    let confirmed = confirm("Are you sure you want to remove this component");

    if (confirmed) {
      fetch(url, {
        method: "DELETE",
        headers: jsonHeaders(),
      }).then((response) => {
        if (response.status === 200) {
          dispatch("remove", {});
        }
      });
    }
  };
</script>

<div transition:fade class="mb-12 pb-12 border-b border-primary-300">
  <form on:submit|preventDefault on:input={save} action=".">
    <button
      on:click={remove(component)}
      class="icon icon-trash float-right mt-1 {showDeleteComponent
        ? ''
        : 'hidden'}"
    >
      delete
    </button>

    <Field
      bind:field={component.name}
      placeholder="Enter component name"
      containerCss="w-5/6"
      inputCss="bg-transparent border-none outline-none shadow-none p-0"
    />
    <Field
      bind:field={component.quantity}
      label="No of units"
      containerCss="w-48"
    />
  </form>

  <EditComponentCategories
    bind:categories={component.categories}
    formUrl={`${url}/component_categories`}
    {categoryList}
    {component}
    {categoriesUrl}
  />
</div>
