<script>
  import { fade } from "svelte/transition";
  import { jsonHeaders } from "../shared/utils.js";

  export let taxCodes, defaultTaxCodeName, hideForm, url;
  const saveStoreTax = () => {
    console.log(defaultTaxCodeName);
    fetch(`${url}/bulk_update`, {
      method: "POST",
      headers: jsonHeaders(),
      body: JSON.stringify({
        default_tax_code_name: defaultTaxCodeName,
        tax_codes: taxCodes.map((item) => ({
          id: item.id,
          status: item.status,
        })),
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.errors) {
          console.log(errors);
        } else {
          location.href = url;
        }
      });
  };

  const setChecked = (taxCodeId, isChecked) => {
    console.log(taxCodeId, isChecked);
    taxCodes = taxCodes.map((it) => {
      let item = { ...it };
      if (item.id === taxCodeId) {
        if (isChecked) {
          item.status = "active";
        } else {
          item.status = "archived";
        }
      }
      return item;
    });
  };
</script>

<div
  class="fixed z-10 inset-0 overflow-y-auto"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true"
>
  <div
    class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
  >
    <div
      class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      aria-hidden="true"
    />

    <span
      class="hidden sm:inline-block sm:align-middle sm:h-screen"
      aria-hidden="true"
    />
    <div class="float-right relative">
      <div
        transition:fade
        class="flex items-center justify-center bg-opacity-75 fixed z-10 top-0 right-0 bottom-0 left-0"
      >
        <form
          on:submit|preventDefault
          class="container lg:w-8/12 max-full overflow-y-scroll bg-white rounded-lg text-left shadow-xl transform transition-all border border-primary-200"
        >
          <h2
            class="bg-white font-medium text-lg capitalize text-primary-800 border-b border-gray-200 p-8 sticky top-0 z-10"
          >
            Edit Tax Rules
          </h2>
          <table
            class="w-full overflow-y-scroll"
            data-test-table="tax-codes-IN"
          >
            <thead class="sticky top-[93px] z-10 shadow-sm bg-white h-12">
              <tr class="text-xs text-primary-500 uppercase tracking-wider ">
                <th class="font-medium py-3 pl-8 text-left">Rule</th>
                <th class="font-medium px-8 py-3 text-left">IGST</th>
                <th class="font-medium px-8 py-3 text-left">SGST</th>
                <th class="font-medium px-8 py-3 text-left">CGST</th>
                <th class="font-medium px-8 py-3 text-left">Cess</th>
                <th class="font-medium px-8 py-3 text-center">Active</th>
                <th class="font-medium pr-8 py-3 text-center">Default</th>
              </tr>
            </thead>
            <tbody class="text-primary-800 text-xs">
              {#each taxCodes as taxCode, i}
                <tr
                  class="border-b border-gray-300"
                  data-test-row-name={taxCode.name}
                >
                  <td class="pl-8 py-3" data-test-rule-name={taxCode.name}>
                    {taxCode.name}
                  </td>
                  <td class="px-8 py-3" data-test-igst={taxCode.name}>
                    {taxCode.tax_rates.igst}%
                  </td>
                  <td class="px-8 py-3" data-test-sgst={taxCode.name}>
                    {taxCode.tax_rates.sgst}%
                  </td>
                  <td class="px-8 py-3" data-test-cgst={taxCode.name}>
                    {taxCode.tax_rates.cgst}%
                  </td>
                  <td class="px-8 py-3" data-test-cess={taxCode.name}>
                    {taxCode.tax_rates.cess}%
                  </td>
                  <td class=" py-3 text-center">
                    <input
                      type="checkbox"
                      checked={taxCode.status === "active"}
                      on:change={(event) =>
                        setChecked(taxCode.id, event.target.checked)}
                    />
                  </td>
                  <td class="pr-8  py-3 text-center">
                    <input
                      type="radio"
                      bind:group={defaultTaxCodeName}
                      name={"default_tax"}
                      value={taxCode.name}
                    />
                  </td>
                </tr>
              {/each}
            </tbody>
          </table>
          <div class="bg-gray-100 py-4 px-8 sticky bottom-0 z-10">
            <a
              href="#"
              on:click|preventDefault={saveStoreTax}
              class="button button-primary mr-8">Save</a
            >
            <a
              href="#"
              on:click|preventDefault={hideForm}
              class="text-primary-800 font-medium">Cancel</a
            >
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
