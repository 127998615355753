<script>
  import { jsonHeaders } from "./shared/utils.js";
  export let distributor, formUrl, regions, regionsUrl;
  import PostalRegions from "../svelte/PostalRegions.svelte";

  function cancelSubmit(event) {
    event.preventDefault();
  }

  function createDistributor() {
    if (!distributor.id) {
      fetch(formUrl, {
        method: "POST",
        headers: jsonHeaders(),
        body: JSON.stringify({ distributor: { name: distributor.name } }),
      })
        .then((response) => response.json())
        .then((data) => {
          distributor.id = data.id;
          window.history.replaceState(
            null,
            null,
            `${formUrl}/${distributor.id}/edit`
          );
        });
    }
  }
</script>

<div class="p-8">
  <h2 class="h2 mt-4 mb-8 w-full overflow-hidden">
    <a href="/admin/stock_locations" class="float-left w-8 pt-1">←</a>
    <input
      bind:value={distributor.name}
      on:blur={createDistributor}
      type="text"
      id="name"
      class="float-left w-10/12 bg-transparent border-none"
    />
  </h2>

  <PostalRegions {regions} bind:distributor formUrl={regionsUrl} />
</div>
