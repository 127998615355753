<script>
  export let payment;
  let rzpOrderId;

  $: {
    if (
      payment &&
      payment.provider_details &&
      payment.provider_details.provider_input &&
      payment.provider_details.provider_input.order
    ) {
      rzpOrderId = payment.provider_details.provider_input.order.id;
    }
  }
</script>

<ul class=" py-6 mt-0 flex">
  <li class="mr-12">
    <p class="text-xs text-primary-500 mb-0">Gateway Payment</p>
    <p class="mb-0">
      {payment.provider_details && payment.provider_details.provider_name}
    </p>
  </li>
  <li class="flex-auto">
    <p class="text-xs text-primary-500 mb-0">Status</p>
    <p class="mb-0">{payment.status}</p>
  </li>
  <li class="flex-auto">
    <p class="text-xs text-primary-500 mb-0">Razorpay Order Id</p>
    <p class="mb-0">{rzpOrderId}</p>
  </li>
</ul>
