<script>
  import NewVariantCoupon from "./NewVariantCoupon.svelte";
  import Coupon from "./Coupon.svelte";

  export let campaign, coupons, url, productsUrl;
  let showForm = false;

  const addProduct = () => {
    showForm = true;
  };

  const remove = (coupon) => {
    let index = coupons.indexOf(coupon);

    coupons = [
      ...coupons.slice(0, index),
      ...coupons.slice(index + 1, coupons.length),
    ];
  };

  const couponUrl = (coupon) => `${url}/coupons/${coupon.id}/`;
</script>

<div class="col-span-6 border border-primary-300 mx-8">
  <table class="w-full">
    <thead>
      <tr
        class="border-b border-gray-300 text-xs text-primary-500 uppercase tracking-wider"
      >
        <th class="font-medium px-4 py-3 text-left">Product</th>
        <th class="font-medium px-4 py-3 text-right">Selling Price</th>
        {#if campaign.kind != "lightning"}
          <th class="font-medium px-4 py-3 text-center">Type</th>
        {/if}
        <th class="font-medium px-4 py-3 text-right">Amount</th>
        <th class="font-medium px-4 py-3 text-right">Used Count</th>
        <th class="font-medium px-4 py-3 text-right">No of Coupons</th>
        <th class="font-medium px-4 py-3 text-right">Coupon Code</th>
        <th />
      </tr>
    </thead>
    <tbody class="text-primary-800">
      {#each coupons as coupon (coupon.id)}
        <Coupon
          {coupon}
          {campaign}
          url={couponUrl(coupon)}
          on:delete={(e) => remove(coupon)}
        />
      {/each}
    </tbody>
  </table>

  <button class="button-transparent m-4" on:click|preventDefault={addProduct}
    >+ Add Variant Coupon</button
  >
</div>

{#if showForm}
  <NewVariantCoupon bind:showForm bind:coupons {campaign} {url} {productsUrl} />
{/if}
