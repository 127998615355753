<script>
  import { onMount } from "svelte";
  import { fade } from "svelte/transition";
  import { jsonHeaders } from "../../shared/utils.js";
  import ToggleButton from "../../forms/ToggleButton.svelte";

  export let catalogs;
  export let store;
  export let decoratedCatalogs = [];

  const toggleEnabled = (catalog, checked) => {
    catalog.access_request_change_in_progress = true;
    if (catalog.request_access) {
      const denyUrl = `/vizstore/admin/brand_catalogs/${catalog.id}/access_requests/${catalog.request_access.id}/deny`;
      const approveUrl = `/vizstore/admin/brand_catalogs/${catalog.id}/access_requests/${catalog.request_access.id}/approve`;
      fetch(checked ? approveUrl : denyUrl, {
        method: "POST",
        headers: jsonHeaders(),
        body: JSON.stringify({}),
      })
        .then((response) =>
          response.json().then((data) => ({
            code: response.status,
            responseJson: data,
          }))
        )
        .then((data) => {
          console.log(data.responseJson);
          if (data.code === 200) {
            catalog.request_access = data.responseJson.access_request;
            catalog.has_access =
              data.responseJson.access_request.status == "approved";
          } else {
            catalog.has_access = false;
          }
        })
        .finally(() => {
          catalog.access_request_change_in_progress = false;
          decoratedCatalogs = decoratedCatalogs.map((item) => {
            if (item.id === catalog.id) {
              return catalog;
            }
            return item;
          });
        });
    } else {
      if (checked) {
        const createUrl = `/vizstore/admin/brand_catalogs/${catalog.id}/access_requests`;
        fetch(createUrl, {
          method: "POST",
          headers: jsonHeaders(),
          body: JSON.stringify({ requester_id: store.id }),
        })
          .then((response) =>
            response.json().then((data) => ({
              code: response.status,
              responseJson: data,
            }))
          )
          .then((data) => {
            console.log(data.responseJson);
            if (data.code === 201) {
              catalog.request_access = data.responseJson.access_request;
              catalog.has_access =
                data.responseJson.access_request.status == "approved";
            } else {
              catalog.has_access = false;
            }
          })
          .finally(() => {
            catalog.access_request_change_in_progress = false;
            decoratedCatalogs = decoratedCatalogs.map((item) => {
              if (item.id === catalog.id) {
                return catalog;
              }
              return item;
            });
          });
      }
    }
  };

  onMount(() => {
    decoratedCatalogs = [];
    if (catalogs) {
      catalogs.forEach((catalog) => {
        catalog.requests =
          catalog.requests.filter((item) => item != null) || [];
        catalog.request_access = catalog.requests[0];
        if (catalog.request_access) {
          catalog.available_since = new Date(
            catalog.request_access.created_at
          ).toLocaleDateString();
          catalog.has_access = catalog.request_access.status == "approved";
        } else {
          catalog.access_request_change_in_progress = false;
          catalog.has_access = false;
        }

        decoratedCatalogs.push(catalog);
      });
    }
    decoratedCatalogs = [...decoratedCatalogs];
  });
</script>

<table class="w-full">
  <thead>
    <tr class="text-xs text-primary-500 uppercase tracking-wider">
      <th class="font-medium px-8 py-3 text-left">Date</th>
      <th class="font-medium px-8 py-3 text-left">Catalog Name</th>
      <th class="font-medium px-8 py-3 text-left">Brand Name</th>
      <th class="font-medium px-8 py-3 text-left">Status</th>
    </tr>
  </thead>
  <tbody class="text-primary-800">
    {#each decoratedCatalogs as catalog (catalog.id)}
      <tr transition:fade class="border-t border-gray-300">
        <td class="px-8 py-4 text-left">{catalog.available_since || "-"}</td>
        <td class="px-8 py-4 text-left">
          {catalog.name}
        </td>
        <td class="px-8 py-4 text-left">
          {catalog.sub_brand_name || "-"}
        </td>
        <td class="px-8 py-4 text-left">
          {#if catalog.access_request_change_in_progress}
            <p>Access change queued</p>
          {:else}
            <ToggleButton
              bind:checked={catalog.has_access}
              on:change={(event) =>
                toggleEnabled(catalog, event.detail.checked)}
              checkedLabel="Available"
              uncheckedLabel="Available"
              containerCss="float-left -mt-1"
            />
          {/if}
          <!-- <p>{catalog.request_access && catalog.request_access.status}</p> -->
        </td>
      </tr>
    {/each}
  </tbody>
</table>
