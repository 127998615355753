<script>
  import { jsonHeaders } from "../shared/utils.js";
  import Coupon from "./Coupon.svelte";

  export let campaign, coupons, url;

  let newCouponUrl = `${url}/coupons/`;

  const addCoupon = () => {
    fetch(newCouponUrl, {
      method: "POST",
      headers: jsonHeaders(),
      body: JSON.stringify({
        discount_type: "flat",
        type: "CartCoupon",
        min_cart_value: 0,
        discount_value: 0,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        coupons = coupons.concat(data);
      });
  };

  const remove = (coupon) => {
    let index = coupons.indexOf(coupon);

    coupons = [
      ...coupons.slice(0, index),
      ...coupons.slice(index + 1, coupons.length),
    ];
  };

  const couponUrl = (coupon) => `${url}/coupons/${coupon.id}/`;
</script>

<div class="col-span-5 border border-primary-300 mx-8">
  <table class="w-full">
    <thead>
      <tr
        class="border-b border-gray-300 text-xs text-primary-500 uppercase tracking-wider"
      >
        <th class="font-medium px-4 py-3 text-left">Minimum Cart Value</th>
        <th class="font-medium px-4 py-3 text-center">Type</th>
        <th class="font-medium px-4 py-3 text-right">Amount</th>
        <th class="font-medium px-4 py-3 text-right">Used Count</th>
        <th class="font-medium px-4 py-3 text-right">No of Coupons</th>
        <th class="font-medium px-4 py-3 text-right">Coupon Code</th>
        <th />
      </tr>
    </thead>
    <tbody class="text-primary-800">
      {#each coupons as coupon (coupon.id)}
        <Coupon
          {coupon}
          {campaign}
          url={couponUrl(coupon)}
          on:delete={(e) => remove(coupon)}
        />
      {/each}
    </tbody>
  </table>

  <button class="button-transparent m-4" on:click|preventDefault={addCoupon}
    >+ Add Cart Coupon</button
  >
</div>
