<script>
  import { jsonHeaders } from "../../shared/utils.js";
  import Field from "../../forms/Field.svelte";
  export let account, termsConditions;
  let apiInProgress;

  const updateTermsConditions = () => {
    apiInProgress = true;
    fetch(`/vizstore/admin/accounts/${account.id}/update_terms_conditions`, {
      method: "POST",
      headers: jsonHeaders(),
      body: JSON.stringify({
        terms_conditions: termsConditions,
      }),
    })
      .then((response) => console.log(response))
      .finally(() => {
        apiInProgress = false;
      });
  };
</script>

<div class=" border-t border-gray-300 pt-12 mt-12">
  <h3 class="text-lg font-semibold col-span-2">Terms & Conditions</h3>

  <textarea bind:value={termsConditions} rows="20" class="w-full" />
</div>
<button
  class="button-primary mt-6"
  disabled={apiInProgress ? true : false}
  on:click|preventDefault={updateTermsConditions}
  >{apiInProgress ? "Updating" : "Update Terms and Conditions"}</button
>
