<script>
  export let actionsList;

  let showMenu = false;
  let kebabMenu, kebabMenuButton;
  let menuHorizontalPosition = "right";
  let menuVerticalPosition = "bottom";

  const show = (event) => {
    showMenu = true;

    if (
      kebabMenuButton.getBoundingClientRect().left <
      (window.innerWidth / 2 || document.documentElement.clientWidth / 2)
    ) {
      menuHorizontalPosition = "left";
    }

    if (
      kebabMenuButton.getBoundingClientRect().bottom <
      (window.innerHeight / 2 || document.documentElement.clientHeight / 2)
    ) {
      menuVerticalPosition = "top";
    }
  };
  const toggleMenu = () => (showMenu = !showMenu);
</script>

<div class="relative">
  <a
    href="#"
    on:click|stopPropagation={show}
    class="block outline-none m-2 px-2 py-1 text-left button-subtle"
    bind:this={kebabMenuButton}><span class="icon icon-kebab" /></a
  >

  <div
    class="fixed top-0 left-0 right-0 bottom-0 opacity-5 bg-black inset-0 z-10 {showMenu
      ? ''
      : 'hidden'}"
    on:click|stopPropagation={toggleMenu}
  >
    &nbsp
  </div>
  <div class="hidden right-2 left-2 top-12 bottom-12" />
  <ul
    class="nav absolute {menuHorizontalPosition}-2 {menuVerticalPosition}-12 mt-1 py-2 w-48 rounded-lg bg-white shadow-xl text-sm border border-primary-200 z-20 {showMenu
      ? ''
      : 'hidden'}"
    bind:this={kebabMenu}
  >
    {#each actionsList as item}
      <li>
        <a href={item.url} class="block outline-none w-full px-4 py-1 text-left"
          >{item.name}</a
        >
      </li>
    {/each}
  </ul>
</div>
