<script>
  import { onMount } from "svelte";

  export let field,
    label,
    error,
    name = "",
    required = false,
    placeholder = "",
    containerCss = "",
    inputCss = "";

  let inputField;

  const trimField = () => {
    if (inputField || inputField === "") {
      field = inputField.trim();
    }
  };

  onMount(() => {
    inputField = field;
  });
</script>

<p class={containerCss}>
  {#if label}
    <label for={name}>{label}</label>
  {/if}

  <input
    bind:value={inputField}
    type="text"
    id={name}
    {name}
    {placeholder}
    class="w-full {inputCss}"
    {required}
    on:keyup={trimField}
  />
  {#if error}<span data-test-error={name} class="field-error">{error}</span
    >{/if}
</p>
