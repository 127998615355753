<style>
  .tag-container {
    min-height: 41px;
  }
</style>

<script>
  export let tags, onAdded, onRemoved;
  let newTag = "";

  function refresh() {
    tags = [...new Set(tags)];
  }

  function focusInput() {
    event.target.querySelector("span[contenteditable]").focus();
  }

  function addTag(tag) {
    console.log("add");

    if (newTag.length > 0) {
      tags.push(newTag.trim().toLowerCase());
      let addedTag = newTag;
      newTag = "";
      refresh();
      onAdded && onAdded(addedTag);
    }
  }

  function insertTag() {
    console.log("insert");
    if (newTag[newTag.length - 1] === ",") {
      let addedTag = contentEditableTrim(newTag.slice(0, -1).toLowerCase());
      console.log(addedTag);
      tags.push(addedTag);
      newTag = "";
      event.target.focus();
      refresh();
      onAdded && onAdded(addedTag);
    }
  }

  const contentEditableTrim = (text) => text.replace(/&nbsp;/g, "").trim();

  function removeTag(tag) {
    tags = tags.filter((t) => t !== tag);
    onRemoved && onRemoved(tag);
  }
</script>

<div
  class="tag-container input-field block w-full leading-none p-0 overflow-hidden"
  on:click|self={focusInput}
>
  {#each tags as tag, i}
    <span
      class="block float-left relative h-7 text-sm bg-secondary-400 text-white rounded pl-2 pr-7 m-1 py-1"
    >
      {tag}
      <button
        class="icon icon-cross top-1 right-1 absolute"
        on:click={(event) => removeTag(tag)}>×</button
      >
    </span>
  {/each}

  <span
    bind:innerHTML={newTag}
    on:focusout={(event) => addTag()}
    on:input={(event) => insertTag()}
    contenteditable="true"
    class="block float-left p-1 pt-2 leading-3 border border-transparent m-1 outline-none focus:outline-none border-0 min-w-8"
  />
</div>
