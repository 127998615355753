<script>
  import { jsonHeaders } from "../../../shared/utils.js";
  export let url,
    isLiteLicenseFeatureDeactivated = false;
  let showDeactivateModal = false;

  const toggleDeactivateConfirmation = () => {
    showDeactivateModal = !showDeactivateModal;
  };

  const deactivateLiteLicense = () => {
    fetch(`${url}/update_lite_license_feature`, {
      method: "POST",
      headers: jsonHeaders(),
      body: JSON.stringify({
        feature_deactivated: !isLiteLicenseFeatureDeactivated,
      }),
    }).then(() => {
      location.href = url;
    });
  };
</script>

<div class="my-8 border-t border-gray-200 pt-8">
  <h4 class="flex-auto text-md text-primary-800 font-semibold">
    {#if isLiteLicenseFeatureDeactivated}
      Activate Lite License
    {:else}
      Deactivate Lite License
    {/if}
  </h4>
  <p class="text-primary-600 text-sm">
    {#if isLiteLicenseFeatureDeactivated}
      Activate Lite License will enable lite licenses from the store
    {:else}
      Deactivating a lite license would remove lite licenses from the store
    {/if}
  </p>
  <a
    href=""
    on:click|preventDefault={toggleDeactivateConfirmation}
    class="button button-danger"
  >
    {#if isLiteLicenseFeatureDeactivated}
      Activate
    {:else}
      Deactivate
    {/if}
  </a>
</div>
{#if showDeactivateModal}
  <div
    class="fixed z-10 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
        on:click={toggleDeactivateConfirmation}
      />

      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen "
        aria-hidden="true">&#8203;</span
      >
      <div
        class="inline-block align-bottom bg-white rounded-sm text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full"
      >
        <h4 class="flex-auto text-lg text-primary-800 font-semibold p-8">
          {#if isLiteLicenseFeatureDeactivated}
            Activate Lite License
          {:else}
            Deactivate Lite License
          {/if}
        </h4>
        <p class="mb-8 text-primary-800 px-8">
          {#if isLiteLicenseFeatureDeactivated}
            Are you sure you want to activate lite license?
          {:else}
            Are you sure you want to deactivate lite license?
          {/if}
        </p>
        <div class="border-t border-gray-200 p-8 bg-primary-150">
          <a
            href="#"
            on:click|preventDefault={deactivateLiteLicense}
            class="button button-primary mr-8"
          >
            {#if isLiteLicenseFeatureDeactivated}
              Yes, Activate
            {:else}
              Yes, Deactivate
            {/if}
          </a>
          <a
            href="#"
            on:click|preventDefault={toggleDeactivateConfirmation}
            class="text-primary-600 "
            >{#if isLiteLicenseFeatureDeactivated}
              Do not Activate
            {:else}
              Do not deactivate
            {/if}</a
          >
        </div>
      </div>
    </div>
  </div>
{/if}
