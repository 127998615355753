<script>
  export let liteLicensePack, url, selectedPackId;
  let currencyFmt = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });
</script>

<div class="border border-secondary-200 rounded-md">
  <div class="bg-secondary-100 p-4">
    <div class="flex justify-end mb-4">
      {#if !url}
        <input
          type="radio"
          bind:group={selectedPackId}
          name="packs"
          value={liteLicensePack.id}
        />
      {:else}
        <a
          href={`${url}/${liteLicensePack.id}/edit`}
          class=" icon icon-edit opacity-50 hover:opacity-100 w-8 h-6">Edit</a
        >
      {/if}
    </div>

    <p class="text-primary-800 font-bold capitalize mb-5">
      {liteLicensePack.name}
    </p>
    <div class="flex items-end font-bold mb-6">
      <p class="text-3xl text-secondary-600">
        {currencyFmt.format(liteLicensePack.total_price_units / 100)}
      </p>
      <p class=" ml-2 text-secondary-400">credit</p>
    </div>
  </div>
  <div class="p-4 border-t border-secondary-200">
    <div class="flex items-center text-primary-800">
      <div>
        <span>1 &#8212; {liteLicensePack.catalog_count_limit}</span>
      </div>
      <span class="flex-auto border-b border-gray-200 mx-6" />
      <div>
        <span class="font-bold"
          >{currencyFmt.format(
            liteLicensePack.price_units_per_catalog / 100
          )}</span
        >
      </div>
    </div>
    <div class="flex text-xs text-primary-500 mt-1">
      <p class="flex-auto">Catalogues</p>

      <p>/Catalogue/day</p>
    </div>

    <div class="flex items-center text-primary-800 mt-3">
      <div>
        <span>{liteLicensePack.catalog_count_limit}+</span>
      </div>
      <span class="flex-auto border-b border-gray-200 mx-6" />
      <div>
        <span class="font-bold"
          >{currencyFmt.format(
            liteLicensePack.price_units_after_limit / 100
          )}</span
        >
      </div>
    </div>
    <div class="flex text-xs text-primary-500 mt-1">
      <span class="flex-auto">Catalogues</span>

      <span>/day</span>
    </div>
  </div>
</div>
