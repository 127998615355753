<script>
  import { slide } from "svelte/transition";
  import { jsonHeaders } from "../../../shared/utils.js";
  import OfflinePayment from "./OfflinePayment.svelte";
  import PayByLink from "./PayByLink.svelte";
  import GatewayPayment from "./GatewayPayment.svelte";
  export let purchase, url;
  let payments = [];
  let apiInProgress = false;
  let togglePayments = false;
  let payment = {};
  let billDate, packName, purchaseAmount, receivedOn, paymentStatus;

  const togglePaymentsList = (purchase) => {
    if (apiInProgress) return;
    togglePayments = !togglePayments;

    if (togglePayments) {
      apiInProgress = true;
      fetch(`${url}/${purchase.id}/payments`, {
        method: "GET",
        headers: jsonHeaders(),
      })
        .then((response) => response.json())
        .then((resp) => {
          payments = resp.payments || [];
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          apiInProgress = false;
        });
    }
  };

  $: {
    if (purchase) {
      billDate = new Intl.DateTimeFormat("en-IN").format(
        new Date(purchase.created_at)
      );
      packName = purchase.pack_details.name;
      purchaseAmount = new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }).format(purchase.credited_amount / 100);
      paymentStatus = purchase.payment_status;
    }
  }
</script>

<tr class="border-b border-gray-200 {togglePayments ? 'bg-secondary-100' : ''}">
  <td class="px-4 pl-8 py-6 capitalize">{purchase.number}</td>
  <td class="px-4 pl-8 py-6 capitalize">{purchase.status}</td>
  <td class="px-4 pl-8 py-6 capitalize">{billDate}</td>
  <td class="px-4 py-6 capitalize">{packName}</td>
  <td class="px-4 py-6 capitalize">{purchaseAmount}</td>
  <td class="px-4 py-6 capitalize">{paymentStatus}</td>
  <td
    ><a href="#" on:click|preventDefault={() => togglePaymentsList(purchase)}
      ><span
        class="icon {togglePayments ? 'icon-cross' : 'icon-chevron-down'}"
      /></a
    ></td
  >
</tr>
{#if togglePayments}
  <tr transition:slide>
    <td colspan="6">
      <ul>
        {#each payments as payment (payment.id)}
          <div class="pl-8 border-b border-gray-200">
            {#if payment.type === "OfflinePayment"}
              <OfflinePayment bind:payment {url} />
            {:else if payment.type === "PayByLinkPayment"}
              <PayByLink bind:payment {url} />
            {:else if payment.type === "GatewayPayment"}
              <GatewayPayment bind:payment {url} />
            {/if}
          </div>
        {/each}
      </ul>
    </td>
  </tr>
{/if}
