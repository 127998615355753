<script>
  import NewLiteLicensePackPurchase from "./NewLiteLicensePackPurchase.svelte";
  import DeactivatePack from "./DeactivatePack.svelte";
  import RechargePack from "./RechargePack.svelte";
  import LiteLicensePackPurchasePayments from "./LiteLicensePackPurchasePayments.svelte";
  import { jsonHeaders } from "../../../shared/utils.js";
  import { onMount } from "svelte";

  export let liteLicensePacks,
    liteLicensePackPurchases,
    isLiteLicenseFeatureDeactivated,
    url;
  let activePurchase,
    selectedPack,
    queuedPurchases = [];
  let showModify = false,
    showCurrentPacks = true,
    showRecharge = false;
  let currencyFmt = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

  const modifyPack = () => {
    showModify = true;
    showCurrentPacks = false;
    showRecharge = false;
  };

  const toggleRecharge = () => {
    showRecharge = !showRecharge;
  };

  const getActivePurchase = () => {
    fetch(`${url}/active_purchase`, {
      method: "GET",
      headers: jsonHeaders(),
    })
      .then((response) => response.json())
      .then((resp) => {
        if (resp.active_purchase) {
          const purchaseObj = resp.active_purchase;
          const debits = resp.debits;
          selectedPack = purchaseObj;
          activePurchase = {
            name: purchaseObj.pack_details.name,
            credited_amount: purchaseObj.pack_details.total_price_units,
            amount_used: debits,
            status: purchaseObj.status,
            payment_status: purchaseObj.payment_status,
          };
        } else {
          activePurchase = null;
          showModify = true;
          showCurrentPacks = true;
          console.log(`Error - ${resp}`);
        }
      });
  };

  const getQueuedPurchases = () => {
    fetch(`${url}/queued_purchases`, {
      method: "GET",
      headers: jsonHeaders(),
    })
      .then((response) => response.json())
      .then((resp) => {
        if (resp.queued_purchases) {
          queuedPurchases = resp.queued_purchases.map((item) => ({
            name: item.pack_details.name,
            credited_amount: item.pack_details.total_price_units,
          }));
        } else {
          queuedPurchases = [];
          console.log(`Error - ${resp}`);
        }
      });
  };

  onMount(() => {
    getActivePurchase();
    getQueuedPurchases();
  });
</script>

{#if activePurchase && !showModify}
  <div class="border border-gray-200 rounded-md pb-8">
    <div class="flex m-8">
      <h4 class="flex-auto text-md text-primary-800 font-semibold">
        Lite License Pack
      </h4>
      <a
        href="#"
        on:click|preventDefault={modifyPack}
        class="flex items-center text-sm text-primary-800 opacity-50 hover:opacity-100"
        ><span class="icon icon-edit  w-8 h-6" />Modify</a
      >
    </div>
    <div class="flex mx-8 mb-8">
      <div class="mr-12">
        <p class="text-xs text-primary-600 mb-0">Pack</p>
        <p class="text-primary-800 font-semibold capitalize">
          {activePurchase.name}
        </p>
      </div>
      <div class="mr-12">
        <p class="text-xs text-primary-600 mb-0">Usage</p>
        <p class="text-primary-800 font-semibold">
          {currencyFmt.format(activePurchase.amount_used / 100)}
          <span class="text-xs text-primary-600">
            / {currencyFmt.format(activePurchase.credited_amount / 100)}</span
          >
        </p>
      </div>
      <div class="mr-12">
        <p class="text-xs text-primary-600 mb-0">Payment Status</p>
        <p class="text-primary-800 font-semibold capitalize">
          {activePurchase.payment_status}
        </p>
      </div>
      <div class="mr-12">
        <p class="text-xs text-primary-600 mb-0">License Status</p>
        <p class="text-primary-800 font-semibold capitalize">
          {activePurchase.status}
        </p>
      </div>
      <div class="flex-auto">
        {#if !showRecharge}
          <a
            href="#"
            on:click|preventDefault={toggleRecharge}
            class="float-right button button-subtle rounded-full">Recharge</a
          >
        {/if}
      </div>
    </div>
    {#if showRecharge}
      <RechargePack
        {liteLicensePackPurchases}
        {selectedPack}
        {url}
        bind:showRecharge
      />
    {/if}
    {#each queuedPurchases as pack, i}
      {#if i === 0}
        <p class="text-xs text-primary-500 ml-8">
          Plan will switch to <span class="font-semibold text-primary-800"
            >{pack.name}</span
          >
          with a credit of
          <span class="font-semibold text-primary-800"
            >{currencyFmt.format(pack.credited_amount / 100)}</span
          > after current pack expires
        </p>
      {:else}
        <p class="ml-8 text-xs text-primary-800 mb-1">
          {pack.name} - {currencyFmt.format(pack.credited_amount / 100)}
        </p>
      {/if}
    {/each}
  </div>
{:else if showModify}
  <NewLiteLicensePackPurchase
    {liteLicensePacks}
    {url}
    bind:showModify
    bind:showCurrentPacks
  />
  <DeactivatePack {isLiteLicenseFeatureDeactivated} {url} />
{/if}
<LiteLicensePackPurchasePayments {liteLicensePackPurchases} {url} />
