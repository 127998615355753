<script>
  import CategorySelectFolder from "./CategorySelectFolder.svelte";
  import { createEventDispatcher, onMount } from "svelte";
  import { fade } from "svelte/transition";
  import { jsonHeaders } from "../shared/utils.js";

  export let activeCategory, sourceCategory, url, showCategoryMoveForm, errors;

  let apiUpdateInProgress = false;
  const hideForm = () => (showCategoryMoveForm = false);

  const moveCategory = () => {
    console.log("source", sourceCategory, "target", activeCategory);
    if (sourceCategory.id === activeCategory.id) {
      alert("Cannot move to same category");
    } else if (sourceCategory.parent_category_id === activeCategory.id) {
      alert("Cannot move to same root");
    } else if (sourceCategory.parent_category_id === activeCategory.id) {
      alert("Cannot move to same root");
    } else if (sourceCategory.parent_category_id === null) {
      alert("Cannot move roots");
    } else {
      // call api
      apiUpdateInProgress = true;
      console.log(`call api with base url ${url}`);
      fetch(`${url}/move_category`, {
        method: "POST",
        headers: jsonHeaders(),
        body: JSON.stringify({
          source_category_id: sourceCategory.id,
          target_category_id: activeCategory.id,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if ("errors" in data) {
            errors = data.errors;
          } else {
            location.href = "/admin/categories";
          }
        })
        .catch((error) => {
          console.log("move category errors", error);
        })
        .finally(() => {
          apiUpdateInProgress = false;
        });
    }
  };

  onMount(() => {
    sourceCategory = activeCategory;
  });
</script>

{#if showCategoryMoveForm}
  <div
    on:click|stopPropagation
    transition:fade
    class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 bg-black bg-opacity-75 fixed z-10 top-0 right-0 bottom-0 left-0"
  >
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" />
    <div
      class="inline-block align-bottom bg-white rounded-lg p-6 pb-0 text-left shadow-xl transform transition-all my-8 align-middle max-w-lg my-8 sm:align-middle w-160"
    >
      <h3 class="text-lg font-semibold mb-2">Move {sourceCategory.name}</h3>
      <form on:submit|preventDefault={moveCategory} action=".">
        <div
          class={apiUpdateInProgress
            ? "absolute z-10 top-0 left-0 bottom-0 right-0 bg-white opacity-50"
            : ""}
        >
          <div
            class="loader loader-default {apiUpdateInProgress
              ? 'is-active'
              : ''}"
            data-text="Saving Category..."
            data-blink
          />
        </div>
        <div class="max-h-96 overflow-y-scroll">
          <CategorySelectFolder
            bind:activeCategory
            {url}
            showRadioButtons="true"
          />
          {#if !apiUpdateInProgress}
            <div class="bg-primary-100 p-6">
              <input type="submit" value="Apply" class="button-primary" />
              <a href="#" on:click={hideForm} class="button-secondary ml-6"
                >Cancel</a
              >
            </div>
          {/if}
        </div>
      </form>
    </div>
  </div>
{/if}
