<script>
  import { jsonFileUploadHeaders } from "../../shared/utils.js";
  import { fade } from "svelte/transition";
  export let brands, createAccessRequestUrl;
  let showForm = false;
  let fileName = "";
  let requestedBrand = {};
  let errors = {};
  const readFile = (input) => {
    if (input.target.files && input.target.files[0]) {
      fileName = input.target.files[0].name;
    }
  };
  const requestAccessToBrand = (brand) => {
    requestedBrand = brand;
    showForm = true;
  };

  const createAccessRequest = (event) => {
    // reload same page
    const formData = new FormData(event.target);
    return fetch(createAccessRequestUrl, {
      method: "POST",
      headers: jsonFileUploadHeaders(),
      body: formData,
    })
      .then((response) =>
        response.json().then((data) => ({
          code: response.status,
          responseJson: data,
        }))
      )
      .then((data) => {
        console.log(data.responseJson);
        if (data.code === 201) {
          location.href = location.href;
        } else {
        }
      });
  };

  const hideForm = () => {
    showForm = false;
  };

  const statusDate = (brand) => {
    if (brand.brand_access_request_status === "pending") {
      return `Requested on ${new Date(
        Date.parse(brand.brand_access_request_requested_at)
      ).toLocaleDateString()}`;
    } else if (brand.brand_access_request_status === "approved") {
      return `Available since on ${new Date(
        Date.parse(brand.brand_access_request_approved_at)
      ).toLocaleDateString()}`;
    } else if (brand.brand_access_request_status === "denied") {
      return `Request denied on ${new Date(
        Date.parse(brand.brand_access_request_denied_at)
      ).toLocaleDateString()}`;
    }
    return "-";
  };
</script>

<table class="w-full">
  <thead>
    <tr class="text-xs text-primary-500 uppercase tracking-wider">
      <th class="font-medium px-8 py-3 text-left">Brand</th>
      <th class="font-medium px-8 py-3 text-left">Status</th>
      <th class="font-medium px-8 py-3 text-left">Date</th>
      <th class="font-medium px-8 py-3 text-left" />
    </tr>
  </thead>
  <tbody class="text-primary-800">
    {#each brands as brand (brand.id)}
      <tr transition:fade class="border-t border-gray-300">
        <td class="px-8 py-4 text-left">{brand.name}</td>
        <td class="px-8 py-4 text-left">
          {brand.brand_access_request_status || "-"}
        </td>
        <td class="px-8 py-4 text-left">
          {statusDate(brand)}
        </td>
        <td class="px-8 py-4 text-left">
          {#if !brand.brand_access_request_status}
            <a
              href="#"
              on:click|preventDefault={() => requestAccessToBrand(brand)}
              class="button">Request Access</a
            >
          {/if}
        </td>
      </tr>
    {/each}
  </tbody>
</table>

{#if showForm}
  <div
    transition:fade
    class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 bg-black bg-opacity-75 fixed z-10 top-0 right-0 bottom-0 left-0"
  >
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" />
    <div
      class="inline-block align-bottom bg-white rounded-lg p-6 text-left shadow-xl transform transition-all my-8 align-middle max-w-lg my-8 sm:align-middle w-160"
    >
      <button
        on:click={hideForm}
        class="absolute top-0 right-0 -mt-2 -mr-2 p-1 text-center h-8 w-8 bg-white rounded-full"
        >×</button
      >

      <h3 class="text-lg font-semibold mb-2">Request Access</h3>

      <form on:submit|preventDefault={createAccessRequest} action=".">
        <div class="hidden">
          <label class="label block" for="brand_store_id">brand_store_id</label>
          <input
            type="text"
            id="sku"
            class="w-full"
            name="brand_store_id"
            value={requestedBrand.id}
          />
        </div>

        <div>
          <label class="label block" for="access_code">Access Code</label>
          <input
            type="text"
            id="access-code"
            class="w-full"
            name="access_code"
            required
          />
          {#if errors.access_code}
            <span class="field-error">{errors.access_code[0]}</span>
          {/if}
        </div>

        <div class="row-span-3 pt-2 col-start-3">
          <p class="label">Certificate</p>
          {#if errors.file}
            <span class="field-error">{errors.file[0]}</span>
          {/if}
          <div
            class="image-upload-wrap bg-primary-100 border border-gray-300 shadow-sm rounded-md relative h-36 flex flex-wrap justify-center"
          >
            <span class=" icon icon-file mt-6 h-10 w-full" />
            <input
              class="file-upload-input "
              type="file"
              id="file"
              name="file"
              on:change={readFile}
              required
            />
            <div class="text-center text-primary-800">
              {#if fileName}
                {fileName}
              {:else}
                <p>Drag and drop your brand certificate</p>
                <p>or <span class="font-bold underline">Browse</span></p>
              {/if}
            </div>
          </div>
        </div>

        <button class="button-primary mt-6">Create</button>
      </form>
    </div>
  </div>
{/if}

<style>
  .file-upload-input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
  }
</style>
