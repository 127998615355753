<script>
  import NewProduct from "./NewProduct.svelte";
  import EditProduct from "./EditProduct.svelte";

  export let product,
    should_redirect_to_edit_after_create,
    productKinds,
    variants = [],
    url,
    categoriesUrl,
    taxNumbers,
    storeTaxCodes;
</script>

{#if !product.id}
  <NewProduct
    bind:product
    bind:variants
    {productKinds}
    {url}
    {should_redirect_to_edit_after_create}
  />
{:else}
  <EditProduct
    {product}
    {productKinds}
    {variants}
    {url}
    {categoriesUrl}
    {taxNumbers}
    {storeTaxCodes}
  />
{/if}
