<script>
  export let showForm, parentCategory, url;
  import { createEventDispatcher } from "svelte";
  import { jsonHeaders } from "../shared/utils.js";
  import { fade } from "svelte/transition";

  const dispatch = createEventDispatcher();

  let category = {
    name: "",
    visible_on_store_front: false,
    used_for_components: false,
  };

  const createCategory = () => {
    fetch(requestUrl(), {
      method: "POST",
      headers: jsonHeaders(),
      body: JSON.stringify({
        category: category,
      }),
    }).then((data) => {
      dispatch("message", {});
      hideForm();
    });
  };

  const resetForm = () => {
    category = {
      name: "",
      visible_on_store_front: false,
      used_for_components: false,
    };
  };

  const hideForm = () => (showForm = false);
  const requestUrl = () =>
    parentCategory ? `${url}/${parentCategory.id}/categories/` : url;

  $: resetForm(showForm);
</script>

{#if showForm}
  <div
    transition:fade
    class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 bg-black bg-opacity-75 fixed z-10 top-0 right-0 bottom-0 left-0"
  >
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" />
    <div
      class="inline-block align-bottom bg-white rounded-lg p-6 text-left shadow-xl transform transition-all my-8 align-middle max-w-lg my-8 sm:align-middle w-160"
    >
      <button
        on:click={hideForm}
        class="absolute top-0 right-0 -mt-2 -mr-2 p-1 text-center h-8 w-8 bg-white rounded-full"
        >×</button
      >

      <h3 class="text-lg font-semibold mb-2">
        New SubCategory in {parentCategory ? parentCategory.name : ""}
      </h3>

      <form on:submit|preventDefault={createCategory} action=".">
        <div>
          <label class="label block" for="sku">Name</label>
          <input
            type="text"
            id="sku"
            class="w-full"
            bind:value={category.name}
          />
        </div>

        <div class="p-2">
          <label>
            <input
              type="checkbox"
              bind:checked={category.visible_on_store_front}
            />
            Visible on the Store
          </label>

          <label>
            <input
              type="checkbox"
              bind:checked={category.used_for_components}
            />
            Use for Components
          </label>
        </div>

        <button class="button-primary mt-6">Save</button>
      </form>
    </div>
  </div>
{/if}
