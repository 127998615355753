<style>
  .chart {
    width: 100%;
    height: 19rem;
    padding: 1em 1em 2em 3em;
    box-sizing: border-box;
  }

  .axes {
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #999;
    opacity: 0.3;
  }

  .y.label {
    position: absolute;
    left: -2.5em;
    width: 2em;
    text-align: right;
    bottom: -0.5em;
  }

  .x.label {
    position: absolute;
    width: 4em;
    left: -2em;
    bottom: -22px;
    font-family: sans-serif;
    text-align: center;
  }

  path.current,
  path.previous,
  path.marker-line {
    stroke-linejoin: round;
    stroke-linecap: round;
    fill: none;
    stroke-width: 1px;
  }

  path.current {
    stroke: #f98db4;
    stroke-width: 3px;
  }

  path.previous {
    stroke: #b1aafd;
  }

  path.marker-line {
    stroke: #f1f2f5;
  }

  .active-point {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #f65a93;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }

  .active-notes {
    position: absolute;
    transform: translate(10%, -50%);
    left: 0;
  }

  .active-notes.last {
    transform: translate(-10%, -50%);
    right: 0;
    left: auto;
  }
</style>

<script>
  import { dasherize } from "../shared/utils";
  import * as Pancake from "@sveltejs/pancake";

  export let metadata,
    title,
    callouts,
    current,
    previous,
    maxValue,
    compare = true;

  let minX = 0,
    minY = 0,
    maxX = 1,
    maxY = 1,
    multiple = maxY / 4,
    lines = [multiple, multiple * 2, multiple * 3],
    closest,
    currentPoints = [],
    previousPoints = [],
    dates = [],
    displayDays = 1;

  const sum = (total, row) => total + row.value;

  const updateData = () => {
    if (current && previous) {
      if (previous.length > current.length) {
        previous = previous.slice(0, current.length);
      }
    }

    currentPoints = current.map((p, index) => ({
      x: index,
      y: p.value,
      summary: p,
      previousSummary: previous[index],
    }));
    previousPoints = previous.map((p, index) => ({
      x: index,
      y: p.value,
      summary: p,
    }));

    dates = current.map((p, index) => p.timestamp);
    let days = dates.length - 1;

    if (days > 7) {
      let rounding = Math.floor(days / 4);
      displayDays = Math.floor(days / rounding);
    } else {
      displayDays = days;
    }

    maxX = metadata.days - 1;
    maxY = maxValue;
    multiple = maxY / 4;
    lines = [multiple, multiple * 2, multiple * 3];
  };

  const getDate = (index) => formatDate(dates[index]);

  const formatDate = (dateString) => {
    let date = new Date(dateString);
    let day = date.getDate();
    let month = date.toLocaleString("default", { month: "short" });
    return `${day} ${month}`;
  };

  $: updateData(current);
</script>

<div id={`line-graph-callouts-${dasherize(title)}`} class="my-8">
  {#each callouts as callout}
    <div
      class="inline-block pr-8"
      id={`line-graph-callout-${dasherize(callout.label)}`}
    >
      <span
        id={`line-graph-callout-${dasherize(callout.label)}-value`}
        class="text-2xl font-semibold mr-1">{callout.value}</span
      >
      <span
        id={`line-graph-callout-${dasherize(callout.label)}-difference`}
        class={callout.difference > 0 ? "text-green-500" : "text-red-500"}
      >
        {callout.difference > 0 ? "↑" : "↓"}
        {Math.abs(callout.difference)}
      </span>
      <span
        id={`line-graph-callout-${dasherize(callout.label)}-label`}
        class="block text-xs uppercase h-4">{callout.label}</span
      >
    </div>
  {/each}
</div>

{#if currentPoints.length > 1}
  <div class="chart" id={`line-graph-chart-${dasherize(title)}`}>
    <Pancake.Chart x1={minX} x2={maxX} y1={minY} y2={maxY}>
      <Pancake.Box x2={maxX} y2={maxY}>
        <div class="axes" />
      </Pancake.Box>

      <Pancake.Grid vertical count={displayDays} let:value>
        <span class="x label text-gray-500 -bottom-8 text-xs"
          >{getDate(value)}</span
        >
      </Pancake.Grid>

      <Pancake.Grid horizontal count={4} let:value>
        {#if value != 0}
          <span class="y label text-gray-500 -left-10 text-xs">{value}</span>
          <div class="h-full border-t border-gray-100" />
        {/if}
      </Pancake.Grid>

      <Pancake.Svg class="graph-lines">
        {#if compare && previousPoints.length > 1}
          <Pancake.SvgLine data={previousPoints} let:d>
            <path class="previous" {d} />
          </Pancake.SvgLine>
        {/if}

        {#if currentPoints.length > 1}
          <Pancake.SvgLine data={currentPoints} let:d>
            <path class="current" {d} />
          </Pancake.SvgLine>
        {/if}
      </Pancake.Svg>

      {#if closest}
        <Pancake.Point x={closest.x} y={closest.y}>
          <span class="active-point" />
          <div
            class="active-notes {parseInt(closest.x) >=
            parseInt(currentPoints.length - 2)
              ? 'last'
              : ''} text-sm bg-primary-900 text-primary-300 px-4 py-2 rounded shadow-lg"
          >
            <h3 class="font-medium text-xs mb-1 mr-4">
              <span
                class="inline-block mr-2 rounded-sm w-2 h-2 mt-2 bg-pink-300"
              />
              {formatDate(closest.summary.timestamp)}
            </h3>
            <div class="block whitespace-nowrap">
              {title}:
              <span class="text-white font-medium">{closest.summary.value}</span
              >
            </div>

            {#if compare && closest.previousSummary}
              <hr class="my-3 border-dashed border-primary-600" />
              <h3 class="font-medium text-xs mb-1">
                <span
                  class="inline-block mr-2 rounded-sm w-2 h-2 mt-2 bg-secondary-400"
                />
                {formatDate(closest.previousSummary.timestamp)}
              </h3>
              <div class="block whitespace-nowrap">
                {title}:
                <span class="text-white font-medium"
                  >{closest.previousSummary.value}</span
                >
              </div>
            {/if}
          </div>
        </Pancake.Point>
      {/if}

      {#if closest}
        <Pancake.Box x={closest.x} y={closest.y}>
          <span class="active-line" />
          <div class="h-full border-l border-gray-200" />
        </Pancake.Box>
      {/if}

      <Pancake.Quadtree data={currentPoints} bind:closest />
    </Pancake.Chart>
  </div>
{/if}
