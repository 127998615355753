<script>
  import Flatpickr from "svelte-flatpickr";
  import { createEventDispatcher, beforeUpdate } from "svelte";

  export let start_date,
    end_date,
    extradata = {};

  let datesArray,
    flatpickrOptions = {};
  const dispatch = createEventDispatcher();

  const setDateArray = (start_date, end_date) => {
    datesArray = [start_date, end_date];
  };

  beforeUpdate(() => {
    flatpickrOptions = {
      mode: "range",
      disable: [
        {
          from: extradata.start_date,
          to: extradata.end_date,
        },
      ],
      onChange: (selectedDates, dateStr, instance) => {
        if (selectedDates.length === 2) {
          setDates(selectedDates);
        }
      },
    };
  });

  const parseDate = (date) => {
    let timezoneOffset = new Date().getTimezoneOffset() * 60000;
    return new Date(date - timezoneOffset).toISOString().split("T")[0];
  };

  const setDates = (datesArray) => {
    start_date = parseDate(new Date(datesArray[0]));
    end_date = parseDate(new Date(datesArray[1]));
    dispatch("change", {
      start: new Date(datesArray[0]),
      end: new Date(datesArray[1]),
    });
  };

  $: setDateArray(start_date, end_date);
</script>

<div class="inline-block w-60">
  <Flatpickr options={flatpickrOptions} bind:value={datesArray} />
</div>
