<script>
  import { jsonHeaders } from "../../shared/utils.js";
  import Field from "../../forms/Field.svelte";
  export let account, bankDetails;
  let apiInProgress;

  const updateBankDetails = () => {
    apiInProgress = true;
    fetch(`/vizstore/admin/accounts/${account.id}/update_bank_details`, {
      method: "POST",
      headers: jsonHeaders(),
      body: JSON.stringify(bankDetails),
    })
      .then((response) => console.log(response))
      .finally(() => {
        apiInProgress = false;
      });
  };
</script>

<div class="grid grid-cols-2 gap-2 border-t border-gray-300 pt-12 mt-12">
  <h3 class="text-lg font-semibold col-span-2">Bank Details</h3>

  <Field
    bind:field={bankDetails.bank_ac_name}
    label="Account Name"
    inputCss="w-3/5"
  />
  <Field
    bind:field={bankDetails.bank_ac_number}
    label="Account Number"
    inputCss="w-3/5"
  />
  <Field
    bind:field={bankDetails.bank_name}
    label="Bank Name"
    inputCss="w-3/5"
  />
  <Field
    bind:field={bankDetails.bank_ifsc_code}
    label="IFSC Code"
    inputCss="w-3/5"
  />
</div>
<button
  class="button-primary mt-6"
  disabled={apiInProgress ? true : false}
  on:click|preventDefault={updateBankDetails}
  >{apiInProgress ? "Updating" : "Update Bank Details"}</button
>
