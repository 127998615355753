<script>
  import debounce from "lodash/debounce";
  import { fade } from "svelte/transition";
  import { jsonHeaders, jsonFileUploadHeaders } from "../../shared/utils.js";
  import AddNewMaterialMedia from "./AddNewMaterialMedia.svelte";
  export let mediaUploadUrl, mediaDeleteUrl, productUrl, material;
  let mediaPathUrls = material.media_path_urls || [];
  let mediaPaths = material.media_paths || [];
  let shouldShowNewMedia = false;
  const showNewMedia = () => {
    shouldShowNewMedia = true;
  };
  const hideMediaForm = () => {
    shouldShowNewMedia = false;
  };
  const deleteMedia = (mediaUrl, index) => {
    const pathName = mediaPaths[index];
    mediaPathUrls = mediaPathUrls.filter((item) => item !== mediaUrl);
    mediaPaths = mediaPaths.filter((_item, i) => i !== index);
    fetch(mediaDeleteUrl, {
      method: "POST",
      headers: jsonHeaders(),
      body: JSON.stringify({ media_path: pathName }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(`Delete media`, data);
      });
  };
</script>

<div
  transition:fade
  class="gap-y-8 gap-x-16 bg-white text-primary-800 border border-primary-300 pt-8 px-8 m-8 "
>
  <div class="flex items-center mb-8">
    <h2 class="h2 flex-auto">Material Media</h2>
    <button class="button" on:click|preventDefault={showNewMedia}
      >Add new media</button
    >
  </div>
  <ul class="grid grid-cols-12 gap-4 {mediaPathUrls.length > 0 ? 'mb-8' : ''}">
    {#each mediaPathUrls as mediaUrl, index (mediaUrl)}
      <li
        class="border border-secondary-200 col-span-4 relative rounded-md overflow-hidden h-48"
      >
        {#if mediaUrl.endsWith("jpeg") || mediaUrl.endsWith("png") || mediaUrl.endsWith("jpg")}
          <img src={mediaUrl} alt="image" />
        {/if}
        {#if mediaUrl.endsWith("mp4")}
          <video controls="controls" src={mediaUrl}>
            <track kind="captions" />
            Your browser does not support the HTML5 Video element.</video
          >
        {/if}
        {#if mediaUrl.endsWith("pdf")}
          <div class="relative">
            <iframe
              src={mediaUrl}
              style="width:400px; height:400px;"
              title="Document"
            />
            <a
              href={mediaUrl}
              target="_blank"
              class="absolute top-2 left-2 button-subtle">Open in new tab</a
            >
          </div>
        {/if}
        <button
          class="absolute top-2 right-2 w-8 h-8 pt-1 pb-1 rounded-md bg-secondary-200 border border-secondary-400"
          on:click|preventDefault={() => deleteMedia(mediaUrl, index)}
          ><span class="icon icon-trash " /></button
        >
      </li>
    {/each}
  </ul>

  {#if shouldShowNewMedia}
    <AddNewMaterialMedia
      {mediaUploadUrl}
      {productUrl}
      onClickCancel={hideMediaForm}
    />
  {/if}
</div>

<style></style>
