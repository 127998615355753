<script>
  import TextArea from "./../forms/TextArea.svelte";

  export let paymentDetails, selectedVariants, showReviewOrder;

  let paymentMethod,
    paymentInfo,
    clearanceMode,
    paymentOptions = [["Offline Payment", "offline-payment"]],
    clearanceModes = [
      ["Cred Club", "cred-club"],
      ["Paytm Mall", "paytm-mall"],
      ["Toppr", "toppr"],
      ["Step Set Go", "step-set-go"],
      ["cash", "cash"],
      ["Other", "other"],
    ];
  let total;

  $: {
    if (!showReviewOrder) {
      paymentDetails = {
        payment_method: paymentMethod,
        payment_info: paymentInfo,
        clearance_mode: clearanceMode,
      };
    }

    total = selectedVariants.reduce(
      (previousValue, currentValue) => previousValue + currentValue.total,
      0
    );
  }
</script>

{#if showReviewOrder}
  <div>
    <p class="mx-2 flex items-center">
      <span class="flex-auto text-xs uppercase text-primary-500 ">Total</span
      ><span class="text-lg text-primary-800 font-bold">₹{total / 100}</span>
    </p>
    <p class="mx-2 flex items-center">
      <span class="flex-auto text-xs uppercase text-primary-500"
        >Payment Method</span
      ><span class="text-lg text-primary-800 font-bold"
        >{paymentOptions.find(
          (item) => item[1] === paymentDetails.payment_method
        )[0]}</span
      >
    </p>
  </div>
{:else}
  <div class="ml-2">
    <label for="paymentMethod" class="">Select payment method</label>
    <select bind:value={paymentMethod} name="paymentMethod" class="">
      {#each paymentOptions as option}
        <option name={option[1]} value={option[1]}>
          {option[0]}
        </option>
      {/each}
    </select>
    <label for="clearanceMode" class="">Select mode</label>
    <select bind:value={clearanceMode} name="clearanceMode" class="">
      {#each clearanceModes as option}
        <option name={option[1]} value={option[1]}>
          {option[0]}
        </option>
      {/each}
    </select>
    <TextArea bind:field={paymentInfo} label="Payment Info" />
  </div>
{/if}
