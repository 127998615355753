<script>
  import ProductKindSelector from "./ProductKindSelector.svelte";
  import VariantAttributes from "./VariantAttributes.svelte";
  import { onMount } from "svelte";
  import { jsonHeaders } from "./../shared/utils.js";

  export let product, productKinds, url, should_redirect_to_edit_after_create;

  let properties,
    nameInput,
    valid = true,
    showSelector = product.kind === null ? true : false;

  onMount(() => nameInput.focus());

  let kinds = [
    {
      value: "basic",
      label: "Basic Product",
      description:
        "A product that is made to order according to a customer’s specifications. A custom product will not have any inventory.",
    },
    {
      value: "with-variants",
      label: "Product with Variants",
      description:
        "A product that has variants with unique attributes. Attributes are features of your product, for example, colour and size.",
    },
    {
      value: "custom",
      label: "Custom Product",
      description:
        "A product that is made to order according to a customer’s specifications. A custom product will not have any inventory.",
    },
  ];

  const setProperties = () =>
    (properties = productKinds.find((k) => k.name === product.kind));

  const createProduct = () => {
    if (valid) {
      fetch(url, {
        method: "POST",
        headers: jsonHeaders(),
        body: JSON.stringify({ product: product }),
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          }
        })
        .then((data) => {
          product = data.product;
          if (should_redirect_to_edit_after_create) {
            console.log("Redirecting to edit");
            location.href = `${url}/${product.id}/edit`;
          } else {
            console.log("Using location refresh");
            window.history.replaceState(
              null,
              null,
              `${url}/${product.id}/edit`
            );
          }
        });
    }
  };

  const isValid = () => {
    valid = true;

    if (!(product.name && product.name.length > 0)) {
      valid = false;
    }

    if (!product.kind) {
      valid = false;
    }

    if (
      properties &&
      properties.has_variants === true &&
      product.variant_attributes
    ) {
      for (let attribute of product.variant_attributes) {
        if (
          !(
            attribute &&
            attribute.kind.length > 0 &&
            attribute.properties.length > 0
          )
        ) {
          valid = false;
        }
      }
    }
  };

  $: setProperties(product);
  $: isValid(product);
</script>

<form action={url} class="p-8">
  <h2 class="h2 mt-4 mb-8 col-span-3 overflow-hidden">
    <a href="/admin/products" class="float-left w-8 pt-2">←</a>
    <input
      placeholder="Enter Product Name"
      bind:this={nameInput}
      bind:value={product.name}
      type="text"
      class="w-10/12 bg-transparent border-none ring-0 shadow-none"
    />
  </h2>

  {#if showSelector}
    {#if !product.kind || properties.has_variants === false}
      <h4 class="text-xs uppercase tracking-wide col-span-2 font-medium">
        Select Product Type
      </h4>
      <div class="grid grid-cols-3 gap-4 w-full">
        <ProductKindSelector bind:field={product.kind} options={kinds} />
      </div>
    {:else}
      <VariantAttributes bind:attributes={product.variant_attributes} />
    {/if}
  {/if}

  <div class="col-span-2 text-center">
    <button
      disabled={!valid}
      on:click|preventDefault={createProduct}
      class="button-primary w-48 mt-4">Next</button
    >
    <a href="/admin/products" class="block mt-12 text-sm">Cancel</a>
  </div>
</form>
