<script>
  import { jsonHeaders } from "./../../shared/utils.js";
  import CategorySelect from "./../../categories/CategorySelect.svelte";
  export let mountedToCategory, categoriesUrl, mountStatus, accessRequestId;

  let showCategoriesForm = false;
  let activeCategory = mountedToCategory;

  const changeCategory = () => {
    showCategoriesForm = true;
  };

  const checkMountPoint = (activeCategory) => {
    if (mountedToCategory) {
      if (mountedToCategory.id != activeCategory.id) {
        updateMountPoint();
        showCategoriesForm = false;
      }
    } else {
      if (activeCategory) {
        createMountPoint();
        showCategoriesForm = false;
      }
    }
  };

  const createMountPoint = () => {
    mountedToCategory = activeCategory;
    mountStatus = "in-progress";
    const createMountUrl = "/vizstore/admin/store_catalogs/create_mount_point";
    fetch(createMountUrl, {
      method: "POST",
      headers: jsonHeaders(),
      body: JSON.stringify({
        access_request_id: accessRequestId,
        target_mount_to_category_id: mountedToCategory.id,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      });
  };

  const updateMountPoint = () => {
    mountedToCategory = activeCategory;
    mountStatus = "in-progress";
  };

  $: checkMountPoint(activeCategory);
</script>

{#if mountStatus === "in-progress"}
  <p>Mount in progress at {activeCategory.name}</p>
{:else if mountStatus === "failed"}
  <p>Mount failed</p>
{:else}
  <div class="relative z-10">
    {#if mountedToCategory}
      <span class="label">Mounted at</span>
    {:else}
      <span class="label">Create Mount Point</span>
    {/if}
    <select
      name=""
      id=""
      on:mousedown|preventDefault
      on:click|preventDefault={changeCategory}
    >
      {#if activeCategory}
        <option value={activeCategory.id}>{activeCategory.name}</option>
      {:else}
        <option value="" disabled>Select Category to Mount</option>
      {/if}
    </select>
    {#if showCategoriesForm & !mountedToCategory}
      <CategorySelect
        bind:activeCategory
        url={categoriesUrl}
        bind:show={showCategoriesForm}
        popupKind="select"
        cssClasses={"absolute -ml-4 w-72"}
      />
    {/if}
  </div>
{/if}
