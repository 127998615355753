<script>
  import chart from "../../../images/icons/chart.svg";

  export let store;
</script>

<ul class="grid-cols-3  grid text-sm">
  <li class="col-span-2 border-b border-primary-200 pb-4">
    <h4 class="uppercase text-xs mb-2 font-medium text-primary-900">
      Description
    </h4>
    <p class="text-sm ">
      {store.description || "-"}
    </p>
  </li>
  <li class="col-span-2 border-b border-primary-200 py-4">
    <h4 class="uppercase text-xs mb-2 font-medium text-primary-900">
      Administrator
    </h4>
    <ul>
      <li class="flex">
        <p class="text-primary-600">
          <img class="nav-icon" src={chart} alt="scenes icon" />
          Name
        </p>
        <p class="ml-4">{store.administrator_name}</p>
      </li>
      <li class="flex">
        <p class="text-primary-600">
          <img class="nav-icon" src={chart} alt="scenes icon" />
          Phone
        </p>
        <p class="ml-4">{store.phone}</p>
      </li>
      <li class="flex">
        <p class="text-primary-600">
          <img class="nav-icon" src={chart} alt="scenes icon" />
          Email
        </p>
        <p class="ml-4">{store.email}</p>
      </li>
    </ul>
  </li>
  <li class="col-span-2 border-b border-primary-200 py-4">
    <h4 class="uppercase text-xs mb-2 font-medium text-primary-900">
      Store Address
    </h4>
    <div class="flex">
      <p class="text-primary-600">
        <img class="nav-icon" src={chart} alt="scenes icon" />
        Address
      </p>
      <p class="ml-4">
        {store.address_string}
      </p>
    </div>
  </li>
  <li class="col-span-2 border-b border-primary-200 py-4">
    <h4 class="uppercase text-xs mb-2 font-medium text-primary-900">
      Tax Info
    </h4>
    <ul>
      <li class="flex">
        <p class="text-primary-600">
          <img class="nav-icon" src={chart} alt="scenes icon" />
          PAN
        </p>
        <p class="ml-4">{store.pan_number}</p>
      </li>
      <li class="flex">
        <p class="text-primary-600">
          <img class="nav-icon" src={chart} alt="scenes icon" />
          GST
        </p>
        <p class="ml-4">{store.gst_number}</p>
      </li>
    </ul>
  </li>
</ul>
