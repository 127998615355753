<style>
</style>

<script>
  export let field, options, error;
</script>

{#each options as { value, label, subtitle }}
  <label
    class="flex-1 p-4 rounded-md border border-primary-300 cursor-pointer bg-primary-100 hover:border-primary-800 rounded {field ===
    value
      ? 'bg-primary-200'
      : ''}  "
  >
    <input
      type="radio"
      id="option"
      bind:group={field}
      {value}
      class="opacity-0 absolute"
    />
    <span />
    <p class="font-bold">{label}</p>
    <p>{subtitle}</p>
  </label>
{/each}

{#if error}<span class="field-error">{error}</span>{/if}
