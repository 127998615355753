<script>
  import { onMount } from "svelte";
  import { jsonHeaders, securityToken } from "../shared/utils.js";
  import { fade } from "svelte/transition";
  import Field from "../forms/Field.svelte";

  let shipmentId, orderNumber;
  let shippingPartnerName, trackingNumber, apiInProgress, contentValid;

  let shouldShowContent = false;

  const showContent = (a1, a2) => {
    shipmentId = a1;
    orderNumber = a2;
    shouldShowContent = true;
  };

  const closeContent = () => {
    shouldShowContent = false;
    shipmentId = null;
    orderNumber = null;
    trackingNumber = null;
    shippingPartnerName = null;
  };

  const updateExternal = () => {
    apiInProgress = true;
    fetch(`/admin/shipments/${shipmentId}/update_external`, {
      method: "POST",
      headers: jsonHeaders(),
      body: JSON.stringify({
        shipping_partner_name: shippingPartnerName,
        tracking_number: trackingNumber,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        location.reload();
      })
      .finally(() => {
        apiInProgress = false;
      });
  };

  onMount(() => {
    document
      .querySelectorAll(".button-update-external-shipment")
      .forEach((element) => {
        element.onclick = (event) => {
          event.preventDefault();
          showContent(element.dataset.id, element.dataset.order);
        };
      });
    return () => {
      document
        .querySelectorAll(".button-update-external-shipment")
        .forEach((element) => {
          element.onclick = null;
        });
    };
  });

  $: {
    if (trackingNumber && shippingPartnerName) {
      contentValid =
        trackingNumber.length > 0 && shippingPartnerName.length > 0;
    }
  }
</script>

{#if shouldShowContent}
  <div
    transition:fade
    class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 bg-black bg-opacity-75 fixed z-10 top-0 right-0 bottom-0 left-0 transition-all duration-150"
  >
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" />
    <div
      class="inline-block align-bottom bg-white max-h-screen rounded-lg p-8 text-left shadow-xl transform transition-all my-8 align-middle max-w-6xl sm:align-middle w-full"
    >
      <button
        on:click|preventDefault={closeContent}
        class="absolute top-0 right-0 -mt-2 -mr-2 p-1 text-center h-8 w-8 bg-white rounded-full shadow-sm"
        >×</button
      >
      <h3 class="text-lg text-primary-900 font-semibold mb-2">
        Update External Shipment Details - {orderNumber}
      </h3>

      <div class="flex">
        <div class="leading-none">
          <label for="shippingPartnerName">Shipment Partner</label>
          <Field
            bind:field={shippingPartnerName}
            name="shippingPartnerName"
            required="true"
          />
        </div>
      </div>

      <div class="flex">
        <div class="leading-none">
          <label for="trackingNumber">Tracking Number</label>
          <Field
            bind:field={trackingNumber}
            name="trackingNumber"
            required="true"
          />
        </div>
      </div>
      {#if !apiInProgress}
        <div class="flex py-4">
          <div class="leading-none">
            <button
              on:click|preventDefault={closeContent}
              class="button button-danger">Cancel</button
            >
          </div>
          {#if contentValid}
            <div class="ml-4">
              <button
                on:click|preventDefault={updateExternal}
                class="button button-primary">Update Details</button
              >
            </div>
          {/if}
        </div>
      {/if}
    </div>
  </div>
{/if}
