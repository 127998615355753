<script>
  import { jsonHeaders } from "../../../shared/utils.js";
  import LiteLicensePackCard from "./LiteLicensePackCard.svelte";
  export let liteLicensePacks, url, createUrl;

  const destroyPack = (lpid) => {
    const confirmed = window.confirm("Are you sure?");
    if (confirmed) {
      fetch(`${url}/${lpid}`, {
        method: "DELETE",
        headers: jsonHeaders(),
      }).then((resp) => {
        if (resp.status === 200) {
          location.href = url;
        }
      });
    }
  };
</script>

<div class="grid grid-cols-4 gap-12 border-b border-gray-200 pb-8 mb-8">
  <a
    href={createUrl}
    class="flex flex-col min-h-24 items-center justify-center border border-dashed border-secondary-200 rounded-md "
  >
    <div class="bg-secondary-200 w-16 h-16 rounded-full icon icon-plus mb-2" />
    <p class="text-primary-800">Create New Pack</p>
  </a>
  {#each liteLicensePacks as liteLicensePack (liteLicensePack.id)}
    {#if liteLicensePack.status === "active"}
      <LiteLicensePackCard {liteLicensePack} {url} />
    {/if}
  {/each}
</div>

<h2 class="font-bold text-lg text-primary-800 mb-8">Archived</h2>

<div class="grid grid-cols-4 gap-12">
  {#each liteLicensePacks as liteLicensePack (liteLicensePack.id)}
    {#if liteLicensePack.status === "inactive"}
      <a
        href={`${url}/${liteLicensePack.id}/edit`}
        class="archived-pack text-secondary-400"
      >
        <div class="border border-secondary-200 rounded-md">
          <div class="bg-secondary-100 p-4">
            <p class="flex justify-end mb-4 text-xs">Archived</p>

            <p class=" font-bold capitalize mb-5">
              {liteLicensePack.name}
            </p>
            <div class="flex items-end font-bold mb-6">
              <p class="text-3xl">
                ₹{liteLicensePack.total_price_units / 100}
              </p>
              <p class=" ml-2 ">credit</p>
            </div>
          </div>
        </div>
      </a>
    {/if}
  {/each}
</div>
