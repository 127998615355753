<script>
  import { onMount } from "svelte";
  import { fade } from "svelte/transition";
  import { jsonFileUploadHeaders } from "../../shared/utils.js";
  export let createUrl = "";
  export let editUrlPrefixPath = "";
  let catalog = {
    name: "",
    country: "in",
    file: "",
  };
  let errors = {};
  let fileName = "";
  let shouldShowForm = false;
  const toggleForm = () => {
    shouldShowForm = !shouldShowForm;
    catalog = {
      name: "",
      country: "in",
      file: "",
    };
    errors = {};
  };

  const readFile = (input) => {
    if (input.target.files && input.target.files[0]) {
      fileName = input.target.files[0].name;
    }
  };

  const createCatalog = (event) => {
    const formData = new FormData(event.target);
    return fetch(createUrl, {
      method: "POST",
      headers: jsonFileUploadHeaders(),
      body: formData,
    })
      .then((response) =>
        response.json().then((data) => ({
          code: response.status,
          responseJson: data,
        }))
      )
      .then((data) => {
        console.log(data.responseJson);
        if (data.code === 201) {
          catalog = data.responseJson;
          location.href = `${editUrlPrefixPath}/${catalog.id}/edit`;
          errors = nil;
        } else {
          errors = data.responseJson.errors;
        }
        console.log(catalog, errors);
      });
  };

  onMount(() => {
    document.querySelector("#button-add-catalog").onclick = (event) => {
      event.preventDefault();
      toggleForm();
    };
    return () => {
      document.querySelector("#button-add-catalog").onclick = null;
    };
  });
</script>

{#if shouldShowForm}
  <div
    transition:fade
    class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 bg-black bg-opacity-75 fixed z-10 top-0 right-0 bottom-0 left-0 transition-all duration-150"
  >
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" />
    <div
      class="inline-block align-bottom bg-white rounded-lg p-8 text-left shadow-xl transform transition-all my-8 align-middle max-w-lg sm:align-middle w-160"
    >
      <button
        on:click={toggleForm}
        class="absolute top-0 right-0 -mt-2 -mr-2 p-1 text-center h-8 w-8 bg-white rounded-full shadow-sm"
        >×</button
      >
      <h3 class="text-lg text-primary-900 font-semibold mb-2">New Catalog</h3>
      <form on:submit|preventDefault={createCatalog} action=".">
        <div>
          <label class="label block" for="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            class="w-full"
            required
            bind:value={catalog.name}
          />
          {#if errors.name}<span class="field-error">{errors.name[0]}</span
            >{/if}
        </div>
        <div>
          <label class="label block" for="sub_brand_name">Brand Name</label>
          <input
            type="text"
            id="sub_brand_name"
            name="sub_brand_name"
            class="w-full"
            required
            bind:value={catalog.sub_brand_name}
          />
          {#if errors.sub_brand_name}<span class="field-error"
              >{errors.sub_brand_name[0]}</span
            >{/if}
        </div>
        <div class="row-span-3 pt-2 col-start-3">
          <p class="label">Upload</p>
          {#if errors.file}
            <span class="field-error">{errors.file[0]}</span>
          {/if}
          <div
            class="image-upload-wrap bg-primary-100 border border-gray-300 shadow-sm rounded-md relative h-36 flex flex-wrap justify-center"
          >
            <span class=" icon icon-file mt-6 h-10 w-full" />
            <input
              class="file-upload-input "
              type="file"
              id="file"
              name="file"
              on:change={readFile}
              required
            />
            <div class="text-center text-primary-800">
              {#if fileName}
                {fileName}
              {:else}
                <p>Drag and drop a csv file</p>
                <p>or <span class="font-bold underline">Browse</span></p>
              {/if}
            </div>
          </div>
        </div>
        <p class="label">Country</p>
        <select
          required
          bind:value={catalog.country}
          class="select disabled"
          name="country"
          id="country"
        >
          <option value="in">India</option>
          <!-- <option value="us">US</option> -->
        </select>
        {#if errors.country}
          <span class="field-error">{errors.country[0]}</span>
        {/if}
        <button class="button-primary mt-6" type="submit">Create</button>
      </form>
    </div>
  </div>
{/if}

<style>
  .file-upload-input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
  }
</style>
