<script>
  import LiteLicensePayment from "./LiteLicensePayment.svelte";

  export let url, liteLicensePackPurchases;
</script>

<h3 class="mt-8 mb-4 text-md text-primary-800 font-medium pt-4">Purchases</h3>
<table class="w-full">
  <thead>
    <tr class="border-b border-t border-gray-200">
      <th class="font-medium text-xs text-primary-600 px-4 text-left py-4"
        >Purchase Number</th
      >
      <th class="font-medium text-xs text-primary-600 px-4 text-left py-4"
        >Purchase Status</th
      >
      <th class="font-medium text-xs text-primary-600 px-4 text-left py-4 pl-8"
        >Bill Date</th
      >
      <th class="font-medium text-xs text-primary-600 px-4 text-left py-4"
        >Pack</th
      >
      <th class="font-medium text-xs text-primary-600 px-4 text-left py-4"
        >Invoice Value</th
      >
      <th class="font-medium text-xs text-primary-600 px-4 text-left py-4"
        >Payment Status</th
      >
      <!-- <th class="font-medium text-xs text-primary-600 px-4 text-left py-4"
        >Recieved On</th
      >
      <th /> -->
    </tr>
  </thead>
  <tbody class="text-sm leading-loose text-primary-800">
    {#each liteLicensePackPurchases as purchase}
      <LiteLicensePayment {purchase} {url} />
    {/each}
  </tbody>
</table>
