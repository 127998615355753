<script>
  import { jsonHeaders } from "../shared/utils.js";
  import Field from "../forms/Field.svelte";

  let errors = {},
    description,
    name,
    isTemplate = true;
  export let url, showFromScratch, productPropertySets, productId;
  if (productId) {
    isTemplate = false;
  }
  const options = [
    { kind: "list" },
    { kind: "table" },
    { kind: "media" },
    { kind: "strict_table" },
  ];

  let selected = options[0].kind;

  const createPropertySet = () => {
    fetch(url, {
      method: "POST",
      headers: jsonHeaders(),
      body: JSON.stringify({
        name: name,
        description: description,
        kind: selected,
        product_id: productId,
        is_template: isTemplate,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if ("errors" in data) {
          errors = data.errors;
          console.log(errors);
        } else {
          errors = {};
          let template = data.template;
          if (productId) {
            showFromScratch = false;
            productPropertySets.push(template);
            productPropertySets = productPropertySets;
          } else {
            console.log("Redirecting to edit");
            location.href = `${url}/${template.id}/edit`;
          }
        }
      });
  };
</script>

<Field
  bind:field={name}
  label="Property Name"
  name="prop-name"
  error={errors.name}
/>
<Field
  bind:field={description}
  label="Property Description"
  name="prop-description"
/>

<label for="property-type" class="w-64 mt-4">Select Property Type</label>
<select
  data-test-select-kind="select-kind"
  id="property-type"
  class="w-40 mr-4 capitalize"
  bind:value={selected}
>
  {#each options as option}
    <option value={option.kind}><span class="">{option.kind}</span></option>
  {/each}
</select>
<div class="col-span-2 text-center">
  <button
    data-test-create-property-set="create-property-set"
    on:click|preventDefault={createPropertySet}
    class="button-primary w-48 mt-4">Create</button
  >
  <a href="/admin/property_sets" class="block mt-12 text-sm">Cancel</a>
</div>
