<script>
  import Table from "./Table.svelte";
  import { jsonHeaders, analyticsDataUrl, dasherize } from "../shared/utils.js";
  import { onMount } from "svelte";

  export let title, url, colspan, filters;
  let data, titles;

  onMount(() => {
    getData();
  });

  const getData = (filters) => {
    fetch(analyticsDataUrl(url, filters), {
      method: "GET",
      headers: jsonHeaders(),
    })
      .then((response) => response.json())
      .then((jsonData) => {
        data = jsonData.data;

        if (data.length > 1) {
          titles = jsonData.titles.map((title, index) => ({
            label: title,
            css:
              typeof data[0][index] === "number" ? "text-right" : "text-left",
          }));
        }
      });
  };

  $: getData(filters);
</script>

<div
  id={`table-container-${dasherize(title)}`}
  class="col-span-{colspan} border border-gray-200 p-4 text-primary-800 rounded"
>
  <h3 class="text-lg font-medium mb-4">{title}</h3>

  {#if data}
    <Table {url} {data} {titles} />
  {/if}
</div>
