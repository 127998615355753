import map from "lodash/map";

function securityToken() {
  return document.querySelector("meta[name=csrf-token]").content;
}

function jsonHeaders() {
  return {
    "Content-type": "application/json",
    "X-CSRF-Token": securityToken(),
    accept: "application/json",
  };
}

function jsonFileUploadHeaders() {
  return {
    "X-CSRF-Token": securityToken(),
    accept: "application/json",
  };
}

const analyticsFilterParams = (filters) =>
  map(filters, (value, key) => {
    if (value) {
      return `${key}=${value}`;
    } else {
      return false;
    }
  })
    .filter((item) => item)
    .join("&");

const dasherize = (str) => {
  if (str) {
    return str
      .toString()
      .split(" ")
      .map((item) => item.toLowerCase())
      .join("-");
  }
  return str;
};

export const analyticsDataUrl = (url, filters) =>
  `${url}?${analyticsFilterParams(filters)}`;

export { securityToken, jsonHeaders, jsonFileUploadHeaders, dasherize };
