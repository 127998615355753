<script>
  import Field from "./../forms/Field.svelte";
  export let amountInRupees = 0,
    amountInPaisa = 0,
    error,
    label = "",
    name = "";

  $: {
    amountInPaisa = amountInRupees * 100;
  }
</script>

<Field bind:field={amountInRupees} {label} {name} {error} />
