<script>
  import OrderListingFilter from "./OrderListingFilter.svelte";
  import ShipmentListingFilter from "./ShipmentListingFilter.svelte";

  export let url,
    show_filter_options,
    filter_options,
    sort_options,
    form_values;
  let showFilters = false,
    componentName;

  const components = [
    { title: "OrderListingFilter", componentName: OrderListingFilter },
    { title: "ShipmentListingFilter", componentName: ShipmentListingFilter },
  ];

  components.filter((component) => {
    if (component.title === filter_options.component_name) {
      componentName = component.componentName;
    }
  });
</script>

<form action={url} class="my-8 filter-form" method="GET">
  <div class="flex items-center">
    <input
      type="search"
      bind:value={form_values.search}
      name="search"
      placeholder="Search"
      class="w-1/5 rounded-full text-sm"
    />

    {#if sort_options}
      <div class="w-1/5 flex items-center ml-5">
        <label for="" class="w-24 mr-4">Sort by</label>
        <select bind:value={form_values.sort_by} name="sort_by" class="">
          {#each sort_options as option}
            <option value={option.key}>
              {option.value}
            </option>
          {/each}
        </select>
      </div>
    {/if}
    <div class="flex-auto" />
    {#if show_filter_options}
      <a
        href="#"
        on:click|stopPropagation={() => (showFilters = !showFilters)}
        class="block  border border-secondary-200 outline-none flex items-center m-2 mr-5 px-4 py-2 text-left w-1/8 ml-4 rounded-md {showFilters
          ? 'bg-secondary-100'
          : ''}"><span class="icon icon-filter mr-1" />Filters</a
      >
    {/if}
    <button class="button-primary">Search</button>
  </div>
  <div
    class="w-full flex flex-wrap justify-between mt-1 px-4 pt-2 pb-4 bg-secondary-100 text-sm border border-secondary-200 rounded-md {showFilters
      ? ''
      : 'hidden'}"
  >
    <svelte:component this={componentName} bind:form_values {filter_options} />
  </div>
</form>
