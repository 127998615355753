<script>
  import { onMount } from "svelte";
  import FileUpload from "../forms/FileUpload.svelte";
  import { securityToken } from "../shared/utils.js";

  export let account, deleteLogoUrl, logo;

  let medium = logo;

  onMount(() => {
    // setImage();
    console.log(medium, deleteLogoUrl);
  });

  // const setImage = () =>
  //   (medium = account.media.find((media) => media.kind === "logo"));
</script>

<div class="flex items-center">
  <p class="flex-auto label">Store Logo</p>
  <form
    action={deleteLogoUrl}
    method="POST"
    class={deleteLogoUrl === "" || medium === null ? "hidden" : ""}
  >
    <input type="hidden" name="authenticity_token" value={securityToken()} />
    <button type="submit" class="button-small px-2">Delete logo</button>
  </form>
</div>
<FileUpload attachmentType="Account" kind="logo" id={account.id} {medium} />
