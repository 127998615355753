<script>
  import { dasherize } from "./utils";
  export let tabs, selectedTab;

  const selectTab = (tab) => {
    selectedTab = tab;
  };
</script>

<ul class="tabs col-span-full">
  {#each tabs as tab}
    <li
      on:click={(e) => selectTab(tab)}
      id={`tab-${dasherize(tab.title || "")}`}
      class="cursor-pointer inline-block px-4 {selectedTab === tab
        ? 'text-gray-900 border-b-2 border-gray-900 font-medium'
        : 'text-gray-500'}"
    >
      {tab.title}
    </li>
  {/each}
</ul>
