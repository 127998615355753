<script>
  import { jsonHeaders } from "../shared/utils.js";
  import { fade } from "svelte/transition";
  import Field from "../forms/Field.svelte";

  export let showAddLink, media, propertySet, selectedLabel;
  let mediaLink = "";
  let errors = null;

  const createMedium = (blob) => {
    let mediumUrl = "/admin/media";
    let mediumJSON = {
      record_type: "PropertySet",
      record_id: propertySet.id,
      url: mediaLink,
      label: selectedLabel,
    };

    fetch(mediumUrl, {
      method: "POST",
      headers: jsonHeaders(),
      body: JSON.stringify(mediumJSON),
    })
      .then((response) => response.json())
      .then((data) => {
        if ("errors" in data) {
          errors = data.errors;
          console.log(data);
        } else {
          errors = null;
          media.push(data.medium);
          showAddLink = false;
        }
      });
  };
</script>

{#if showAddLink}
  <div
    class="fixed z-10 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      />

      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
      />
      <div class="float-right relative">
        <div
          transition:fade
          class="flex items-center justify-center bg-opacity-75 fixed z-10 top-0 right-0 bottom-0 left-0"
        >
          <div
            class="container w-128 p-8 bg-white rounded-lg text-left shadow-xl transform transition-all border border-primary-200"
          >
            <a
              href="#"
              on:click={(e) => {
                showAddLink = false;
              }}
              class="absolute top-0 right-0 -mt-2 -mr-2 p-1 text-center h-8 w-8 bg-white rounded-full shadow-md"
              id="close-add-taxcode">×</a
            >

            <h2 class="font-medium capitalize text-primary-800">Add Link</h2>
            <p>
              <Field
                bind:field={mediaLink}
                label="Media Link"
                name="MediaLink"
                {errors}
              />
            </p>
            <button
              data-test-create-medium="create-medium"
              on:click={createMedium}
              class="button-primary"
            >
              Create Medium
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
{/if}
