<script>
  import { jsonHeaders } from "../shared/utils.js";
  import List from "./List.svelte";
  import Table from "./Table.svelte";
  import StrictTable from "./StrictTable.svelte";
  import { onMount } from "svelte";

  let attributes, description, name, kind, selectedComponent;
  let errors = {};
  export let url, template;

  const options = [
    { kind: "list", component: List },
    { kind: "table", component: Table },
    { kind: "media", component: List },
    { kind: "strict_table", component: StrictTable },
  ];

  const setComponent = (kind) => {
    switch (kind) {
      case "list":
        return List;
      case "table":
        return Table;
      case "strict_table":
        return StrictTable;
      case "media":
        return List;
      default:
        break;
    }
  };

  const formatListInfo = () => {
    let i = 0;
    let tempInfo = attributes;
    for (; i < attributes.length; i++) {
      tempInfo[i]["position"] = i;
      if (tempInfo[i]["kind"] == "number") {
        tempInfo[i]["value"] = parseFloat(tempInfo[i]["value"]);
      }
      if (tempInfo[i]["value"] == "") {
        tempInfo.splice(i, 1);
      }
    }
    return tempInfo;
  };

  const formatTableInfo = () => {
    let i = 0;
    console.log(attributes);
    let columns = [];
    if (attributes == null) {
      attributes = {
        columns: [],
        rows: [],
      };
      columns.push({ value: "", position: 0, kind: "text" });
      columns.push({ value: "", position: 1, kind: "text" });
      attributes.columns = columns;
    }
    return attributes;
  };

  const formatInfo = () => {
    if (kind == "list" || kind == "media") {
      return formatListInfo();
    } else if (kind == "table" || kind == "strict_table") {
      return formatTableInfo();
    }
  };

  const updateTempalte = () => {
    attributes = formatInfo();
    fetch(`${url}/${template.id}`, {
      method: "PUT",
      headers: jsonHeaders(),
      body: JSON.stringify({
        id: template.id,
        name: name,
        description: description,
        info: JSON.stringify(attributes),
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if ("errors" in data) {
          errors = data.errors;
        } else {
          let template = data.template;
          console.log("Redirecting to edit");
          location.href = `${url}/${template.id}/edit`;
        }
      });
  };

  const setAttributes = (info, kind) => {
    if (kind == "list" || kind == "media") {
      if (info == null) {
        attributes = [{ value: "", kind: "text" }];
      } else {
        attributes = info;
      }
    } else if (kind == "table" || kind == "strict_table") {
      if (info == null) {
        attributes = { columns: [], rows: [] };
      } else {
        attributes = info;
      }
    }

    return attributes;
  };

  const buildData = (template) => {
    description = template.description;
    name = template.name;
    kind = template.kind;

    attributes = setAttributes(template.info, template.kind);
  };
  onMount(() => {
    selectedComponent = setComponent(template.kind);
    buildData(template);
  });
</script>

<div class="mr-2">
  <label for="" class="label text-xs font-medium"> Property Name </label>
  <input bind:value={name} type="text" name="prop-name" class="block w-full" />
</div>
<div class="mr-2">
  <label for="" class="label text-xs font-medium"> Description </label>
  <input
    bind:value={description}
    type="text"
    name="prop-description"
    class="block w-full"
  />
</div>
<label for="property-type" class="label text-xs font-medium"
  >Property Type</label
>
<span
  class="px-4 py-1 font-bold uppercase text-xs bg-secondary-400 text-white rounded"
  >{kind}</span
>
{#if errors && Object.entries(errors).length != 0}
  {JSON.stringify(errors)}
{/if}
<svelte:component
  this={selectedComponent}
  bind:attributes
  bind:propertySet={template}
/>
<br />
<div class="col-span-2 text-center">
  <button
    on:click|preventDefault={updateTempalte}
    class="button-primary w-48 mt-4"
    data-test-update-prop="update-prop">Update</button
  >
  <a href="/admin/property_sets" class="block mt-12 text-sm">Cancel</a>
</div>
