<script>
  import { fade } from "svelte/transition";
  import { jsonHeaders } from "../../../shared/utils.js";
  import Field from "../../../forms/Field.svelte";
  import LiteLicensePackCard from "./LiteLicensePackCard.svelte";
  export let liteLicensePacks,
    url,
    showModify = false,
    showCurrentPacks;
  let paymentMode,
    bankName,
    chequeNumber,
    selectedPackId = liteLicensePacks.length > 0 ? liteLicensePacks[0].id : "",
    apiInProgress = false;
  let errors = {};

  const onSave = () => {
    apiInProgress = true;
    fetch(`${url}`, {
      method: "POST",
      headers: jsonHeaders(),
      body: JSON.stringify({
        pack_id: selectedPackId,
        payment_mode: paymentMode,
        bank_name: bankName,
        cheque_number: chequeNumber,
      }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.errors) {
          errors = resp.errors;
        } else {
          location.href = url;
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        apiInProgress = false;
      });
  };

  const onCancel = () => {
    if (showModify === true) {
      showModify = false;
      showCurrentPacks = true;
      showRecharge = false;
    } else {
      location.href = url;
    }
  };
</script>

<div class="border border-gray-200 rounded-md ">
  <h4 class="text-primary-800 font-medium text-md p-8 pb-0">
    Select Lite License Pack
  </h4>
  <div class="grid grid-cols-3 gap-12 p-8">
    {#each liteLicensePacks as liteLicensePack (liteLicensePack.id)}
      <LiteLicensePackCard {liteLicensePack} bind:selectedPackId />
    {/each}
  </div>
  <div class="bg-primary-150 p-8 border-t border-primary-200">
    <h4 class="text-primary-800 font-medium text-md">Create Invoice</h4>
    <div class="grid grid-cols-3 gap-8">
      <label
        >Payment Method
        <select
          required
          bind:value={paymentMode}
          class="select mt-1"
          name="payment_mode"
          id="payment_mode"
        >
          {#each ["cheque", "bank-transfer", "link"] as pType (pType)}
            <option value={pType}>{pType}</option>
          {/each}
        </select>
      </label>

      {#if paymentMode === "cheque"}
        <Field
          bind:field={bankName}
          error={errors.bank_name}
          label="Bank Name"
          name="bank_name"
          placeholder="Enter Bank Name"
          required={true}
        />

        <Field
          bind:field={chequeNumber}
          label="Cheque Number"
          error={errors.cheque_number}
          placeholder="Enter Cheque Number"
          required={true}
        />
      {/if}
    </div>
  </div>
  <div class="bg-primary-150 px-8 py-4 border-t border-primary-200">
    <div class="flex">
      {#if !apiInProgress}
        <button on:click|preventDefault={onSave} class="button-primary mr-8"
          >Save</button
        >
        <button on:click|preventDefault={onCancel} class="button-secondary"
          >Cancel</button
        >
      {/if}
    </div>
  </div>
</div>
