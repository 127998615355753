<script>
  import { onMount } from "svelte";
  import { jsonHeaders } from "../../../shared/utils.js";
  import MoneyField from "../../../forms/MoneyField.svelte";
  import Field from "../../../forms/Field.svelte";
  export let liteLicensePack, url;

  let isEdit = false;
  let errors = {};
  let rangeOutput;
  let total_price_units_rupees = liteLicensePack
      ? liteLicensePack.total_price_units / 100
      : 0,
    price_units_per_catalog_rupees = liteLicensePack
      ? liteLicensePack.price_units_per_catalog / 100
      : 0,
    price_units_after_limit_rupees = liteLicensePack
      ? liteLicensePack.price_units_after_limit / 100
      : 0;

  const onSave = () => {
    if (isEdit) {
      // patch
      fetch(`${url}/${liteLicensePack.id}`, {
        method: "PATCH",
        headers: jsonHeaders(),
        body: JSON.stringify({
          lite_license_pack: liteLicensePack,
        }),
      })
        .then((resp) => resp.json())
        .then((resp) => {
          if (resp.errors) {
            errors = resp.errors;
          } else {
            location.href = `${url}/${resp.lite_license_pack.id}/edit`;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      // post
      fetch(`${url}`, {
        method: "POST",
        headers: jsonHeaders(),
        body: JSON.stringify({
          lite_license_pack: liteLicensePack,
        }),
      })
        .then((resp) => resp.json())
        .then((resp) => {
          if (resp.errors) {
            errors = resp.errors;
          } else {
            location.href = `${url}/${resp.lite_license_pack.id}/edit`;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const onCancel = () => {
    location.href = url;
  };

  const applyFromLiteLicensePack = (pack) => {
    isEdit = true;
    total_price_units_rupees = pack.total_price_units / 100;
    price_units_per_catalog_rupees = pack.price_units_per_catalog / 100;
    price_units_after_limit_rupees = pack.price_units_after_limit / 100;
    liteLicensePack = pack;
  };

  const inputRangeValue = (e) => {
    const newVal = Number(
      ((e.target.value - e.target.min) * 100) / (e.target.max - e.target.min)
    );
    rangeOutput.style.left = `calc(${newVal}% + (${8 - newVal * 0.15}px))`;
  };

  const archivePack = (lpid) => {
    const confirmed = window.confirm("Are you sure?");
    if (confirmed) {
      fetch(`${url}/${lpid}/archive`, {
        method: "POST",
        headers: jsonHeaders(),
      }).then((resp) => {
        if (resp.status === 200) {
          location.href = url;
        }
      });
    }
  };

  const unarchivePack = (lpid) => {
    const confirmed = window.confirm("Are you sure?");
    if (confirmed) {
      fetch(`${url}/${lpid}/unarchive`, {
        method: "POST",
        headers: jsonHeaders(),
      }).then((resp) => {
        if (resp.status === 200) {
          location.href = url;
        }
      });
    }
  };

  onMount(() => {
    if (liteLicensePack && liteLicensePack.id) {
      applyFromLiteLicensePack(liteLicensePack);
    } else {
      isEdit = false;
      liteLicensePack = {
        catalog_count_limit: 12,
      };
    }
    for (let e of document.querySelectorAll(
      'input[type="range"].slider-progress'
    )) {
      e.style.setProperty("--value", e.value);
      e.style.setProperty("--min", e.min == "" ? "0" : e.min);
      e.style.setProperty("--max", e.max == "" ? "100" : e.max);
      e.addEventListener("input", () =>
        e.style.setProperty("--value", e.value)
      );
      const newVal = Number(((e.value - e.min) * 100) / (e.max - e.min));
      rangeOutput.style.left = `calc(${newVal}% + (${8 - newVal * 0.15}px))`;
    }
  });
</script>

<div transition:fade class="p-8 gap-0 grid product-container">
  <div class="flex col-span-2">
    <div class="flex-auto">
      <h2 class="h2 mt-4 mb-8">
        <a href={url} class="float-left w-8 pt-2">←</a>
        <Field
          bind:field={liteLicensePack.name}
          error={errors.name}
          placeholder="Enter Pack Name"
          inputCss="w-8/12 font-medium bg-transparent border-none ring-0 shadow-none text-2xl text-primary-900 placeholder-gray-400"
        />
      </h2>
    </div>

    <div class="buttons mt-4 mb-8 text-right">
      <div class="flex">
        <button on:click={onSave} class="button-primary mr-8">Save</button>
        <button on:click={onCancel} class="button-secondary">Cancel</button>
      </div>
    </div>
  </div>

  <div
    class="border border-gray-200 rounded-md col-span-2 grid gap-y-2 gap-x-4 grid-cols-3 p-8"
  >
    <div
      class="border-b border-gray-200 col-span-2 grid grid-cols-2 gap-4 pb-6"
    >
      <MoneyField
        bind:amountInRupees={total_price_units_rupees}
        bind:amountInPaisa={liteLicensePack.total_price_units}
        name="total_price_units"
        label="Price of the pack"
        error={errors.total_price_units}
      />
      <p class="text-sm text-primary-600 mt-8">
        This will also be the credit that would be <br />available to the store
      </p>
    </div>
    <div class="col-span-2 grid grid-cols-2 gap-4 pt-12">
      <h4 class="uppercase font-bold text-xs text-primary-800 col-span-2">
        Rent per catalogue
      </h4>
      <div class="grid grid-cols-2 col-span-2 gap-8">
        <div class="relative w-64">
          <label name="">No of Catalogues</label>
          <input
            type="range"
            min="1"
            max="24"
            name="catalog_count_limit"
            bind:value={liteLicensePack.catalog_count_limit}
            on:input={inputRangeValue}
            class="styled-slider slider-progress"
          />
          <output class="block absolute -ml-2" bind:this={rangeOutput}
            >{liteLicensePack.catalog_count_limit}</output
          >
        </div>
        <MoneyField
          bind:amountInRupees={price_units_per_catalog_rupees}
          bind:amountInPaisa={liteLicensePack.price_units_per_catalog}
          error={errors.price_units_per_catalog}
          name="price_units_per_catalog"
          label="Cost / Catalogue / Day"
        />
        <p class="col-span-2 text-sm text-primary-500 -mt-8">
          If a store rents more catalogues than upper limit, rent is calculated
          per catalogue per day
        </p>
      </div>
      <div class="grid grid-cols-2 col-span-2 gap-8">
        <div>
          <label>No. of catalogues</label>
          <p class="mt-3 txt-lg">{liteLicensePack.catalog_count_limit}+</p>
        </div>
        <MoneyField
          bind:amountInRupees={price_units_after_limit_rupees}
          bind:amountInPaisa={liteLicensePack.price_units_after_limit}
          label="Cost / Day"
          name="price_units_after_limit"
          error={errors.price_units_after_limit}
        />
        <p class="col-span-2 text-sm text-primary-500 -mt-8">
          If a store rents more catalogues than upper limit, rent is calculated
          per day
        </p>
      </div>
      <div class="col-span-2 mb-12 border-t border-secondary-200 pt-12">
        <p class="text-primary-800 font-medium">Archive LL Pack</p>
        <p class="text-xs text-primary-500 mb-6">
          Store that are using this LL pack could continue using this LL pack
          until their credit runs out.
        </p>
        {#if liteLicensePack.status === "active"}
          <a
            href="#"
            class="button button-danger"
            on:click|preventDefault={() => archivePack(liteLicensePack.id)}
            >Archive</a
          >
        {:else}
          <a
            href="#"
            class="button button-danger"
            on:click|preventDefault={() => unarchivePack(liteLicensePack.id)}
            >Unarchive</a
          >
        {/if}
      </div>
    </div>
  </div>
</div>
