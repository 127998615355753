<script>
  import Reorder from "../shared/Reorder.svelte";
  import { createEventDispatcher } from "svelte";

  export let category,
    url,
    reorder,
    select,
    checkedNodes,
    path,
    selectedFolderId;
  import { jsonHeaders } from "../shared/utils.js";
  import { fade } from "svelte/transition";
  import Category from "./Category.svelte";
  import NewCategory from "./NewCategory.svelte";

  let selectedCategory = undefined;

  let categories = [],
    products = [],
    checkedSubCategoryNodes = [],
    checkedCategoryNodes = [];
  let parentFolder = [...path];
  let showNewCategoryForm = false;

  const dispatch = createEventDispatcher();
  const sendCategory = (category) =>
    dispatch("moveCategory", { category: category });
  function getContents(category) {
    selectedCategory = undefined;

    fetch(requestUrl(), {
      method: "GET",
      headers: jsonHeaders(),
    })
      .then((response) => response.json())
      .then((data) => {
        categories = data.categories;
        products = data.products;
      });
  }

  const selectCategory = (category) => {
    if (!select && !reorder) {
      selectedCategory = category;
    }
  };

  function setPath(category) {
    path = [...parentFolder, currentFolder()];
  }

  const requestUrl = () => (category ? `${url}/${category.id}` : url);
  const deselectCategory = () => (selectedCategory = undefined);
  const refresh = () => (categories = categories);
  const reorderUrl = () =>
    category ? `${url}/${category.id}/categories` : url;

  const currentFolder = () => {
    return category
      ? { id: category.id, name: category.name }
      : { id: "0", name: "Product Lines" };
  };

  const setCurrentCategory = (folderId) => {
    if (
      (category && category.id === folderId) ||
      (!category && folderId === "0")
    ) {
      selectedCategory = undefined;
      setPath(category);
    }
  };

  const showCategoryForm = () => (showNewCategoryForm = true);

  $: getContents(category);
  $: setPath(category);
  $: checkedCategoryNodes =
    categories.length > 0 ? categories.filter((c) => c.checked) : [];
  $: checkedProductNodes =
    products.length > 0 ? products.filter((c) => c.checked) : [];
  $: checkedNodes = selectedCategory
    ? checkedSubCategoryNodes
    : [...checkedCategoryNodes, ...checkedProductNodes];
  $: setCurrentCategory(selectedFolderId);
</script>

<div
  transition:fade
  class="column float-left border-r border-gray-300 h-full w-72"
>
  <ul class="relative">
    <Reorder
      bind:items={categories}
      enable={reorder}
      url={`${reorderUrl()}/reorder_subcategories`}
      let:item
      let:index
    >
      <Category
        bind:category={categories[index]}
        {selectedCategory}
        {select}
        {reorder}
        {url}
        on:message={(e) => selectCategory(item)}
        on:categoryChange={(category) => getContents()}
        on:moveCategory={(event) => sendCategory(event.detail.category)}
      />
    </Reorder>

    {#if reorder && categories.length > 0 && products.length > 0}
      <div class="block h-4 w-full bg-gray-300" />
    {/if}

    <Reorder
      bind:items={products}
      enable={reorder}
      url={`${reorderUrl()}/reorder_products`}
      let:item
      let:index
    >
      <div
        class="cursor-pointer px-6 py-3 border-b border-gray-300 {!selectedCategory ||
        selectedCategory === category
          ? 'text-gray-800'
          : 'text-gray-500'}"
      >
        {#if !selectedCategory}
          <span>
            {#if select}<input
                type="checkbox"
                bind:checked={products[index].checked}
                on:click|stopPropagation
              />{/if}
            {#if reorder}<button
                class="absolute left-1 top-3 icon icon-drag-handle cursor-move float-left focus:outline-none"
                >reorder</button
              >{/if}
          </span>
        {/if}

        {item.name}
      </div>
    </Reorder>

    {#if !selectedCategory}
      <li transition:fade class="px-6 py-4 text-center">
        <button class="button-primary" on:click={showCategoryForm}
          >+ New Category</button
        >
      </li>
    {/if}
  </ul>
</div>

{#if selectedCategory}
  <svelte:self
    category={selectedCategory}
    bind:checkedNodes={checkedSubCategoryNodes}
    bind:path
    {url}
    {reorder}
    {select}
    {selectedFolderId}
    on:moveCategory={(event) => sendCategory(event.detail.category)}
  />
{/if}

<NewCategory
  on:message={getContents}
  parentCategory={category}
  bind:showForm={showNewCategoryForm}
  {url}
/>
