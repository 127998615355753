<style>
  .container {
    margin: 4vh auto 8vh 0;
  }
</style>

<script>
  import { jsonHeaders } from "../shared/utils.js";
  import debounce from "lodash/debounce";
  import { flip } from "svelte/animate";
  import { createEventDispatcher, onMount } from "svelte";

  export let attributes = { columns: columnAttributes, rows: rowAttributes };
  export let leftName,
    leftKind,
    rightName,
    rightKind,
    propertySet,
    propertySetsUrl,
    autoSave;
  let errors = {};
  let selectPlaceholder = "Select";
  let columnAttributes, rowAttributes;
  let hovering = false;
  let propertySetsUpdateUrl = `${propertySetsUrl}/${propertySet.id}`;
  let array = [];

  const dispatch = createEventDispatcher();

  rowAttributes = [];
  const options = [
    { kind: "text" },
    { kind: "number" },
    { kind: "text_array" },
  ];

  const submitForm = () => dispatch("message", {});

  const refresh = () => {
    setAttributes();
    rowAttributes = rowAttributes;
    columnAttributes = columnAttributes;
  };

  const addAttribute = () =>
    (rowAttributes = rowAttributes.concat({
      name: "",
      nameKind: "text",
      value: "",
      valueKind: "text",
    }));

  const addValueAttribute = (i) => {
    rowAttributes[i]["value"].push("");
    refresh();
  };

  const removeAttribute = (i) => {
    rowAttributes.splice(i, 1);
    refresh();
    save();
  };

  const removeValueAttribute = (i, valueIndex) => {
    rowAttributes[i]["value"].splice(valueIndex, 1);
    refresh();
  };

  const moveAttributeUp = (i) => {
    rowAttributes.splice(i - 1, 0, rowAttributes.splice(i, 1)[0]);
    refresh();
  };

  const drop = (event, target) => {
    event.dataTransfer.dropEffect = "move";
    const start = parseInt(event.dataTransfer.getData("text/plain"));
    const newAttributeslist = rowAttributes;

    if (start < target) {
      newAttributeslist.splice(target + 1, 0, newAttributeslist[start]);
      newAttributeslist.splice(start, 1);
    } else {
      newAttributeslist.splice(target, 0, newAttributeslist[start]);
      newAttributeslist.splice(start + 1, 1);
    }
    rowAttributes = newAttributeslist;
    hovering = null;
  };

  const dragstart = (event, i) => {
    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.dropEffect = "move";
    const start = i;
    event.dataTransfer.setData("text/plain", start);
  };

  const setValueForKind = (valueKind, value, index) => {
    if (valueKind === "text_array") {
      rowAttributes[index]["value"] = [value];
    } else {
      rowAttributes[index]["value"] = value[0];
    }
    console.log(rowAttributes[index]["value"]);
  };

  const setAttributes = () => {
    let columns = [];
    let rows = [];
    columns.push({ value: leftName, position: 0, kind: "text" });
    // columnAttributes = columns
    columns.push({ value: rightName, position: 1, kind: "text" });
    // columnAttributes = columns

    for (let i = 0; i < rowAttributes.length; i++) {
      let nameRow,
        fieldRow = {};
      let row = [];
      let name = rowAttributes[i]["name"];
      let nameKind = rowAttributes[i]["nameKind"];
      let value = rowAttributes[i]["value"];
      let valueKind = rowAttributes[i]["valueKind"];
      if (nameKind == "number") {
        name = parseFloat(name);
      }
      if (valueKind == "number") {
        value = parseFloat(value);
      }
      nameRow = { value: name, position: 0, kind: nameKind };
      row.push(nameRow);
      if (valueKind == "text_array") {
        fieldRow = { value: value, position: 1, kind: valueKind };
        row.push(fieldRow);
      } else {
        fieldRow = { value: value, position: 1, kind: valueKind };
        row.push(fieldRow);
      }
      if (row.length > 0) {
        rows.push(row);
      }
    }

    attributes = {
      columns: columns,
      rows: rows,
    };
    console.log(attributes);
  };

  const save = debounce(() => {
    if (autoSave) {
      submit(propertySetsUpdateUrl, "PATCH");
    }
  }, 1000);

  const submit = (url, method) => {
    setAttributes();
    fetch(url, {
      method: method,
      headers: jsonHeaders(),
      body: JSON.stringify({
        id: propertySet.id,
        name: propertySet.name,
        description: propertySet.description,
        info: JSON.stringify(attributes),
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if ("errors" in data) {
          errors = data.errors;
        } else {
          errors = {};
        }
      });
  };

  const buildData = () => {
    attributes = propertySet.info;
    if (attributes && attributes.columns) {
      if (attributes.columns[0]) {
        leftName = attributes.columns[0]["value"];
        leftKind = "text";
      }
      if (attributes.columns[1]) {
        rightName = attributes.columns[1]["value"];
        rightKind = "text";
      }
    } else {
      leftName = "";
      leftKind = "text";
      rightName = "";
      rightKind = "text";
    }

    if (attributes && attributes.rows.length > 0) {
      for (let index = 0; index < attributes.rows.length; index++) {
        const element = attributes.rows[index];
        let rowAttribute = {
          name: element[0]["value"],
          nameKind: element[0]["kind"],
          value: element[1] && element[1]["value"] ? element[1]["value"] : "",
          valueKind: element[1]["kind"],
        };
        rowAttributes.push(rowAttribute);
      }
      rowAttributes = rowAttributes;
    }
  };
  const initRowAttributes = () => {
    if (propertySet.info == null || propertySet.info == "null") {
      rowAttributes = [
        { name: "", nameKind: "text", value: "", valueKind: "text" },
      ];
    }
  };

  onMount(() => {
    buildData();
  });
</script>

<form
  on:submit|preventDefault
  on:input={save}
  action={propertySetsUpdateUrl}
  method="patch"
  class="pb-5 mt-4 p-0 border-t border-primary-200 border-solid relative"
>
  {#if errors && Object.entries(errors).length != 0}
    <p class="text-red-800">{JSON.stringify(errors)}</p>
  {/if}
  {#if propertySet.is_template}
    <ul class="my-4 py-4">
      <h6 class="label text-sm font-medium">
        Column titles appear as table headings once imported to a product
      </h6>
      <li class="flex mb-2">
        <div class="mr-2">
          <label for="" class="label text-xs font-medium">
            Left Column Title
          </label>
          <input
            bind:value={leftName}
            type="text"
            name="leftName"
            class="block w-full"
            placeholder="Enter Field Name"
            on:blur={setAttributes}
          />
          <div class="w-1/2 mr-2">
            <select
              id="property-type"
              class="w-40 mr-4 hidden"
              bind:value={leftKind}
              on:blur={setAttributes}
              data-test-select-left-kind="select-left-kind"
            >
              {#if selectPlaceholder}
                <option value="" disabled selected>{selectPlaceholder}</option>
              {/if}
              {#each options as option}
                <option value={option.kind}>{option.kind.toUpperCase()}</option>
              {/each}
            </select>
          </div>
        </div>
        <div class=" mr-2 ml-12">
          <label for="" class="label text-xs font-medium">
            Right Column Title
          </label>
          <input
            bind:value={rightName}
            type="text"
            name="rightName"
            class="block w-full"
            placeholder="Enter Field Name"
            on:blur={setAttributes}
          />
          <div class="w-1/2 mr-2 hidden">
            <select
              id="property-type"
              class="w-40 mr-4"
              bind:value={rightKind}
              on:blur={setAttributes}
              data-test-select-right-kind="select-right-kind"
            >
              {#if selectPlaceholder}
                <option value="" disabled selected>{selectPlaceholder}</option>
              {/if}
              {#each options as option}
                <option value={option.kind}>{option.kind.toUpperCase()}</option>
              {/each}
            </select>
          </div>
        </div>
      </li>
    </ul>
  {/if}
  <h3 class="text-lg font-semibold mb-2">Properties</h3>
  <h6 class="label text-xs font-medium">
    Field names are predefined labels that appear in the left column of the
    property table. A collection could be created without predefined field names
    and fileds could be added in the product.
  </h6>
  <div class="border border-gray-300 mt-6">
    <table class="w-full">
      <thead>
        <tr>
          <th />

          <th class="px-4 py-2 font-normal text-sm text-primary-500 text-left"
            >{leftName}</th
          >
          <th class="px-4 py-2 font-normal text-sm text-primary-500 text-left"
            >{rightName}</th
          >
          <th />
        </tr>
      </thead>
      <tbody>
        {#if initRowAttributes()}find a better way to do this.{/if}
        {#each rowAttributes as { name, nameKind, value, valueKind }, i (i)}
          <tr
            class="mb-2"
            animate:flip
            draggable={true}
            on:dragstart={(event) => dragstart(event, i)}
            on:drop|preventDefault={(event) => drop(event, i)}
            ondragover="return false"
            on:dragenter={() => (hovering = i)}
            class:bg-secondary-200={hovering === i}
          >
            <td style="vertical-align:top"
              ><div class="icon icon-drag my-3 ml-4 cursor-move">move</div></td
            >
            <td style="vertical-align:top">
              <div class=" mr-2">
                <input
                  bind:value={name}
                  type="text"
                  name="field-name-{i}"
                  class="block w-full"
                  placeholder="Enter Field Name"
                  on:blur={setAttributes}
                  data-test-field-name="field-name-{i}"
                />
              </div>
              <div class=" mr-2">
                <select
                  id="property-type"
                  class="w-40 hidden"
                  name="nameKind"
                  bind:value={nameKind}
                  on:blur={setAttributes}
                  data-test-field-name-kind="field-name-kind-{i}"
                >
                  {#if selectPlaceholder}
                    <option value="" disabled selected
                      >{selectPlaceholder}</option
                    >
                  {/if}
                  {#each options as option}
                    <option value={option.kind}
                      >{option.kind.toUpperCase()}</option
                    >
                  {/each}
                </select>
              </div>
            </td>
            <td style="vertical-align:top">
              <div class="flex">
                {#if valueKind === "text_array"}
                  <div
                    class="border border-grey-200 border-dashed rounded-md pt-2 px-2 mr-2 mb-2 flex-auto"
                  >
                    {#each value as item, index}
                      <div class="flex">
                        <input
                          bind:value={item}
                          type="text"
                          name="field-value-{index}"
                          class="block w-full mb-2"
                          placeholder="Enter Field Value"
                          on:blur={setAttributes}
                          data-test-field-value="field-value-{i}"
                        />
                        <button
                          on:click|preventDefault={(event) =>
                            removeValueAttribute(i, index)}
                          class="icon icon-trash mt-2 ml-0">delete</button
                        >
                      </div>
                    {/each}
                    <button
                      on:click|preventDefault={addValueAttribute(i)}
                      class="icon icon-plus border border-primary-800 hover:bg-primary-400 px-4 py-1 my-2 rounded-sm mx-auto block"
                      data-test-add-button="add-button">add</button
                    >
                  </div>
                {:else}
                  <div class="mr-2 flex-auto">
                    <input
                      bind:value
                      type="text"
                      name="field-value-{i}"
                      class="block w-full"
                      placeholder="Enter Field Value"
                      on:blur={setAttributes}
                      data-test-field-value="field-value-{i}"
                    />
                  </div>
                {/if}
                <div class=" mr-2">
                  <select
                    id="property-type"
                    class="w-40 mr-4 "
                    name="field-value-kind-{i}"
                    bind:value={valueKind}
                    on:blur={setAttributes}
                    on:change={setValueForKind(valueKind, value, i)}
                    data-test-field-value-kind="field-value-kind-{i}"
                  >
                    {#if selectPlaceholder}
                      <option value="" disabled selected
                        >{selectPlaceholder}</option
                      >
                    {/if}
                    {#each options as option}
                      <option value={option.kind}
                        >{option.kind.toUpperCase()}</option
                      >
                    {/each}
                  </select>
                </div>
              </div>
            </td>
            <td style="vertical-align:top">
              <span class="inline-block w-1/6">
                {#if rowAttributes.length > 0}
                  <button
                    on:click|preventDefault={(event) => removeAttribute(i)}
                    class="icon icon-trash mt-2 ml-0">delete</button
                  >
                {/if}
              </span>
            </td>
          </tr>
        {/each}
      </tbody>
    </table>
    <button
      on:click|preventDefault={addAttribute}
      class="icon icon-plus bg-primary-400 px-4 py-1 my-2 rounded-sm mx-auto block"
      data-test-add-button="add-button">add</button
    >

    {#if rowAttributes.length == 0}
      <button
        on:click|preventDefault={addAttribute}
        class="icon icon-plus mt-2 ml-0 -mr-8"
        data-test-add-button="add-button">add</button
      >
    {/if}
  </div>
</form>
