<script>
  import ProductTabs from "./ProductTabs.svelte";
  import Field from "../forms/Field.svelte";
  import EditVariantsMarketing from "./EditVariantsMarketing.svelte";

  export let product, url, productKinds;

  let errors = {},
    properties = productKinds.find((kind) => kind.name === product.kind),
    productUrl = `${url}/${product.id}`;
</script>

<div class="p-8 grid grid-cols-3 gap-0">
  <h2 class="h2 mt-4 mb-8 w-full overflow-hidden">
    <a href="/admin/products" class="float-left w-8 pt-1">←</a>
    <input
      placeholder="Enter Product Name"
      bind:value={product.name}
      type="text"
      id="name"
      class="float-left w-10/12 bg-transparent border-none"
      disabled
    />
  </h2>

  <ProductTabs
    url={productUrl}
    selectedTab={"marketing"}
    hasComponents={properties.has_components}
  />

  <div
    class="col-span-3 grid grid-cols-3 gap-y-8 gap-x-16 bg-white text-primary-800 border border-primary-300 p-8"
  >
    <div class="col-span-2">
      <Field
        bind:field={product.google_product_category}
        label="Google Product Category"
        error={errors.google_product_category}
      />
      <Field
        bind:field={product.facebok_product_category}
        label="Facebook Product Category"
        error={errors.facebok_product_category}
      />
    </div>

    <div class="col-span-3">
      <EditVariantsMarketing
        variants={product.variants}
        {product}
        {properties}
        {productUrl}
      />
    </div>
  </div>
</div>
