<script>
  export let category, url;
  import CatalogFolder from "./CatalogFolder.svelte";

  let reorder = false,
    select = false,
    nodes = [],
    path = [];
  let selectedFolderId = "0";

  function moveTo() {
    console.log("Move Nodes", nodes);
  }

  const enableSelect = () => (select = true);
  const enableReorder = () => (reorder = true);
  const cancel = () => (select = reorder = false);
  const selectFolder = (folderId) => (selectedFolderId = folderId);
</script>

<div class="h-screen page-grid gap-4">
  <header class="px-8 pt-8">
    <h1 class="font-bold pb-6">Product Lines</h1>
    <div class="toolbar hidden">
      {#if !select && !reorder}
        <button class="button" on:click={enableSelect}>Select</button>
        <button class="button" on:click={enableReorder}>Reorder</button>
      {/if}

      {#if select}
        <button class="button" on:click={moveTo}>Move To</button>
        <button class="button" on:click={moveTo}>Make a Category...</button>
        <button class="button" on:click={moveTo}>Mark as Sellable</button>
        <button class="button" on:click={moveTo}>Mark as Materials</button>
        <button class="button" on:click={moveTo}>Don't Stock</button>
      {/if}

      {#if reorder}
        <button class="button" on:click={moveTo}>Save</button>
      {/if}

      {#if select || reorder}
        <button class="button-subtle" on:click={cancel}>Cancel</button>
      {/if}
    </div>
  </header>

  <div class="border border-primary-300 bg-white page-grid gap-0 ml-8">
    <ul class="breadcrumbs border-b border-primary-300 px-4 py-2 text-sm">
      {#each path as folder, i}
        <li
          class="inline-block cursor-pointer"
          on:click={(e) => selectFolder(folder.id)}
        >
          {folder.name}
        </li>
        {#if i + 1 < path.length}
          <li class="inline-block mx-4">↣</li>
        {/if}
      {/each}
    </ul>
    <div class="folders">
      <CatalogFolder
        {category}
        {url}
        {reorder}
        {select}
        bind:checkedNodes={nodes}
        bind:path
        {selectedFolderId}
      />
    </div>
  </div>
</div>
