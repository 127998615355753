<style>
  .container {
    max-width: 36rem;
    margin: 4vh auto 8vh 0;
  }
</style>

<script>
  import { jsonHeaders } from "../shared/utils.js";
  import debounce from "lodash/debounce";
  import { flip } from "svelte/animate";
  import { createEventDispatcher } from "svelte";

  export let propertySet, attributes, propertySetsUrl, autoSave;
  let listAttributes = attributes || [];
  let errors = {};
  let hovering = false;
  let selectPlaceholder = "Select";
  let propertySetsUpdateUrl = "";
  let canAutoSave = autoSave;

  if (propertySet) {
    propertySetsUpdateUrl = `${propertySetsUrl}/${propertySet.id}`;
  }

  const dispatch = createEventDispatcher();
  const submitForm = () => dispatch("message", {});

  const options = [{ kind: "text" }, { kind: "number" }];

  const refresh = () => (listAttributes = listAttributes);
  const addAttribute = () => {
    listAttributes = listAttributes.concat({
      value: "",
      kind: "text",
      position: 0,
    });
    save();
  };

  const removeAttribute = (i) => {
    listAttributes.splice(i, 1);
    refresh();
    save();
  };

  const moveAttributeUp = (i) => {
    listAttributes.splice(i - 1, 0, listAttributes.splice(i, 1)[0]);
    refresh();
  };

  const drop = (event, target) => {
    event.dataTransfer.dropEffect = "move";
    const start = parseInt(event.dataTransfer.getData("text/plain"));
    const newAttributeslist = listAttributes;

    if (start < target) {
      newAttributeslist.splice(target + 1, 0, newAttributeslist[start]);
      newAttributeslist.splice(start, 1);
    } else {
      newAttributeslist.splice(target, 0, newAttributeslist[start]);
      newAttributeslist.splice(start + 1, 1);
    }
    listAttributes = newAttributeslist;
    hovering = null;
    save();
  };

  const dragstart = (event, i) => {
    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.dropEffect = "move";
    const start = i;
    event.dataTransfer.setData("text/plain", start);
  };

  const formatListInfo = () => {
    canAutoSave = autoSave;
    let i = 0;
    let tempInfo = listAttributes;
    for (; i < listAttributes.length; i++) {
      tempInfo[i]["position"] = i;
      if (tempInfo[i]["kind"] == "number") {
        tempInfo[i]["value"] = parseFloat(tempInfo[i]["value"]);
      }
      if (!autoSave && tempInfo[i]["value"] == "") {
        tempInfo.splice(i, 1);
      }
      // if (autoSave) {
      //   if (
      //     tempInfo[i] &&
      //     (tempInfo[i]["value"] == "" || tempInfo[i]["kind"] == "")
      //   ) {
      //     canAutoSave = canAutoSave && false;
      //   } else if (
      //     tempInfo[i] &&
      //     tempInfo[i]["value"] != "" &&
      //     tempInfo[i]["kind"] != ""
      //   ) {
      //     canAutoSave = canAutoSave && true;
      //   }
      // }
    }

    return tempInfo;
  };

  const save = debounce(() => {
    if (autoSave && canAutoSave) {
      submit(propertySetsUpdateUrl, "PATCH");
    }
  }, 1000);

  const setAttributes = () => {
    attributes = formatListInfo();
  };

  const submit = (url, method) => {
    attributes = formatListInfo();
    fetch(url, {
      method: method,
      headers: jsonHeaders(),
      body: JSON.stringify({
        id: propertySet.id,
        name: propertySet.name,
        description: propertySet.description,
        info: JSON.stringify(attributes),
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if ("errors" in data) {
          errors = data.errors;
        } else {
          errors = {};
        }
      });
  };
</script>

<div class="container rounded-lg p-10 text-left border border-primary-300">
  <h3 class="text-lg font-semibold mb-2">Properties</h3>
  <form
    on:submit|preventDefault
    on:input={save}
    action={propertySetsUpdateUrl}
    method="patch"
    class="pb-5 p-0 border-t border-primary-200 border-solid relative"
  >
    {#if errors && Object.entries(errors).length != 0}
      {JSON.stringify(errors)}
    {/if}
    <ul class="my-4 py-4">
      {#each listAttributes as { value, kind }, i (i)}
        <li
          class="flex mb-2"
          animate:flip
          draggable={true}
          on:dragstart={(event) => dragstart(event, i)}
          on:drop|preventDefault={(event) => drop(event, i)}
          ondragover="return false"
          on:dragenter={() => (hovering = i)}
          class:bg-secondary-200={hovering === i}
        >
          <span class="icon icon-drag mt-10 mr-2 -ml-6 cursor-move">move</span>

          <div class="w-64 mr-2">
            <label for="value" class="label text-xs font-medium">
              Property Value
            </label>
            <input
              bind:value
              data-test-list-item-value={value}
              on:blur={setAttributes}
              type="text"
              name="value"
              class="block w-full"
            />
            <label for="property-type" class="label text-xs font-medium hidden">
              Property Type
            </label>
            <select
              id="property-type"
              class="w-64 mr-4 hidden"
              on:blur={setAttributes}
              bind:value={kind}
              data-test-select-prop-type="select-prop-type"
            >
              {#if selectPlaceholder}
                <option value="" disabled selected>{selectPlaceholder}</option>
              {/if}
              {#each options as option}
                <option value={option.kind}>{option.kind.toUpperCase()}</option>
              {/each}
            </select>
          </div>

          <span class="inline-block w-1/6">
            {#if listAttributes.length > 0}
              <button
                on:click|preventDefault={(event) => removeAttribute(i)}
                class="icon icon-trash mt-9 ml-3">delete</button
              >
            {/if}
          </span>
        </li>
      {/each}
      <button
        on:click|preventDefault={addAttribute}
        class="icon icon-plus mt-9 ml-3 -mr-8"
        data-test-add-button="add-button">add</button
      >
    </ul>
  </form>
</div>
