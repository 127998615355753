<script>
  import { jsonHeaders, securityToken } from "../shared/utils.js";
  import { fade } from "svelte/transition";
  import Field from "../forms/Field.svelte";

  export let url, campaign;

  let showMenu = false,
    showForm = false,
    kind = "";
</script>

<div class="float-right relative">
  <a
    href="#"
    class="button-primary"
    on:click|preventDefault={(e) => {
      showMenu = !showMenu;
    }}>New Campaign</a
  >

  {#if showMenu}
    <div
      on:click|preventDefault={(e) => {
        showMenu = false;
      }}
      class="fixed opacity-0 bg-black top-0 right-0 bottom-0 left-0"
    >
      &nbsp;
    </div>

    <ul
      class="absolute right-0 w-48 py-2 bg-primary-100 border border-primary-200 shadow-2xl rounded-md"
    >
      <li>
        <a
          on:click|preventDefault={(e) => {
            campaign.kind = "group";
            showForm = true;
          }}
          href="{url}?kind=group"
          class="block w-full text-primary-800 text-left text-sm px-6 py-1 font-medium hover:bg-primary-200"
          >Campaign</a
        >
      </li>
      <li>
        <a
          on:click|preventDefault={(e) => {
            campaign.kind = "lightning";
            showForm = true;
          }}
          href="{url}?kind=lightning"
          class="block w-full text-primary-800 text-left text-sm px-6 py-1 font-medium hover:bg-primary-200"
          >Lightning Campaign</a
        >
      </li>
      <li>
        <a
          on:click|preventDefault={(e) => {
            campaign.kind = "single-use";
            showForm = true;
          }}
          href="{url}?kind=single-use"
          class="block w-full text-primary-800 text-left text-sm px-6 py-1 font-medium hover:bg-primary-200"
          >Single Use Coupons</a
        >
      </li>
    </ul>
  {/if}

  {#if showForm}
    <div
      transition:fade
      class="flex items-center justify-center bg-black bg-opacity-75 fixed z-10 top-0 right-0 bottom-0 left-0"
    >
      <form
        action={url}
        method="post"
        class="container w-128 p-8 bg-white rounded-lg text-left shadow-xl transform transition-all border border-primary-200"
      >
        <a
          href="#"
          on:click={(e) => {
            showForm = false;
          }}
          class="absolute top-0 right-0 -mt-2 -mr-2 p-1 text-center h-8 w-8 bg-white rounded-full shadow-md"
          >×</a
        >

        <h2 class="font-medium capitalize text-primary-800">
          New {campaign.kind} Campaign
        </h2>
        <input
          type="hidden"
          name="coupon_campaign[kind]"
          value={campaign.kind}
        />
        <input
          type="hidden"
          name="authenticity_token"
          value={securityToken()}
        />
        <p>
          <label for="name">Name</label>
          <Field
            bind:field={campaign.name}
            name="coupon_campaign[name]"
            required="true"
          />
        </p>

        <p>
          <label for="name">Prefix</label>
          <Field
            bind:field={campaign.prefix}
            name="coupon_campaign[prefix]"
            required="true"
          />
        </p>

        <button class="button-primary mt-2">Create Campaign</button>
      </form>
    </div>
  {/if}
</div>
