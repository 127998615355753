<script>
  import { onMount } from "svelte";
  import { fade } from "svelte/transition";
  import { jsonHeaders } from "../../shared/utils.js";
  export let addonsUrl,
    existingAddons,
    allAddons = {};
  let shouldShowForm = false,
    updateInProgress = false,
    formObject = {};

  const toggleForm = () => {
    shouldShowForm = !shouldShowForm;
    updateInProgress = false;
    allAddons.forEach((item) => {
      formObject[item.key] = (
        existingAddons.find((elem) => elem.key === item.key) || { value: 0 }
      ).value;
    });
    console.log(formObject);
  };

  const saveAddons = () => {
    if (updateInProgress) {
      return;
    }
    updateInProgress = true;
    fetch(addonsUrl, {
      method: "POST",
      headers: jsonHeaders(),
      body: JSON.stringify(formObject),
    })
      .then((response) =>
        response.json().then((data) => ({
          code: response.status,
          responseJson: data,
        }))
      )
      .then((data) => {
        console.log(data.responseJson);
        if (data.code === 200) {
          location.reload();
        } else {
          errors = data.responseJson.errors;
          console.error(errors);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        updateInProgress = false;
      });
  };
  onMount(() => {
    document.querySelector("#edit-addons").onclick = (event) => {
      event.preventDefault();
      toggleForm();
    };
    return () => {
      document.querySelector("#edit-addons").onclick = null;
    };
  });
</script>

{#if shouldShowForm}
  <div
    transition:fade
    class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 bg-black bg-opacity-75 fixed z-10 top-0 right-0 bottom-0 left-0 transition-all duration-150"
  >
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" />
    <div
      class="inline-block align-bottom bg-white rounded-lg p-8 text-left shadow-xl transform transition-all my-8 align-middle max-w-lg sm:align-middle w-160"
    >
      <button
        on:click={toggleForm}
        class="absolute top-0 right-0 -mt-2 -mr-2 p-1 text-center h-8 w-8 bg-white rounded-full shadow-sm"
        >×</button
      >
      <h3 class="text-lg text-primary-900 font-semibold mb-2">Edit Addons</h3>

      <form on:submit|preventDefault={saveAddons} action=".">
        {#each allAddons as item (item.key)}
          <div>
            <label class="label block" for="name">{item.name}</label>
            <input
              type="number"
              id={item.key}
              name={item.key}
              class="w-full"
              required
              bind:value={formObject[item.key]}
            />
          </div>
        {/each}
        {#if !updateInProgress}
          <button class="button-primary mt-6" type="submit">Update</button>
        {/if}
      </form>
    </div>
  </div>
{/if}
