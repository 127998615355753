<script>
  import EditStoreTaxCodes from "./EditStoreTaxCodes.svelte";
  import Field from "./../forms/Field.svelte";
  import ToggleButton from "./../forms/ToggleButton.svelte";
  import { jsonHeaders } from "../shared/utils.js";
  export let gstNumber,
    panNumber,
    sacHsnCode,
    cinNumber,
    taxCodes,
    defaultTaxCodeName,
    url;

  let showForm = false,
    is_gst_recipient_same_as_admin;

  const saveAccountDetails = () => {
    console.log(url);
    fetch(`${url}/update_account_tax_details`, {
      method: "POST",
      headers: jsonHeaders(),
      body: JSON.stringify({
        account: {
          gst_number: gstNumber,
          pan_number: panNumber,
          sac_hsn_code: sacHsnCode,
          cin_number: cinNumber,
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.errors) {
          console.log(errors);
        } else {
          location.href = url;
        }
      });
  };
</script>

<div class="">
  <div class="grid grid-cols-3 gap-8">
    <Field
      bind:field={gstNumber}
      label="GST Number"
      inputClass="input-sm"
      name="gst-number"
    />
    <Field
      bind:field={panNumber}
      label="PAN Number"
      inputClass="input-sm"
      name="pan-number"
    />
    <Field
      bind:field={sacHsnCode}
      label="SAC HSN Code"
      inputClass="input-sm"
      name="sac-hsn-code"
    />
    <Field
      bind:field={cinNumber}
      label="CIN Number"
      inputClass="input-sm"
      name="cin-number"
    />
  </div>
  <div class="mt-6 hidden">
    <h4 class="text-sm mt-2 mb-1 text-primary-600 block">Tax Recipient</h4>
    <div class="flex items-start">
      <p class="text-primary-800 text-sm">Same as administrator</p>
      <ToggleButton
        bind:checked={is_gst_recipient_same_as_admin}
        containerCss="float-right -mt-1"
      />
    </div>
  </div>
  <a
    href="#"
    on:click|preventDefault={saveAccountDetails}
    class="button-primary mt-4">Save</a
  >
  <div class="mt-8 border-t border-gray-200 pt-8">
    <div class="flex items-center mb-6">
      <h3
        class="flex-auto font-medium  w-full text-primary-800 overflow-hidden"
      >
        Tax Rules
      </h3>
      <a
        href="#"
        on:click|preventDefault={() => {
          showForm = true;
        }}
        class="button button-subtle py-2 flex items-center w-32"
        ><span class="icon icon-edit mr-1" />Edit Rules</a
      >
    </div>

    <table class="w-full" data-test-table="tax-codes-IN">
      <thead>
        <tr
          class="border-b border-gray-300 text-xs text-primary-500 uppercase tracking-wider"
        >
          <th class="font-medium py-3 text-left">Rule</th>
          <th class="font-medium px-8 py-3 text-left">IGST</th>
          <th class="font-medium px-8 py-3 text-left">SGST</th>
          <th class="font-medium px-8 py-3 text-left">CGST</th>
          <th class="font-medium px-8 py-3 text-left">Cess</th>
        </tr>
      </thead>
      <tbody class="text-primary-800 text-xs">
        {#each taxCodes as taxCode, i}
          {#if taxCode.status === "active"}
            <tr
              class="border-b border-gray-300"
              data-test-row-name={taxCode.name}
            >
              <td class=" py-3" data-test-rule-name={taxCode.name}>
                {taxCode.name}
                {#if taxCode.name === defaultTaxCodeName}
                  <span
                    class="bg-primary-400 text-secondary-700 text-[10px] font-medium px-2 py-1 rounded-full ml-2}"
                    >Default</span
                  >
                {/if}
              </td>
              <td class="px-8 py-3" data-test-igst={taxCode.name}>
                {taxCode.tax_rates.igst}%
              </td>
              <td class="px-8 py-3" data-test-sgst={taxCode.name}>
                {taxCode.tax_rates.sgst}%
              </td>
              <td class="px-8 py-3" data-test-cgst={taxCode.name}>
                {taxCode.tax_rates.cgst}%
              </td>
              <td class="px-8 py-3" data-test-cess={taxCode.name}>
                {taxCode.tax_rates.cess}%
              </td>
            </tr>
          {/if}
        {/each}
      </tbody>
    </table>
  </div>
</div>

{#if showForm}
  <EditStoreTaxCodes
    taxCodes={taxCodes.map((item) => ({ ...item }))}
    {defaultTaxCodeName}
    hideForm={() => {
      showForm = false;
    }}
    {url}
  />
{/if}
