<script>
  import { onMount } from "svelte";
  import debounce from "lodash/debounce";
  import { jsonHeaders } from "../../shared/utils.js";
  import { fade } from "svelte/transition";
  import Field from "../../forms/Field.svelte";
  import RadioButtons from "../../forms/RadioButtons.svelte";
  import RegionList from "./RegionList.svelte";

  export let editUrl = "";
  export let catalog;
  export let country;
  export let regionsUrl;

  let errors = {};
  let fileName = "";

  let selectedRegion;
  let priceValuesField = "fixed";

  let priceValues = [
    { value: "fixed", label: "Fixed", subtitle: "Stores cannot change prices" },
    {
      value: "adjustable",
      label: "Adjustable",
      subtitle: "Stores can change prices",
    },
  ];

  let displayOptions = [
    { value: "whole_country", label: "Whole Country", subtitle: "" },
    { value: "select_regions", label: "Selected Regions", subtitle: "" },
  ];

  const readFile = (input) => {
    if (input.target.files && input.target.files[0]) {
      fileName = input.target.files[0].name;
    }
  };

  const save = debounce(() => {
    console.log(catalog);
    console.log("save catalog", catalog.id);
    submit(`${editUrl}`, "PATCH");
  }, 1000);

  const submit = (url, method) => {
    catalog.is_pricing_adjustable = priceValuesField === "adjustable";
    fetch(url, {
      method: method,
      headers: jsonHeaders(),
      body: JSON.stringify({ ...catalog }),
    })
      .then((response) => response.json())
      .then((data) => {
        if ("errors" in data) {
          errors = data.errors;
          catalog = data.catalog;
          console.log(errors);
        } else {
          errors = {};
          catalog = data.catalog;
          console.log("success");
        }
      });
  };

  onMount(() => {
    priceValuesField = catalog.is_pricing_adjustable ? "adjustable" : "fixed";
    applySelectedRegion();
  });

  const applySelectedRegion = () => {
    if (country) {
      if (country.all_selected) {
        selectedRegion = "whole_country";
      } else {
        selectedRegion = "select_regions";
      }
    }
  };

  $: {
    let country_all_selected = null;
    if (selectedRegion === "whole_country" && country.all_selected === false) {
      country_all_selected = true;
    } else if (
      selectedRegion === "select_regions" &&
      country.all_selected === true
    ) {
      country_all_selected = false;
    }
    if (country_all_selected !== null) {
      let editCountryUrl = `${regionsUrl}/${country.id}`;

      fetch(editCountryUrl, {
        method: "PATCH",
        headers: jsonHeaders(),
        body: JSON.stringify({
          region: {
            all_selected: country_all_selected,
          },
        }),
      })
        .then((response) =>
          response.json().then((data) => ({
            status: response.status,
            data: data,
          }))
        )
        .then((resp) => {
          if (resp.status == 200) {
            country.all_selected = country_all_selected;
          } else {
            console.log(resp);
          }
        })
        .finally(() => {
          applySelectedRegion();
        });
    }
  }
</script>

<div
  class="  product-container gap-y-8 gap-x-16 bg-white text-primary-800 border border-primary-300 p-8"
>
  <form on:change|preventDefault={save} action="." class="col-span-2">
    <h2 class="flex items-center mt-4 mb-8 overflow-hidden col-span-2">
      <a href="/vizstore/admin/brand_catalogs" class="text-2xl w-8">←</a>
      <input
        placeholder="Enter Catalog Name"
        bind:value={catalog.name}
        type="text"
        class="text-2xl font-bold w-10/12 bg-transparent border-none ring-0 shadow-none p-0"
      />
    </h2>
    <div>
      {#if errors.name}<span class="field-error">{errors.name[0]}</span>{/if}
    </div>
  </form>
  <div class="p-8 border border-gray-300 grid grid-cols-2">
    <div transition:fade class="col-span-1 ">
      <form on:change|preventDefault={save} action=".">
        <p class="label">Country</p>
        <select
          required
          bind:value={catalog.country}
          class="select"
          name="country"
          id="country"
        >
          <option value="in">India</option>
          <option value="us">US</option>
        </select>
        {#if errors.country}
          <span class="field-error">{errors.country[0]}</span>
        {/if}
        <Field
          bind:field={catalog.sub_brand_name}
          label="Catalog Brand Name"
          error={errors.sub_brand_name}
        />
        <Field
          bind:field={catalog.suggested_root_category_name}
          label="Root Category"
          error={errors.suggested_root_category_name}
        />
        <p class="label">Product Type</p>
        <select
          required
          bind:value={catalog.product_type}
          class="select"
          name="product_type"
          id="product_type"
        >
          <option value="Vitrified Tiles">Vitrified Tiles</option>
          <option value="Paints">Paints</option>
          <option value="Carpets">Carpets</option>
          <option value="Fabrics">Fabrics</option>
        </select>
        {#if errors.product_type}
          <span class="field-error">{errors.product_type[0]}</span>
        {/if}
        <p class="label">Price</p>
        <div class="grid grid-cols-2 gap-8">
          <RadioButtons options={priceValues} bind:field={priceValuesField} />
        </div>
      </form>
      <div class="row-span-3 pt-2 col-start-3 border-t border-gray-300 mt-8">
        <p class="label">Upload</p>
        <div
          class="image-upload-wrap bg-primary-100 border border-gray-300 shadow-sm rounded-md relative h-36 flex flex-wrap justify-center mt-4"
        >
          <span class=" icon icon-file mt-6 h-10 w-full" />
          <input
            class="file-upload-input "
            type="file"
            id="file"
            name="file"
            on:change={readFile}
            required
          />
          <div class="text-center text-primary-800">
            {#if fileName}
              {fileName}
            {:else}
              <p>Drag and drop a csv file</p>
              <p>or <span class="font-bold underline">Browse</span></p>
            {/if}
          </div>
        </div>
        {#if errors.file}
          <span class="field-error">{errors.file[0]}</span>
        {/if}
      </div>
    </div>

    <div class="col-span-2 grid grid-cols-2 mt-12">
      <h3 class="text-primary-800 font-bold col-span-2">Regions</h3>
      <div class="col-span-1 grid grid-cols-2 gap-8">
        <RadioButtons options={displayOptions} bind:field={selectedRegion} />
      </div>
      <div class="col-span-2 mt-4">
        {#if country.all_selected == false}
          <RegionList {country} {catalog} {regionsUrl} />
        {/if}
      </div>
    </div>
  </div>
</div>

<style>
  .file-upload-input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
  }
</style>
