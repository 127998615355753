<style>
  .product-container {
    grid-template-columns: 1fr 1fr 16rem;
  }
</style>

<script>
  import debounce from "lodash/debounce";
  import isEqual from "lodash/isEqual";
  import clone from "lodash/clone";
  import { fade } from "svelte/transition";
  import { jsonHeaders } from "./../shared/utils.js";
  import ProductCategory from "./ProductCategory.svelte";
  import EditVariants from "./EditVariants.svelte";
  import ProductTabs from "./ProductTabs.svelte";
  import Field from "./../forms/Field.svelte";
  import TextArea from "./../forms/TextArea.svelte";
  import { onMount } from "svelte";

  export let product,
    productKinds,
    url,
    categoriesUrl,
    taxNumbers,
    storeTaxCodes;

  let errors = {},
    properties = productKinds.find((kind) => kind.name === product.kind),
    productUrl = `${url}/${product.id}`,
    variantsUrl = `${url}/${product.id}/variants`,
    prevProduct = {};

  const setJsonFields = () => {
    product.content = Object.assign({ summary: "" }, product.content);
    product.content = Object.assign({ description: "" }, product.content);
  };

  const save = debounce((product) => {
    if (!isEqual(prevProduct, product)) {
      submit(`${productUrl}`, "PATCH");
    }
  }, 1000);

  const publish = () => {
    console.log("publish product");
    submit(`${productUrl}/publish`, "POST");
  };

  const destroy = () => {
    fetch(productUrl, {
      method: "DELETE",
      headers: jsonHeaders(),
    }).then(() => {
      location.href = "/admin/products";
    });
  };

  const archive = () => {
    console.log("archive product");
    submit(`${productUrl}/archive`, "POST");
  };

  const submit = (url, method) => {
    fetch(url, {
      method: method,
      headers: jsonHeaders(),
      body: JSON.stringify({ product: product }),
    })
      .then((response) => response.json())
      .then((data) => {
        if ("errors" in data) {
          errors = data.errors;
          product = data.product;
          console.log(errors);
        } else {
          errors = {};
          product = data.product;
          prevProduct = clone(product);
          console.log("success");
        }
      });
  };

  $: setJsonFields();

  onMount(() => {
    prevProduct = clone(product);
  });
</script>

<div transition:fade class="p-8 gap-0 grid product-container">
  <h2 class="h2 mt-4 mb-8 overflow-hidden col-span-2">
    <a
      href="#"
      on:click|preventDefault={() => history.back()}
      class="float-left w-8 pt-2">←</a
    >
    <input
      placeholder="Enter Product Name"
      bind:value={product.name}
      on:input={save}
      type="text"
      class="w-10/12 bg-transparent border-none ring-0 shadow-none"
    />
  </h2>

  <div class="buttons mt-4 mb-8 text-right">
    {#if product.status === "draft"}
      <div class="flex">
        <button on:click={destroy} class="button-primary mr-8">Delete</button>
        <button on:click={publish} class="button-primary">Publish</button>
      </div>
    {:else if product.status === "published"}
      <button on:click={archive} class="button-primary">Archive</button>
    {:else if product.status === "archived"}
      <button on:click={publish} class="button-primary">Publish</button>
    {/if}
  </div>

  <ProductTabs
    url={productUrl}
    selectedTab={"details"}
    hasComponents={properties.has_components}
  />

  <div
    class="col-span-3 grid product-container gap-y-8 gap-x-16 bg-white text-primary-800 border border-primary-300 p-8"
  >
    <form action={productUrl} class="col-span-2" on:input={save}>
      <Field bind:field={product.slug} label="URL" error={errors.slug} />
      <TextArea
        bind:field={product.content.summary}
        label="summary"
        error={errors.summary}
      />
      <TextArea
        bind:field={product.content.description}
        label="Description"
        error={errors.description}
      />
    </form>

    <div>
      <h4 class="text-xs uppercase tracking-wide col-span-2 font-medium">
        ORGANIZATION
      </h4>

      <ProductCategory
        bind:product
        {categoriesUrl}
        {productUrl}
        on:save={save}
      />
    </div>

    <div class="col-span-3">
      <EditVariants
        variants={product.variants}
        {product}
        {properties}
        {taxNumbers}
        {storeTaxCodes}
        formUrl={variantsUrl}
      />
    </div>
  </div>
</div>
