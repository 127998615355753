<script>
  import NewTaxCode from "./NewTaxCode.svelte";
  import { jsonHeaders } from "../shared/utils.js";
  export let taxCodes, taxNames, url;

  let showForm = false;

  const deleteTaxCode = (taxCodeId) => {
    let confirmed = confirm("Are you sure you want to delete this Tax Code?");

    if (confirmed) {
      let taxCodeUrl = `${url}/${taxCodeId}/`;
      fetch(taxCodeUrl, {
        method: "DELETE",
        headers: jsonHeaders(),
      }).then((response) => {
        if (response.status === 200) {
          window.location.href = url;
        }
      });
    }
  };
</script>

<div class="p-8">
  <div class="flex items-center mb-8">
    <h2 class="h2 flex-auto  overflow-hidden text-primary-800 text-3xl">
      Tax Rules
    </h2>
    <button
      class="button button-primary mt-4"
      id="add-taxcode"
      on:click|preventDefault={() => (showForm = true)}>+ Add TaxCode</button
    >
  </div>
  <div class="border border-primary-300 p-8">
    <h3 class="h2 mb-8 w-full text-xl overflow-hidden">India</h3>
    <table class="w-full" data-test-table="tax-codes-IN">
      <thead>
        <tr
          class="border-b border-gray-300 text-xs text-primary-500 uppercase tracking-wider"
        >
          <th class="font-medium py-3 text-left">Rule</th>
          <th class="font-medium px-8 py-3 text-left">IGST</th>
          <th class="font-medium px-8 py-3 text-left">SGST</th>
          <th class="font-medium px-8 py-3 text-left">CGST</th>
          <th class="font-medium px-8 py-3 text-left">Cess</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody class="text-primary-800 text-xs">
        {#each taxCodes as taxCode}
          <tr
            class="border-b border-gray-300"
            data-test-row-name={taxCode.name}
          >
            <td class=" py-3" data-test-rule-name={taxCode.name}>
              {taxCode.name}
            </td>
            <td class="px-8 py-3" data-test-igst={taxCode.name}>
              {taxCode.tax_rates.igst}%
            </td>
            <td class="px-8 py-3" data-test-sgst={taxCode.name}>
              {taxCode.tax_rates.sgst}%
            </td>
            <td class="px-8 py-3" data-test-cgst={taxCode.name}>
              {taxCode.tax_rates.cgst}%
            </td>
            <td class="px-8 py-3" data-test-cess={taxCode.name}>
              {taxCode.tax_rates.cess}%
            </td>
            <td class="px-8 py-3 text-right">
              <a
                data-test-delete-link={taxCode.name}
                href="#"
                on:click|preventDefault={deleteTaxCode(taxCode.id)}
                class="p-4"><span class="icon icon-trash" /></a
              >
            </td>
          </tr>
        {/each}
      </tbody>
    </table>
  </div>
</div>
<NewTaxCode bind:showForm {taxNames} {url} />
