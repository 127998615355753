<script>
  import { jsonHeaders } from "../shared/utils.js";
  import { fade } from "svelte/transition";
  import EditComponent from "./EditComponent.svelte";
  import ProductTabs from "./ProductTabs.svelte";

  export let product,
    components,
    formUrl,
    productUrl,
    categoryList,
    categoriesUrl,
    showAddComponent,
    showDeleteComponent;

  const url = (component) => `${formUrl}/${component.id}`;

  const add = () => {
    fetch(formUrl, {
      method: "POST",
      headers: jsonHeaders(),
    })
      .then((response) => response.json())
      .then((data) => {
        components = components.concat(data);
      });
  };

  const remove = (component) => {
    let index = components.indexOf(component);

    components = [
      ...components.slice(0, index),
      ...components.slice(index + 1, components.length),
    ];
  };
</script>

<div transition:fade class="p-8 grid grid-cols-3 gap-0">
  <h2 class="h2 mt-4 mb-8 w-full overflow-hidden">
    <a href="/admin/products" class="float-left w-8 pt-1">←</a>
    <input
      placeholder="Enter Product Name"
      bind:value={product.name}
      type="text"
      id="name"
      class="float-left w-10/12 bg-transparent border-none"
    />
  </h2>

  <ProductTabs
    url={productUrl}
    selectedTab={"components"}
    hasComponents={true}
  />

  <div
    class="col-span-3 bg-white text-primary-800 border border-primary-300 p-8"
  >
    {#each components as component (component.id)}
      <EditComponent
        {component}
        {categoryList}
        {categoriesUrl}
        {formUrl}
        {showDeleteComponent}
        on:remove={(e) => remove(component)}
      />
    {/each}

    <div class="col-span-3 mt-16">
      <button
        class="button-primary {showAddComponent ? '' : 'hidden'}"
        on:click={add}>+ Add Component</button
      >
    </div>
  </div>
</div>
