<script>
  import debounce from "lodash/debounce";
  import isEqual from "lodash/isEqual";
  import clone from "lodash/clone";
  import { jsonHeaders } from "../shared/utils.js";
  import { fade } from "svelte/transition";

  import Field from "./../forms/Field.svelte";
  import MoneyField from "./../forms/MoneyField.svelte";
  import RadioButtonsPill from "./../forms/RadioButtonsPill.svelte";

  export let coupon, url, campaign;
  import { createEventDispatcher, onMount } from "svelte";
  const dispatch = createEventDispatcher();

  let discountTypes = [
    { value: "flat", label: "₹" },
    { value: "percentage", label: "%" },
  ];

  let discountAmount = coupon.discount_value / 100 || 0,
    minCartValue = coupon.min_cart_value / 100 || 0,
    showDiscountAmountError = false,
    prevCoupon = {};

  onMount(() => {
    prevCoupon = clone(coupon);
    if (coupon.discount_type === "flat") {
      discountAmount = coupon.discount_value / 100 || 0;
    }
    minCartValue = coupon.min_cart_value / 100;
  });

  const save = debounce(() => {
    showDiscountAmountError = false;
    if (coupon.discount_type === "flat") {
      if (!isEqual(prevCoupon, coupon)) {
        console.log("saving coupon...");
        submit();
      }
    }
    if (
      coupon.discount_type === "percentage" &&
      coupon.discount_value <= 100 &&
      coupon.discount_value >= 0
    ) {
      if (!isEqual(prevCoupon, coupon)) {
        console.log("saving coupon...");
        submit();
      }
    } else {
      showDiscountAmountError = true;
    }
  }, 1000);

  const submit = () => {
    fetch(url, {
      method: "PATCH",
      headers: jsonHeaders(),
      body: JSON.stringify({ coupon: coupon }),
    })
      .then((response) => response.json())
      .then((data) => {
        if ("errors" in data) {
        } else {
          prevCoupon = clone(coupon);
        }
      });
  };

  const destroy = () => {
    let confirmed = confirm("Are you sure you want to delete this coupon?");

    if (confirmed) {
      fetch(url, {
        method: "DELETE",
        headers: jsonHeaders(),
      }).then((response) => {
        if (response.status === 200) {
          dispatch("delete");
        }
      });
    }
  };

  $: save(coupon, discountAmount);
</script>

<tr transition:fade class="border-b border-gray-300">
  {#if coupon.variant}
    <td class="px-4 py-4 text-left">
      {coupon.variant.product.name}
      <span class="text-gray-500 text-sm text-medium block"
        >{coupon.variant.name}</span
      >
    </td>
    <td class="px-4 py-4 text-right">{coupon.variant.price_units / 100}</td>
  {:else}
    <td class="px-4 py-4 text-center">
      <MoneyField
        bind:amountInRupees={minCartValue}
        bind:amountInPaisa={coupon.min_cart_value}
      />
    </td>
  {/if}
  {#if campaign.kind != "lightning"}
    <td class="px-4 py-4 text-center">
      <RadioButtonsPill
        options={discountTypes}
        bind:field={coupon.discount_type}
      />
    </td>
  {/if}
  <td class="px-4 py-4 text-right relative w-24">
    {#if coupon.discount_type === "percentage"}
      <Field
        bind:field={coupon.discount_value}
        inputClass="input-sm"
        name="discount-amount"
      />
      {#if showDiscountAmountError}
        <span
          class="absolute left-4 text-left w-full whitespace-nowrap text-red-400 text-xs"
          >Percentage value should be between 0-100</span
        >
      {/if}
    {:else}
      <MoneyField
        bind:amountInRupees={discountAmount}
        bind:amountInPaisa={coupon.discount_value}
        name="discount-amount"
      />
    {/if}
  </td>
  <td class="px-4 py-4 text-right">{coupon.used_count}</td>
  <td class="px-4 py-4 text-right w-24">
    <Field
      bind:field={coupon.max_count}
      inputClass="input-sm"
      name="max-count"
    />
  </td>
  <td class="px-4 py-4 text-right w-48">
    <Field bind:field={coupon.code} inputClass="input-sm" name="coupon-code" />
  </td>
  <td class="px-4 py-4 text-right">
    {#if campaign.status == "draft"}
      <button
        on:click|preventDefault={destroy}
        class="icon icon-cross float-right opacity-50 hover:opacity-100"
        >delete</button
      >
    {:else}
      <span
        class="text-xs px-2 py-1 rounded-full bg-gray-100 whitespace-nowrap "
        >{coupon.status}</span
      >
    {/if}
  </td>
</tr>
