<script>
  import CatalogReorder from "./CatalogReorder.svelte";

  export let category,
    url,
    reorder,
    select,
    checkedNodes,
    path,
    selectedFolderId;
  import { jsonHeaders, jsonFileUploadHeaders } from "../../shared/utils.js";
  import { fade } from "svelte/transition";
  import CatalogCategory from "./CatalogCategory.svelte";

  let selectedCategory = undefined;
  let categories = [],
    products = [],
    checkedSubCategoryNodes = [],
    checkedCategoryNodes = [];
  let parentFolder = [...path];
  let showNewCategoryForm = false;

  function getContents(category) {
    console.log(category);
    selectedCategory = undefined;

    fetch(requestUrl(), {
      method: "GET",
      headers: jsonFileUploadHeaders(),
    })
      .then((response) => response.json())
      .then((data) => {
        categories = data.categories;
        products = data.products;
        console.log(categories);
      });
  }

  const selectCategory = (category) => {
    if (!select && !reorder) {
      selectedCategory = category;
    }
  };

  function setPath(category) {
    path = [...parentFolder, currentFolder()];
  }

  const requestUrl = () => (category ? `${url}/${category.id}` : url);
  const deselectCategory = () => (selectedCategory = undefined);
  const refresh = () => (categories = categories);
  const reorderUrl = () =>
    category ? `${url}/${category.id}/categories` : url;

  const currentFolder = () => {
    return category
      ? { id: category.id, name: category.name }
      : { id: "0", name: "Product Lines" };
  };

  const setCurrentCategory = (folderId) => {
    if (
      (category && category.id === folderId) ||
      (!category && folderId === "0")
    ) {
      selectedCategory = undefined;
      setPath(category);
    }
  };

  const showCategoryForm = () => (showNewCategoryForm = true);

  $: getContents(category);
  $: setPath(category);
  $: checkedCategoryNodes =
    categories.length > 0 ? categories.filter((c) => c.checked) : [];
  $: checkedProductNodes =
    products.length > 0 ? products.filter((c) => c.checked) : [];
  $: checkedNodes = selectedCategory
    ? checkedSubCategoryNodes
    : [...checkedCategoryNodes, ...checkedProductNodes];
  $: setCurrentCategory(selectedFolderId);
</script>

<div
  transition:fade
  class="column float-left border-r border-gray-300 h-full w-72"
>
  <ul class="relative">
    <CatalogReorder
      bind:items={categories}
      enable={reorder}
      url={`${reorderUrl()}/reorder_subcategories`}
      let:item
      let:index
    >
      <CatalogCategory
        bind:category={categories[index]}
        {selectedCategory}
        {select}
        {reorder}
        {url}
        on:message={(e) => selectCategory(item)}
        on:categoryChange={(category) => getContents()}
      />
    </CatalogReorder>

    <CatalogReorder
      bind:items={products}
      enable={reorder}
      url={`${reorderUrl()}/reorder_products`}
      let:item
      let:index
    >
      <div
        class="cursor-pointer px-6 py-3 border-b border-gray-300 {!selectedCategory ||
        selectedCategory === category
          ? 'text-gray-800'
          : 'text-gray-500'}"
      >
        {#if !selectedCategory}
          <span>
            {#if select}<input
                type="checkbox"
                bind:checked={products[index].checked}
                on:click|stopPropagation
              />{/if}
            {#if reorder}<button
                class="absolute left-1 top-3 icon icon-drag-handle cursor-move float-left focus:outline-none"
                >reorder</button
              >{/if}
          </span>
        {/if}

        {item.name}
      </div>
    </CatalogReorder>
  </ul>
</div>

{#if selectedCategory}
  <svelte:self
    category={selectedCategory}
    bind:checkedNodes={checkedSubCategoryNodes}
    bind:path
    {url}
    {reorder}
    {select}
    {selectedFolderId}
  />
{/if}
