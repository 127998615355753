<script>
  import CategorySelect from "./../categories/CategorySelect.svelte";
  import { createEventDispatcher } from "svelte";
  export let product, productUrl, categoriesUrl;

  let categories = [],
    showCategoriesForm = false;
  let activeCategory = product.category;
  const dispatch = createEventDispatcher();

  const changeCategory = () => {
    showCategoriesForm = true;
  };
</script>

<div class="relative z-10">
  <span class="label">Product Line</span>
  <select
    name=""
    id=""
    on:mousedown|preventDefault
    on:click|preventDefault={changeCategory}
  >
    {#if activeCategory}
      <option value={product.category.id}>{product.category.name}</option>
    {:else}
      <option value="" disabled>Select Product Line</option>
    {/if}
  </select>

  {#if showCategoriesForm}
    <CategorySelect
      bind:activeCategory
      {product}
      {productUrl}
      url={categoriesUrl}
      bind:show={showCategoriesForm}
      popupKind="select"
      cssClasses={"absolute -ml-4 w-72"}
    />
  {/if}
</div>
