<script>
  export let checked,
    checkedLabel,
    uncheckedLabel,
    name,
    containerCss,
    inputCss;
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();
  const toggleChecked = () => {
    checked = !checked;
    dispatch("change", { checked: checked });
  };
</script>

<div
  on:click={toggleChecked}
  class="toggle-button-container {containerCss} {checked ? 'is-checked' : ''}"
>
  {#if checkedLabel && checked}
    <label for={name}>{checkedLabel}</label>
  {/if}

  {#if uncheckedLabel && !checked}
    <label for={name}>{uncheckedLabel}</label>
  {/if}

  <input bind:checked {name} type="checkbox" class={inputCss} />
</div>
