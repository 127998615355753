<script>
  import { jsonHeaders } from "../../../shared/utils.js";
  import Field from "../../../forms/Field.svelte";

  export let selectedPack, liteLicensePackPurchases, url, showRecharge;

  let paymentMode,
    bankName,
    chequeNumber,
    errors = {},
    apiInProgress = false;
  let currencyFmt = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

  const toggleRecharge = () => {
    showRecharge = !showRecharge;
  };

  const onSave = () => {
    apiInProgress = true;
    fetch(`${url}`, {
      method: "POST",
      headers: jsonHeaders(),
      body: JSON.stringify({
        pack_id: selectedPack.pack_details.id,
        payment_mode: paymentMode,
        bank_name: bankName,
        cheque_number: chequeNumber,
      }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.errors) {
          errors = resp.errors;
        } else {
          location.href = url;
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        apiInProgress = false;
      });
  };
</script>

<div
  class="fixed z-10 inset-0 overflow-y-auto"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true"
>
  <div
    class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
  >
    <div
      class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      aria-hidden="true"
      on:click={toggleRecharge}
    />

    <span
      class="hidden sm:inline-block sm:align-middle sm:h-screen "
      aria-hidden="true">&#8203;</span
    >
    <div
      class="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle w-6/12"
    >
      <div class="p-8">
        <h4 class="text-primary-800 font-medium text-lg mb-6">
          Recharge - Pack {selectedPack.pack_details.name}
        </h4>
        <ul class="border-b border-primary-200 pb-4 mb-4">
          <li>
            <div class="flex items-center text-primary-800">
              <p class="text-primary-500">Price</p>
              <span class="flex-auto border-b border-gray-200 mx-6" />
              <p>
                {currencyFmt.format(
                  selectedPack.pack_details.total_price_units / 100
                )}
              </p>
            </div>
          </li>
          <!-- <li>
            <div class="flex items-center text-primary-800">
              <p class="text-primary-500">Taxable Price</p>
              <span class="flex-auto border-b border-gray-200 mx-6" />
              <p>₹ 41000</p>
            </div>
          </li>
          <li>
            <div class="flex items-center text-primary-800">
              <p class="text-primary-500">SGST (@9%)</p>
              <span class="flex-auto border-b border-gray-200 mx-6" />
              <p>₹ 4500</p>
            </div>
          </li>
          <li>
            <div class="flex items-center text-primary-800">
              <p class="text-primary-500">CGST (@9%)</p>
              <span class="flex-auto border-b border-gray-200 mx-6" />
              <p>₹ 4500</p>
            </div>
          </li> -->
        </ul>
        <div class="grid grid-cols-2 gap-4">
          <label class="col-span-2"
            >Payment Method
            <select
              required
              bind:value={paymentMode}
              class="select mt-1"
              name="payment_mode"
              id="payment_mode"
            >
              {#each ["cheque", "bank-transfer", "link"] as pType (pType)}
                <option value={pType}>{pType}</option>
              {/each}
            </select>
          </label>

          {#if paymentMode === "cheque"}
            <Field
              bind:field={bankName}
              error={errors.bank_name}
              label="Bank Name"
              name="bank_name"
              placeholder="Enter Bank Name"
              required={true}
            />

            <Field
              bind:field={chequeNumber}
              label="Cheque Number"
              error={errors.cheque_number}
              placeholder="Enter Cheque Number"
              required={true}
            />
          {/if}
        </div>
      </div>
      <div class="bg-primary-150 px-8 py-4 border-t border-primary-200">
        <div class="flex">
          {#if !apiInProgress}
            <button on:click|preventDefault={onSave} class="button-primary mr-8"
              >Save</button
            >
            <button
              on:click|preventDefault={toggleRecharge}
              class="button-secondary">Cancel</button
            >
          {/if}
        </div>
      </div>
    </div>
  </div>
</div>
