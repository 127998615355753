<script>
  import { jsonHeaders, analyticsDataUrl, dasherize } from "../shared/utils.js";

  export let title, url, colspan, filters, icon;
  let value = "";

  const getData = (filters) => {
    fetch(analyticsDataUrl(url, filters), {
      method: "GET",
      headers: jsonHeaders(),
    })
      .then((response) => response.json())
      .then((data) => {
        value = data.value;
      });
  };

  $: {
    getData(filters);
  }
</script>

<div
  id={`callout-${dasherize(title)}`}
  class="highlight col-span-{colspan} border border-gray-200 text-primary-800 rounded h-20 pl-20 relative"
>
  <span
    class="icon icon-{icon} absolute left-0 top-0 w-20 h-full icon-cart bg-secondary-200 block"
  />
  <div class="px-4 pt-4">
    <h4 id={`callout-${dasherize(title)}-title`} class="text-xs uppercase">
      {title}
    </h4>
    <h5 id={`callout-${dasherize(title)}-value`} class="text-xl font-medium">
      {value}
    </h5>
  </div>
</div>
