<script>
  import { jsonHeaders } from "../shared/utils.js";
  import { fade } from "svelte/transition";
  import Field from "../forms/Field.svelte";

  export let showChooseTemplate,
    productPropertySets,
    product,
    propertySetTemplates,
    propertySetsUrl;

  let visibleTemplates = propertySetTemplates,
    searchTemplateName = "",
    errors = {};

  const searchTemplates = () => {
    if (searchTemplateName) {
      visibleTemplates = propertySetTemplates.filter((propertySetTemplate) =>
        propertySetTemplate.name.includes(searchTemplateName)
      );
    } else {
      visibleTemplates = propertySetTemplates;
    }
  };

  const importTemplate = (propertySetId) => {
    let importUrl = `${propertySetsUrl}/${propertySetId}/import`;
    fetch(importUrl, {
      method: "POST",
      headers: jsonHeaders(),
      body: JSON.stringify({ product_id: product.id }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if ("errors" in data) {
          errors = data.errors;
          console.log(data);
        } else {
          errors = {};
          console.log(data);
          productPropertySets = data.property_sets;
        }
      });
  };
</script>

{#if showChooseTemplate}
  <div
    class="fixed z-10 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      />

      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
      />
      <div class="float-right relative">
        <div
          transition:fade
          class="flex items-center justify-center bg-opacity-75 fixed z-10 top-0 right-0 bottom-0 left-0"
        >
          <div
            class="container w-128 p-8 bg-white rounded-lg text-left shadow-xl transform transition-all border border-primary-200"
          >
            <a
              href="#"
              on:click={(e) => {
                showChooseTemplate = false;
              }}
              class="absolute top-0 right-0 -mt-2 -mr-2 p-1 text-center h-8 w-8 bg-white rounded-full shadow-md"
              data-test-close-choose-template="close-choose-template">×</a
            >

            <h2 class="font-medium capitalize text-primary-800">
              Import Template
            </h2>
            <form action="" method="GET" class="m-4">
              <Field
                bind:field={searchTemplateName}
                name="search"
                label="Search Templates"
              />
              <button
                data-test-search-button="search-button"
                on:click|preventDefault={searchTemplates}
                class="button-primary"
              >
                Search
              </button>
            </form>

            {#each visibleTemplates as visibleTemplate}
              <div class="col-span-2">
                {visibleTemplate.name}
                <button
                  data-test-import-template={visibleTemplate.name}
                  on:click={importTemplate(visibleTemplate.id)}
                  class="button-primary"
                >
                  Add
                </button>
              </div>
            {/each}
          </div>
        </div>
      </div>
    </div>
  </div>
{/if}
