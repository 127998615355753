<style>
  .active-top::before,
  .active-bottom::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 8px;
    border-radius: 4px;
    background: #8d92bb;
    left: 0;
    z-index: 10;
  }

  .active-top::before {
    top: -4px;
  }

  .active-bottom::before {
    bottom: -4px;
  }
</style>

<script>
  import { jsonHeaders } from "./utils.js";
  import { flip } from "svelte/animate";
  import pick from "lodash/pick";

  export let items, url, enable;
  let draggedElementId, hoverElementId, hoverClass;

  const dragStart = (index) => (draggedElementId = index);

  const dragEnd = (index) => {
    if (hoverElementId != draggedElementId) {
      let newPosition = hoverElementId;
      let draggedElement = items.splice(draggedElementId, 1);
      items.splice(newPosition, 0, ...draggedElement);

      updatePositions();
      savePositions();

      items = items;
    }

    draggedElementId = undefined;
    hoverElementId = undefined;
  };

  const dragEnter = (index) => {
    if (draggedElementId !== index) {
      hoverElementId = index;
    } else {
      hoverElementId = undefined;
    }

    if (hoverElementId) {
      if (hoverElementId < draggedElementId) {
        hoverClass = "active-top";
      } else {
        hoverClass = "active-bottom";
      }
    }
  };

  const updatePositions = () => items.forEach((item, i) => (item.position = i));

  const savePositions = () => {
    fetch(url, {
      method: "POST",
      headers: jsonHeaders(),
      body: JSON.stringify({
        items: items.map((x) => pick(x, ["id", "position"])),
      }),
    });
  };
</script>

<ul>
  {#each items as item, index (item.name)}
    <li
      class="relative block m-0 transition-all {draggedElementId === index
        ? 'opacity-50'
        : ''} {hoverElementId === index ? hoverClass : ''}"
      draggable={enable}
      animate:flip={{ duration: 300 }}
      on:dragstart={() => dragStart(index)}
      on:dragend|stopPropagation={() => dragEnd(index)}
      on:dragenter={() => dragEnter(index)}
      on:dragover|preventDefault
      on:dragleave|preventDefault
      on:drop|preventDefault|stopPropagation
    >
      <slot {item} {index} />
    </li>
  {/each}
</ul>
