<script>
  import EditVariantMarketing from "./EditVariantMarketing.svelte";

  export let product, variants, productUrl, properties;

  const url = (variant) => `${productUrl}/variants/${variant.id}`;
</script>

{#if properties.has_variants}
  <h3 class="text-lg font-semibold mb-4 mt-8 pt-4 border-t border-primary-300">
    Variants
  </h3>
{/if}

{#each variants as variant (variant.name)}
  <EditVariantMarketing {variant} {product} {url} {properties} />
{/each}
