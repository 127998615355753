<script>
  import { onMount } from "svelte";
  import { fade } from "svelte/transition";
  import { jsonFileUploadHeaders } from "../../shared/utils.js";
  export let resourceUrl = "";
  let errors = {};
  let materialUpload = {};
  let fileName = "";
  let shouldShowForm = false;
  let processor = "";

  const toggleForm = (processorType) => {
    shouldShowForm = !shouldShowForm;
    materialUpload = {
      file: "",
    };
    processor = processorType;
    errors = {};
  };

  const readFile = (input) => {
    if (input.target.files && input.target.files[0]) {
      fileName = input.target.files[0].name;
    }
  };

  const createUpload = (event) => {
    const formData = new FormData(event.target);
    formData.append("processor", processor);
    return fetch(resourceUrl, {
      method: "POST",
      headers: jsonFileUploadHeaders(),
      body: formData,
    })
      .then((response) =>
        response.json().then((data) => ({
          code: response.status,
          responseJson: data,
        }))
      )
      .then((data) => {
        console.log(data.responseJson);
        if (data.code === 201) {
          materialUpload = data.responseJson;
          location.href = `${resourceUrl}`;
          errors = nil;
        } else {
          errors = data.responseJson.errors;
        }
        console.log(catalog, errors);
      });
  };

  onMount(() => {
    document.querySelector("#button-add-material-upload").onclick = (event) => {
      event.preventDefault();
      toggleForm("materials");
    };
    document.querySelector("#button-add-variant-upload").onclick = (event) => {
      event.preventDefault();
      toggleForm("variants");
    };
    return () => {
      document.querySelector("#button-add-material-upload").onclick = null;
      document.querySelector("#button-add-variant-upload").onclick = null;
    };
  });
</script>

{#if shouldShowForm}
  <div
    transition:fade
    class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 bg-black bg-opacity-75 fixed z-10 top-0 right-0 bottom-0 left-0 transition-all duration-150"
  >
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" />
    <div
      class="inline-block align-bottom bg-white rounded-lg p-8 text-left shadow-xl transform transition-all my-8 align-middle max-w-lg sm:align-middle w-160"
    >
      <button
        on:click={toggleForm}
        class="absolute top-0 right-0 -mt-2 -mr-2 p-1 text-center h-8 w-8 bg-white rounded-full shadow-sm"
        >×</button
      >
      <h3 class="text-lg text-primary-900 font-semibold mb-2">New Upload</h3>
      <form on:submit|preventDefault={createUpload} action=".">
        <div class="row-span-3 pt-2 col-start-3">
          <p class="label">Upload</p>
          {#if errors.file}
            <span class="field-error">{errors.file[0]}</span>
          {/if}
          <div
            class="image-upload-wrap bg-primary-100 border border-gray-300 shadow-sm rounded-md relative h-36 flex flex-wrap justify-center"
          >
            <span class=" icon icon-file mt-6 h-10 w-full" />
            <input
              class="file-upload-input "
              type="file"
              id="file"
              name="file"
              on:change={readFile}
              required
            />
            <div class="text-center text-primary-800">
              {#if fileName}
                {fileName}
              {:else}
                <p>Drag and drop a csv file</p>
                <p>or <span class="font-bold underline">Browse</span></p>
              {/if}
            </div>
          </div>
        </div>
        <button class="button-primary mt-6" type="submit">Upload</button>
      </form>
    </div>
  </div>
{/if}

<style>
  .file-upload-input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
  }
</style>
