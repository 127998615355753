<script>
  import { onMount } from "svelte";
  import debounce from "lodash/debounce";
  import { jsonHeaders } from "../../shared/utils.js";
  import TagField from "../../forms/TagField.svelte";
  export let country, catalog, regionsUrl;
  let regionsList = [];

  const addRegion = () => {
    const stateName = `name your state ${Math.random()
      .toString(36)
      .substring(10)}`;

    // create state
    fetch(regionsUrl, {
      method: "POST",
      headers: jsonHeaders(),
      body: JSON.stringify({
        name: stateName,
        parent_id: country.id,
        kind: "state",
        all_selected: true,
      }),
    })
      .then((response) =>
        response.json().then((data) => ({
          status: response.status,
          data: data,
        }))
      )
      .then((resp) => {
        if (resp.status === 200) {
          regionsList = regionsList.concat({
            state: {
              id: resp.data.region.id,
              name: resp.data.region.name,
              all_selected: resp.data.region.all_selected,
            },
            cities: [],
          });
        } else {
          console.log(resp.data);
        }
      });
  };

  const removeRegion = (region) => {
    // create state
    fetch(`${regionsUrl}/${region.state.id}`, {
      method: "DELETE",
      headers: jsonHeaders(),
    })
      .then((response) =>
        response.json().then((data) => ({
          status: response.status,
          data: data,
        }))
      )
      .then((resp) => {
        if (resp.status === 200) {
          regionsList = regionsList.filter(
            (item) => item.state.id !== region.state.id
          );
        } else {
          console.log(resp.data);
        }
      });
  };

  const onAddCity = (region, city) => {
    const cityCreator = () =>
      fetch(regionsUrl, {
        method: "POST",
        headers: jsonHeaders(),
        body: JSON.stringify({
          name: city,
          parent_id: region.state.id,
          kind: "city",
          all_selected: true,
        }),
      })
        .then((response) =>
          response.json().then((data) => ({
            status: response.status,
            data: data,
          }))
        )
        .then((resp) => {
          if (resp.status === 200) {
            let stateRegion = regionsList.find(
              (item) => item.state.id === region.state.id
            );
            stateRegion.cities.push({
              id: resp.data.region.id,
              name: resp.data.region.name,
            });
          } else {
            console.log(resp.data);
          }
        });
    const allSelector = () =>
      fetch(`${regionsUrl}/${region.state.id}`, {
        method: "PATCH",
        headers: jsonHeaders(),
        body: JSON.stringify({
          region: {
            all_selected: false,
          },
        }),
      })
        .then((response) =>
          response.json().then((data) => ({
            status: response.status,
            data: data,
          }))
        )
        .then((resp) => {
          if (resp.status == 200) {
            region.state.all_selected = false;
          }
        });
    if (region.state.all_selected) {
      allSelector().then(() => cityCreator());
    } else {
      cityCreator();
    }
  };

  const onRemoveCity = (region, city) => {
    const cityId = region.cities.find(
      (item) => item.name.toLowerCase() === city.toLowerCase()
    ).id;
    const cityDeleter = () =>
      fetch(`${regionsUrl}/${cityId}`, {
        method: "DELETE",
        headers: jsonHeaders(),
      })
        .then((response) =>
          response.json().then((data) => ({
            status: response.status,
            data: data,
          }))
        )
        .then((resp) => {
          if (resp.status === 200) {
            region.cities = region.cities.filter((item) => item.id !== cityId);
          } else {
            console.log(resp.data);
          }
        });
    const allSelector = () =>
      fetch(`${regionsUrl}/${region.state.id}`, {
        method: "PATCH",
        headers: jsonHeaders(),
        body: JSON.stringify({
          region: {
            all_selected: true,
          },
        }),
      })
        .then((response) =>
          response.json().then((data) => ({
            status: response.status,
            data: data,
          }))
        )
        .then((resp) => {
          if (resp.status == 200) {
            region.state.all_selected = true;
          }
        });

    cityDeleter().then(() => {
      if (region.cities.length === 0) {
        return allSelector();
      }
    });
  };

  const onStateNameChangedFn = (state, newName) => {
    let editStateUrl = `${regionsUrl}/${state.id}`;

    fetch(editStateUrl, {
      method: "PATCH",
      headers: jsonHeaders(),
      body: JSON.stringify({
        region: {
          name: newName,
        },
      }),
    })
      .then((response) =>
        response.json().then((data) => ({
          status: response.status,
          data: data,
        }))
      )
      .then((resp) => {
        console.log(resp);
      });
  };

  const onStateNameChanged = debounce(onStateNameChangedFn, 2000);

  onMount(() => {
    fetch(regionsUrl, {
      method: "GET",
      headers: jsonHeaders(),
    })
      .then((response) =>
        response.json().then((data) => ({
          status: response.status,
          data: data,
        }))
      )
      .then((resp) => {
        if (resp.status == 200) {
          regionsList = resp.data;
        } else {
          console.log(resp.data);
        }
      });
  });
</script>

<div class="grid grid-cols-3 gap-8 border-t border-b border-gray-300 py-2">
  <span class="col-span-1 leading-none text-xs">State or Union Territories</span
  >
  <span class="col-span-2 leading-none text-xs">City</span>
</div>
<ul class="mb-4 py-4 border-b border-gray-300">
  {#each regionsList as region, i}
    <li class="flex mb-2">
      <div class="w-64 mr-2">
        <label for="" class="label text-xs font-medium"> State </label>
        <input
          value={region.state.name}
          type="text"
          name="kind"
          class="block w-full"
          on:change={(event) =>
            onStateNameChanged(region.state, event.target.value)}
        />
      </div>
      <div class="w-3/5 mr-2">
        <label for="" class="label text-xs text-medium">Cities</label>
        <TagField
          tags={region.cities.map((item) => item.name)}
          onAdded={(city) => onAddCity(region, city)}
          onRemoved={(city) => onRemoveCity(region, city)}
        />
      </div>
      <button
        on:click|preventDefault={(event) => removeRegion(region)}
        class="icon icon-trash mt-9 ml-3">delete</button
      >
    </li>
  {/each}
</ul>

<a
  href="#"
  on:click|preventDefault={addRegion}
  class="button button-transparent">+Add Region</a
>
