<script>
  export let property;
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();
  let show = true;

  const toggleProperty = () => {
    show = !show;
    dispatch("toggle", { show: show, property: property });
  };
</script>

<li
  on:click={toggleProperty}
  class="cursor-pointer text-sm {show
    ? 'bg-primary-800 text-white'
    : 'bg-primary-200 text-primary-800'} rounded-full px-3 py-1 mr-2"
>
  {property}
</li>
