<script>
  import { slide } from "svelte/transition";
  import { jsonHeaders } from "../shared/utils.js";
  import CustomerInformation from "./CustomerInformation";
  import ProductsList from "./ProductsList";
  import OrderPayment from "./OrderPayment";
  import ShippingAddress from "./ShippingAddress";
  import isEmpty from "lodash/isEmpty";

  let selectedCustomer,
    selectedVariants = [],
    paymentDetails = {},
    shippingAddress = {},
    errors = {},
    isValidToCreate = false,
    isOrderCreationInProgress = false,
    showReviewOrder = false;

  let sections = [
    {
      id: 1,
      title: "Customer Information",
      component: CustomerInformation,
      active: true,
    },
    {
      id: 2,
      title: "Shipping Address",
      component: ShippingAddress,
      active: false,
    },
    {
      id: 3,
      title: "Choose Products",
      component: ProductsList,
      active: false,
    },
    {
      id: 4,
      title: "Payment",
      component: OrderPayment,
      active: false,
    },
  ];
  const expand = (section) => {
    sections = sections.map((s) => {
      s.active = false;
      if (s.id === section.id) {
        s.active = true;
      }
      return s;
    });
  };
  const setShowReviewOrder = (val) => {
    showReviewOrder = val;
  };
  const createOrder = () => {
    isOrderCreationInProgress = true;
    const jsonData = {
      order_items: selectedVariants.map((item) => ({
        quantity: item.quantity,
        sku: item.sku,
        price_units: item.price_units,
        list_price_units: item.list_price_units,
      })),
      payment_details: {
        ...paymentDetails,
      },
      shipping_address: {
        ...shippingAddress,
      },
      customer_id: selectedCustomer.id,
    };
    fetch("/admin/orders", {
      method: "POST",
      headers: jsonHeaders(),
      body: JSON.stringify(jsonData),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.order && data.order.id) {
          location.href = `/admin/orders/${data.order.id}`;
        } else if ("errors" in data) {
          errors = data.errors;
        }
      })
      .finally(() => {
        isOrderCreationInProgress = false;
      });
  };
  const discardOrder = () => {
    setShowReviewOrder(false);
    selectedCustomer = null;
    selectedVariants = [];
    shippingAddress = {};
    errors = {};
  };
  $: {
    if (
      selectedCustomer &&
      selectedCustomer.id &&
      selectedVariants &&
      selectedVariants.length > 0 &&
      paymentDetails &&
      paymentDetails.clearance_mode
    ) {
      isValidToCreate = true;
    } else {
      isValidToCreate = false;
    }
  }
</script>

<div class="p-8">
  <div class="flex items-center mt-4 mb-8 overflow-hidden">
    <a href="/admin/orders" class="h2 float-left w-8 pt-2">←</a>
    <h2 class="h2 ">New Order</h2>
  </div>
</div>
{#if !isEmpty(errors)}
  <div
    class="md:w-10/12 lg:w-8/12 px-8 py-4 mb-8 mx-auto border border-red-600 bg-red-100 bg-opacity-50 rounded-md"
  >
    <h2 class="h2 text-red-800">Errors</h2>
    <ul class="list-disc">
      {#each Object.entries(errors) as [key, value]}
        <li class="text-sm">{value[0]}</li>
      {/each}
    </ul>
  </div>
{/if}
<div
  class="md:w-10/12 lg:w-8/12 border border-secondary-200 rounded-md mx-auto"
>
  {#if !showReviewOrder}
    {#each sections as section}
      <div class="accordion border-b border-secondary-200 px-10 py-8">
        <div class="flex items-center">
          <a
            href="#"
            on:click={() => expand(section)}
            class="flex flex-auto items-center h2 text-primary-800"
            ><span
              class="inline-block flex items-center justify-center bg-primary-800 text-white text-sm rounded-full px-2 py-1 w-8 h-8 mr-4"
              >{section.id}</span
            >{section.title}</a
          >
          {#if selectedCustomer && section.id === 1}
            <a
              href="#"
              class="button button-subtle"
              on:click={() => (selectedCustomer = undefined)}>Edit</a
            >
          {/if}
        </div>
        {#if section.active}
          <div class="ml-10" transition:slide>
            <svelte:component
              this={section.component}
              bind:selectedCustomer
              bind:selectedVariants
              bind:paymentDetails
              bind:shippingAddress
              showCustomerForm={false}
            />
          </div>
        {/if}
        {#if section.id === 4 && section.active}
          <button
            class="button ml-12 mt-2"
            on:click|preventDefault={() => setShowReviewOrder(true)}
            >Review Order</button
          >
        {/if}
      </div>
    {/each}
  {:else}
    {#each sections as section}
      <div class="accordion border-b border-secondary-200 px-10 py-8">
        <div class="flex items-center">
          <div class="flex flex-auto items-center h2 text-primary-800">
            <span
              class="inline-block flex items-center justify-center bg-primary-800 text-white text-sm rounded-full px-2 py-1 w-8 h-8 mr-4"
              >{section.id}</span
            >{section.title}
          </div>
        </div>
        <div class="ml-10" transition:slide>
          <svelte:component
            this={section.component}
            showReviewOrder
            bind:selectedCustomer
            bind:selectedVariants
            bind:paymentDetails
            bind:shippingAddress
          />
        </div>
      </div>
    {/each}
  {/if}
</div>
{#if showReviewOrder}
  <div class="flex justify-center my-8">
    {#if isValidToCreate}
      <button class="button ml-12 mt-2" on:click|preventDefault={createOrder}
        >Create Order</button
      >
    {/if}

    <button
      class="button-secondary ml-12 mt-2"
      on:click|preventDefault={discardOrder}>Discard</button
    >
  </div>
{/if}
{#if isOrderCreationInProgress}
  <div class="absolute z-10 top-0 left-0 bottom-0 right-0">
    <div class="loader loader-default is-active" data-text="Creating Order" />
  </div>
{/if}
