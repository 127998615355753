<script>
  import debounce from "lodash/debounce";
  import { jsonHeaders } from "../../shared/utils.js";
  import CatalogMount from "./CatalogMount.svelte";

  export let catalogs, categoriesUrl;
</script>

<table class="w-full">
  <thead>
    <tr class="text-xs text-primary-500 uppercase tracking-wider">
      <th class="font-medium px-8 py-3 text-left">Catalog Name</th>
      <th class="font-medium px-8 py-3 text-left">Brand Name</th>
      <th class="font-medium px-8 py-3 text-left">Products Count</th>
      <th class="font-medium px-8 py-3 text-left" />
    </tr>
  </thead>
  <tbody class="text-primary-800">
    {#each catalogs as catalog (catalog.access_request_id)}
      <tr transition:fade class="border-t border-gray-300">
        <td class="px-8 py-4 text-left">
          {catalog.name}
        </td>
        <td class="px-8 py-4 text-left">
          {catalog.brand_name}
        </td>
        <td class="px-8 py-4 text-left">
          {catalog.products_count}
        </td>
        <td class="px-8 py-4 text-left"
          ><CatalogMount
            mountedToCategory={catalog.mounted_to_category}
            {categoriesUrl}
            mountStatus={catalog.mount_status}
            accessRequestId={catalog.access_request_id}
          /></td
        >
      </tr>
    {/each}
  </tbody>
</table>
