<script>
  import { jsonHeaders } from "../../shared/utils.js";
  import Field from "../../forms/Field.svelte";
  import UpiQrCode from "./UpiQrCode.svelte";

  export let upiId, account, deleteqrCodeImageUrl, qrCodeImage;
  let apiInProgress;

  const updateUPIDetails = () => {
    apiInProgress = true;
    fetch(`/vizstore/admin/accounts/${account.id}/update_upi_id`, {
      method: "POST",
      headers: jsonHeaders(),
      body: JSON.stringify({
        bank_upi_id: upiId,
      }),
    })
      .then((response) => console.log(response))
      .finally(() => {
        apiInProgress = false;
      });
  };
</script>

<div class="grid grid-cols-3">
  <h3 class="text-lg font-semibold mb-2 col-span-3">UPI Details</h3>
  <div class="col-span-2">
    <Field bind:field={upiId} label="UPI ID" />
  </div>
  <div class="col-span-2">
    <UpiQrCode {account} {deleteqrCodeImageUrl} {qrCodeImage} />
  </div>
</div>

<button
  class="button-primary mt-6"
  disabled={apiInProgress ? true : false}
  on:click|preventDefault={updateUPIDetails}
  >{apiInProgress ? "Updating" : "Update UPI Details"}</button
>
