<script>
  import { onMount } from "svelte";
  import { fade } from "svelte/transition";
  import { jsonHeaders } from "../../shared/utils.js";
  export let resourceUrl = "";
  let materialUpload = {};
  let shouldShowContent = false;
  let fetchInProgress = false;

  const showContent = (id) => {
    shouldShowContent = true;
    materialUpload = {
      id,
      file: {
        name: "",
      },
    };
    fetchMaterialUpload();
  };

  const closeContent = () => {
    shouldShowContent = false;
  };

  const fetchMaterialUpload = (event) => {
    fetchInProgress = true;
    return fetch(`${resourceUrl}/${materialUpload.id}`, {
      method: "GET",
      headers: jsonHeaders(),
    })
      .then((response) =>
        response.json().then((data) => ({
          code: response.status,
          responseJson: data,
        }))
      )
      .then((data) => {
        if (data.code === 200) {
          materialUpload = data.responseJson;
        }
      })
      .finally(() => {
        fetchInProgress = false;
      });
  };

  onMount(() => {
    document.querySelectorAll(".button-show-material").forEach((element) => {
      element.onclick = (event) => {
        event.preventDefault();
        showContent(element.dataset.id);
      };
    });
    return () => {
      document.querySelectorAll(".button-show-material").forEach((element) => {
        element.onclick = null;
      });
    };
  });

  const copyContent = () => {
    var element = document.createRange();
    element.selectNode(document.querySelector(".backtrace-error-content"));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(element);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
  };
</script>

{#if shouldShowContent}
  <div
    transition:fade
    class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 bg-black bg-opacity-75 fixed z-10 top-0 right-0 bottom-0 left-0 transition-all duration-150"
  >
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" />
    <div
      class="inline-block align-bottom bg-white max-h-screen rounded-lg p-8 text-left shadow-xl transform transition-all my-8 align-middle max-w-6xl sm:align-middle w-full"
    >
      <button
        on:click={closeContent}
        class="absolute top-0 right-0 -mt-2 -mr-2 p-1 text-center h-8 w-8 bg-white rounded-full shadow-sm"
        >×</button
      >
      <h3 class="text-lg text-primary-900 font-semibold mb-2">Upload Result</h3>

      <div class="flex py-4">
        <div class="leading-none">
          <p class="text-sm font-medium text-primary-600">Status</p>
          <p>
            {#if materialUpload.status === "failure"}
              <p class="text-primary-800 relative flex items-center">
                <span
                  class="icon h-6 w-6 left-0 top-0 bg-primary-800 rounded-full absolute icon-cross-white"
                /><span class="ml-8 leading-5">Failure</span>
              </p>
            {/if}
            {#if materialUpload.status === "success"}
              <p class="text-primary-800 relative flex items-center">
                <span
                  class="icon h-6 w-6 left-0 top-0 bg-primary-800 rounded-full absolute icon-check-white"
                /><span class="ml-8 leading-5">Success</span>
              </p>
            {/if}
            {#if materialUpload.status === "draft"}
              <p class="text-primary-800 relative flex items-center">
                <span
                  class="icon h-6 w-6 left-0 top-0 bg-primary-800 rounded-full absolute icon-check-white"
                /><span class="ml-8 leading-5">Pending Processing</span>
              </p>
            {/if}
            {#if materialUpload.status === "in_progress"}
              <p class="text-primary-800 relative flex items-center">
                <span
                  class="icon h-6 w-6 left-0 top-0 bg-primary-800 rounded-full absolute icon-check-white"
                /><span class="ml-8 leading-5"
                  >Processing - {materialUpload.progress}%</span
                >
              </p>
            {/if}
          </p>
        </div>
      </div>

      <div class="flex py-4">
        <div class="leading-none mr-8">
          <p class="text-sm font-medium text-primary-600">File Name</p>
          <p>
            {materialUpload.file && materialUpload.file.name}
          </p>
        </div>
        <div class="leading-none mr-8">
          <p class="text-sm font-medium text-primary-600">Upload ID</p>
          <p>
            {materialUpload.id}
          </p>
        </div>
      </div>

      <div class="flex py-4">
        <div class="leading-none mr-8">
          <p class="text-sm font-medium text-primary-600">Uploaded At</p>
          <p>
            {materialUpload.created_at &&
              new Date(materialUpload.created_at).toLocaleString()}
          </p>
        </div>
        <div class="leading-none mr-8">
          <p class="text-sm font-medium text-primary-600">Processing Begin</p>
          <p>
            {materialUpload.started_at &&
              new Date(materialUpload.started_at).toLocaleString()}
          </p>
        </div>
        <div class="leading-none mr-8">
          <p class="text-sm font-medium text-primary-600">Processing End</p>
          <p>
            {materialUpload.ended_at &&
              new Date(materialUpload.ended_at).toLocaleString()}
          </p>
        </div>
      </div>

      {#if materialUpload.status === "failure"}
        <div class="flex py-4">
          <div class="leading-none mr-8">
            <p class="text-sm font-medium text-primary-600">Reason</p>
            <div class="module line-clamp text-sm">
              <p class="">
                {materialUpload.output.message}
              </p>
            </div>
          </div>
        </div>
        <div>
          <details>
            <summary>Content</summary>
            <a
              href="#"
              on:click={copyContent}
              class="button-subtle my-4 inline-block">Copy Error Log</a
            >
            <div
              class="border border-primary-300 bg-white p-8 col-span-2 max-h-64 overflow-scroll "
            >
              <p class="w-full text-sm backtrace-error-content">
                {materialUpload.output && JSON.stringify(materialUpload)}
              </p>
            </div>
          </details>
        </div>
      {/if}
    </div>
  </div>
{/if}

<style>
  .module {
    width: 1024px;
    margin: 0 0 1em 0;
    overflow: hidden;
  }
  .module p {
    margin: 0;
  }
  .line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
</style>
