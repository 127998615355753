<script>
  import { jsonHeaders } from "../../../shared/utils.js";
  export let url, payment;
  let paymentModeStatus;
  let paymentStatusOptions = [];
  let paymentRemark = "";
  let providerDetails = {};
  const onPaymentSubmit = () => {
    const confirmed = window.confirm(
      `Are you sure you want to change status from ${providerDetails.payment_clearance_mode_status} to ${paymentModeStatus}?`
    );
    if (confirmed) {
      console.log("fire request..");
      // update_payment_status

      fetch(`${url}/${payment.payable_id}/update_payment_status`, {
        method: "POST",
        headers: jsonHeaders(),
        body: JSON.stringify({
          payment_id: payment.id,
          status: paymentModeStatus,
          remark: paymentRemark,
        }),
      }).then(() => {
        location.href = url;
      });
    }
  };
  $: {
    providerDetails = payment.provider_details;
    if (providerDetails.payment_clearance_mode === "cheque") {
      paymentStatusOptions = payment.next_possible_cheque_status || [];
    } else {
      paymentStatusOptions = payment.next_possible_bank_transfer_status || [];
    }
  }
</script>

<ul
  class="w-full py-6 {payment.status === 'draft'
    ? 'border-b border-secondary-200'
    : ''} mt-0 flex"
>
  {#if providerDetails.payment_clearance_mode === "cheque"}
    <li class="mr-12">
      <p class="text-xs text-primary-500 mb-0">Payment Method</p>
      <p class="mb-0">Cheque</p>
    </li>
    <li class="mr-12">
      <p class="text-xs text-primary-500 mb-0">Payment Status</p>
      <p class="mb-0">{payment.status}</p>
    </li>
    <li class="mr-12">
      <p class="text-xs text-primary-500 mb-0">Cheque Status</p>
      <p class="mb-0">{providerDetails.payment_clearance_mode_status}</p>
    </li>
    <li class="mr-12">
      <p class="text-xs text-primary-500 mb-0">Cheque Details</p>
      <p class="mb-0">{providerDetails.payment_clearance_description || "-"}</p>
    </li>
    <li class="mr-12">
      <p class="text-xs text-primary-500 mb-0">Additional Details</p>
      <p class="mb-0">
        {providerDetails.payment_clearance_additional_details || "-"}
      </p>
    </li>
  {/if}

  {#if providerDetails.payment_clearance_mode === "bank-transfer"}
    <li class="mr-12">
      <p class="text-xs text-primary-500 mb-0">Payment Method</p>
      <p class="mb-0">Bank Transfer</p>
    </li>
    <li class="mr-12">
      <p class="text-xs text-primary-500 mb-0">Payment Status</p>
      <p class="mb-0">{payment.status}</p>
    </li>
    <li class="mr-12">
      <p class="text-xs text-primary-500 mb-0">Transfer Status</p>
      <p class="mb-0">{providerDetails.payment_clearance_mode_status}</p>
    </li>
    <li class="mr-12">
      <p class="text-xs text-primary-500 mb-0">Remarks</p>
      <p class="mb-0">
        {providerDetails.payment_clearance_additional_details || "-"}
      </p>
    </li>
  {/if}
</ul>
{#if payment.status === "draft"}
  <form
    on:submit|preventDefault={onPaymentSubmit}
    class="flex content-end items-end pb-6"
  >
    <div class="flex-auto mr-5">
      <div class="">
        <label for="" class=" mr-4 text-primary-600">Status</label>
        <select bind:value={paymentModeStatus} name="order_status" class="">
          {#each paymentStatusOptions as option}
            <option value={option}>
              {option}
            </option>
          {/each}
        </select>
      </div>
      <div>
        <label for="" class="mr-4 text-primary-600">Add Remark</label>
        <input type="text" bind:value={paymentRemark} />
      </div>
    </div>
    <button class="button-primary">Save</button>
  </form>
{/if}
