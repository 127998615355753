/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'hello_svelte' %> (and
// <%= stylesheet_pack_tag 'hello_svelte' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Svelte!</div> at the bottom of the page.

import Product from "../svelte/product/Product.svelte";
import NewProduct from "../svelte/product/NewProduct.svelte";
import NewOrder from "../svelte/order/NewOrder.svelte";
import Components from "../svelte/product/Components.svelte";
import Categories from "../svelte/Categories.svelte";
import Distributor from "../svelte/Distributor.svelte";
import StockLocation from "../svelte/StockLocation.svelte";
import EditProductMarketing from "../svelte/product/EditProductMarketing.svelte";
import EditProductPropertySets from "../svelte/product/EditProductPropertySets.svelte";
import NewPropertySet from "../svelte/property_sets/NewPropertySet.svelte";
import EditPropertySet from "../svelte/property_sets/EditPropertySet.svelte";
import ChooseTemplate from "../svelte/property_sets/ChooseTemplate.svelte";
import FromScratch from "../svelte/property_sets/FromScratch.svelte";
import EditMedium from "../svelte/property_sets/EditMedium.svelte";
import AddLink from "../svelte/property_sets/AddLink.svelte";
import Media from "../svelte/property_sets/Media.svelte";
import AddStock from "../svelte/product/AddStock.svelte";
import StoreLogo from "../svelte/account/StoreLogo.svelte";
import ListFilterForm from "../svelte/ListFilterForm.svelte";
import ActionBar from "../svelte/ActionBar.svelte";
import KebabMenu from "../svelte/KebabMenu.svelte";
import Invoice from "../svelte/Invoice.svelte";
import TaxCodes from "../svelte/taxes/TaxCodes.svelte";
import StoreTaxCodes from "../svelte/taxes/StoreTaxCodes.svelte";
import CustomerInformation from "../svelte/order/CustomerInformation.svelte";

import NewCouponCampaign from "../svelte/coupons/NewCouponCampaign.svelte";
import CouponCampaign from "../svelte/coupons/CouponCampaign.svelte";
import VariantCoupons from "../svelte/coupons/VariantCoupons.svelte";
import CartCoupons from "../svelte/coupons/CartCoupons.svelte";
import Analytics from "../svelte/analytics/Analytics.svelte";
import RazorpayCheckout from "../svelte/payments/RazorpayCheckout.svelte";
import UpdateExternalShipment from "../svelte/shipments/UpdateExternalShipment.svelte";
import InputResourceSelector from "../svelte/bulk_upload/InputResourceSelector.svelte";
import WebpackerSvelte from "webpacker-svelte";
import engineComponents from "../svelte/__engines__";

WebpackerSvelte.setup({
  NewProduct,
  NewOrder,
  Product,
  Invoice,
  TaxCodes,
  StoreTaxCodes,
  Distributor,
  StockLocation,
  Components,
  Categories,
  EditProductMarketing,
  EditProductPropertySets,
  NewPropertySet,
  EditPropertySet,
  ChooseTemplate,
  FromScratch,
  EditMedium,
  AddLink,
  Media,
  CouponCampaign,
  VariantCoupons,
  CartCoupons,
  NewCouponCampaign,
  AddStock,
  StoreLogo,
  KebabMenu,
  ListFilterForm,
  ActionBar,
  Analytics,
  CustomerInformation,
  RazorpayCheckout,
  UpdateExternalShipment,
  InputResourceSelector,
  ...engineComponents,
});
