<script>
  export let field, options, error;
</script>

{#each options as { value, label, description }}
  <label
    class="px-4 pt-48 pb-4 border border-primary-300 cursor-pointer hover:bg-primary-300 {value ===
    field
      ? 'bg-primary-300'
      : ''} rounded text-center font-bold"
  >
    <input
      type="radio"
      id="product[kind]"
      name="product[kind]"
      bind:group={field}
      {value}
      class="opacity-0 absolute"
    />

    <div class="block">
      <h4 class="my-2 text-gray-800">{label}</h4>
      <p class="text-sm font-normal text-gray-600">{description}</p>
    </div>
  </label>
{/each}

{#if error}<span class="field-error">{error}</span>{/if}
