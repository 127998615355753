<script>
  import { fade } from "svelte/transition";
  import { jsonFileUploadHeaders } from "../../shared/utils.js";

  export let mediaUploadUrl, productUrl, onClickCancel;
  let fileName = "";
  let mediaSrc = "";

  const readFileName = (input) => {
    if (input.target.files && input.target.files[0]) {
      fileName = input.target.files[0].name;

      const reader = new FileReader();

      reader.onload = function (e) {
        mediaSrc = e.target.result;
      };

      reader.readAsDataURL(input.target.files[0]);
    }
  };

  let fileUploadErrors = {},
    mediaUploadInprogress = false,
    mediaUploadInprogressCount = 0,
    mediaUploadInprogressTimer = null;

  const uploadFile = (event) => {
    const formData = new FormData(event.target);
    mediaUploadInprogress = true;
    fileUploadErrors = {};
    mediaUploadInprogressTimer && clearInterval(mediaUploadInprogressTimer);
    mediaUploadInprogressCount = 5;
    mediaUploadInprogressTimer = setInterval(() => {
      mediaUploadInprogressCount = mediaUploadInprogressCount + 5;
      if (mediaUploadInprogressCount > 100) {
        mediaUploadInprogressCount = 100;
      }
    }, 500);
    fetch(mediaUploadUrl, {
      method: "POST",
      headers: jsonFileUploadHeaders(),
      body: formData,
    })
      .then((response) =>
        response.json().then((data) => ({
          code: response.status,
          responseJson: data,
        }))
      )
      .then((data) => {
        if (data.code === 200) {
          location.href = `${productUrl}/edit`;
          fileUploadErrors = {};
        } else {
          fileUploadErrors = data.responseJson.errors;
        }
      })
      .finally(() => {
        mediaUploadInprogress = false;
        mediaUploadInprogressCount = 0;
        mediaUploadInprogressTimer && clearInterval(mediaUploadInprogressTimer);
      });
  };
</script>

<div
  on:click|stopPropagation
  transition:fade
  class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 bg-black bg-opacity-75 fixed z-10 top-0 right-0 bottom-0 left-0"
>
  <span class="hidden sm:inline-block sm:align-middle sm:h-screen" />
  <div
    class="inline-block align-bottom bg-white rounded-lg p-6 text-left shadow-xl transform transition-all my-8 align-middle max-w-lg my-8 sm:align-middle w-160"
  >
    <form on:submit|preventDefault={uploadFile} action=".">
      <div class="row-span-3 pt-2 col-start-3">
        <h3 class="text-lg font-semibold mb-4">Upload new image/video/pdf</h3>
        <div
          class="image-upload-wrap bg-primary-100 border border-gray-300 shadow-sm rounded-md relative h-36 flex flex-wrap justify-center"
        >
          <span class=" icon icon-file mt-6 h-10 w-full" />
          <input
            class="file-upload-input"
            type="file"
            id="file"
            name="file"
            on:change={readFileName}
            required
          />
          <div class="text-center text-primary-800">
            {#if fileName}
              {fileName}
            {:else}
              <p>Drag and drop an image/video or pdf file</p>
              <p>or <span class="font-bold underline">Browse</span></p>
            {/if}
          </div>
        </div>
        {#if fileUploadErrors.file}
          <span class="field-error">{fileUploadErrors.file[0]}</span>
        {/if}
      </div>
      {#if !mediaUploadInprogress}
        <div class="flex items-center">
          <button class="button-primary mt-6" type="submit">Upload File</button>
          <div class="flex-auto" />
          <button class="button mt-6" on:click|preventDefault={onClickCancel}
            >Cancel</button
          >
        </div>
      {:else}
        <div class="relative mt-16">
          <div
            class="h-3 rounded-md bg-gray-200 absolute left-6 right-6 bottom-8 border border-white"
          >
            <div
              class="absolute top-0 left-0 h-full bg-primary-800 rounded-md"
              style="width: {mediaUploadInprogressCount}%"
            />
          </div>
        </div>
      {/if}
    </form>
  </div>
</div>

<style>
  .file-upload-input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
  }
  img {
    max-width: 50px;
    max-height: 50px;
  }
</style>
