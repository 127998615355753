<script>
  import { createEventDispatcher } from "svelte";
  import { jsonHeaders } from "../shared/utils.js";
  import { fade } from "svelte/transition";

  export let category, formUrl;
  const url = () => `${formUrl}/${category.id}`;
  const dispatch = createEventDispatcher();

  const remove = () => {
    let confirmed = confirm("Are you sure you want to remove this category");

    if (confirmed) {
      fetch(url(category), {
        method: "DELETE",
        headers: jsonHeaders(),
      }).then((response) => {
        if (response.status === 200) {
          dispatch("remove", {});
        }
      });
    }
  };
</script>

<tr transition:fade class="border-t border-gray-300">
  <td class="px-4 py-2 text-sm text-left">{category.name}</td>
  <td class="px-4 py-2 text-sm text-left">{category.products_count}</td>
  <td>
    <button on:click={remove} class="icon icon-trash">delete</button>
  </td>
</tr>
