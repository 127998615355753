<script>
  import { DirectUpload } from "@rails/activestorage";
  import { onMount } from "svelte";
  import { jsonFileUploadHeaders } from "../shared/utils.js";

  export let attachmentType, kind, id, medium, label;
  let hover = 0,
    progress = 100,
    mediumUrl,
    image,
    method;

  const setMedium = () => {
    if (medium) {
      image = medium.file_url;
      mediumUrl = `/admin/media/${medium.id}`;
      method = "PATCH";
    } else {
      mediumUrl = "/admin/media";
      method = "POST";
    }
  };

  const dragEnter = (event) => hover++;
  const dragLeave = (event) => hover--;

  const drop = (event) => {
    hover = 0;

    let file = event.dataTransfer.files[0];
    let fileTypes = ["image/jpeg", "image/jpg", "image/png", "image/gif"];

    console.log(file);

    if (fileTypes.includes(file.type) && file.size <= 1024 * 1024 * 10) {
      previewFile(file);
      upload.uploadFile(file);
    } else {
      alert("please upload an image smaller than 10MB");
    }
  };

  const fileInputChange = (event) => {
    hover = 0;

    let files = event.target.files;
    previewFile(files[0]);
    upload.uploadFile(files[0]);
  };

  const previewFile = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = function () {
      image = reader.result;
    };
  };

  const upload = {
    uploadFile(file) {
      progress = 0;
      const directUpload = new DirectUpload(
        file,
        "/admin/direct_uploads",
        this
      );

      directUpload.create((error, blob) => {
        if (error) {
          console.log(error);
        } else {
          attachFile(blob.signed_id);
        }
      });
    },

    directUploadWillStoreFileWithXHR(request) {
      request.upload.addEventListener("progress", (event) =>
        this.directUploadDidProgress(event)
      );
    },

    directUploadDidProgress(event) {
      progress = (event.loaded / event.total) * 100;
    },
  };

  const attachFile = (blob) => {
    const formData = new FormData();

    formData.append("file", blob);
    formData.append("record_type", attachmentType);
    formData.append("record_id", id);
    formData.append("kind", kind);
    formData.append("label", label);

    fetch(mediumUrl, {
      method: method,
      headers: jsonFileUploadHeaders(),
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        medium = data.medium;
      });
  };

  $: setMedium(medium);
</script>

<div
  on:dragenter={dragEnter}
  on:dragleave={dragLeave}
  on:dragover|preventDefault
  on:drop|preventDefault|stopPropagation={drop}
  data-test-upload="{label}-{id}"
  class="input-field p-1 text-center relative w-full h-48 overflow-hidden cursor-pointer {hover >
  0
    ? 'ring-2 ring-blue-600 ring-opacity-10 border-blue-300 bg-white'
    : ''}"
>
  {#if image && hover === 0}
    <img src={image} alt="variant" class="w-full h-full object-contain" />
  {:else}
    <div
      on:drop|preventDefault
      class="icon icon-image w-16 h-16 opacity-50 mt-6"
    />
  {/if}

  {#if progress != 100}
    <div
      class="h-3 rounded-md bg-gray-200 absolute left-6 right-6 bottom-8 border border-white"
    >
      <div
        class="absolute top-0 left-0 h-full bg-primary-800 rounded-md"
        style="width: {progress}%"
      />
    </div>
  {:else if hover === 0}
    <span
      on:drop|preventDefault
      class="block pt-2 px-8 text-capitalize text-sm"
    >
      Drag &amp; drop {attachmentType} images here or
      <span class="underline font-medium">browse</span>
    </span>
  {:else}
    <span
      on:drop|preventDefault
      class="block absolute top-4 left-4 right-4 bottom-4 rounded-md bg-white border border-dashed border-gray-300 py-16"
      >Drop image to upload</span
    >
  {/if}

  <input
    type="file"
    class="absolute top-0 right-0 bottom-0 left-0 opacity-0"
    on:input|stopPropagation
    on:change|stopPropagation={fileInputChange}
  />
</div>
