<script>
  import findIndex from "lodash/findIndex";
  import { jsonHeaders } from "./shared/utils.js";
  import { fade } from "svelte/transition";
  export let regions, stockLocation, formUrl;
  let region, buttonText;
  let showForm = false;

  function save() {
    if (region.id) {
      updateRegion();
    } else {
      createRegion();
    }
  }

  function cancelSubmit(event) {
    event.preventDefault();
  }

  function createRegion() {
    fetch(formUrl, {
      method: "POST",
      headers: jsonHeaders(),
      body: JSON.stringify({
        postal_region: {
          ...region,
          stock_location_id: stockLocation.id,
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        region.id = data.id;
        regions.push(region);
        regions = regions;

        hideForm();
      });
  }

  function updateRegion() {
    let index = findIndex(regions, function (r) {
      return r.id === region.id;
    });

    fetch(`${formUrl}/${region.id}`, {
      method: "PATCH",
      headers: jsonHeaders(),
      body: JSON.stringify(region),
    }).then((response) => {
      if (response.status === 200) {
        hideForm();
        regions[index] = region;
      } else {
        alert("could not save region");
      }
    });
  }

  function addRegion() {
    region = { name: "", from_value: "", to_value: "", id: null };
    buttonText = "Create";
    showForm = true;
  }

  function editRegion(activeRegion) {
    region = { ...activeRegion };
    buttonText = "Update";
    showForm = true;
  }

  function hideForm() {
    showForm = false;
  }

  function remove(activeRegion) {
    region = activeRegion;

    let confirmed = confirm(
      "Are you sure you want to remove this postal region"
    );

    if (confirmed) {
      fetch(`${formUrl}/${region.id}`, {
        method: "DELETE",
        headers: jsonHeaders(),
      }).then((response) => {
        if (response.status === 200) {
          let index = regions.indexOf(region);

          regions = [
            ...regions.slice(0, index),
            ...regions.slice(index + 1, regions.length),
          ];
        }
      });
    }
  }
</script>

<div class="border border-primary-300 bg-white mt-8 rounded p-4 col-span-2">
  <h3 class="text-lg font-semibold mb-4">Regions</h3>
  <div class="border border-primary-300">
    <table class="w-full">
      <thead>
        <tr class="text-xs text-primary-500 uppercase tracking-wider">
          <th class="font-medium px-8 py-3 text-left">Postal Region</th>
          <th class="font-medium px-8 py-3 text-left">Pincode Ranges</th>
          <th />
        </tr>
      </thead>
      <tbody class="text-primary-800">
        {#each regions as region (region.id)}
          <tr transition:fade class="border-t border-gray-300">
            <td class="px-8 py-4 text-left">{region.name}</td>
            <td class="px-8 py-4 text-left">
              {region.from_value} -
              {region.to_value}
            </td>
            <td class="px-8 py-4 text-right">
              <button class="button-subtle" on:click={() => editRegion(region)}
                >Edit</button
              >
              <button
                on:click={(e) => {
                  e.preventDefault();
                  remove(region);
                }}
                class="icon icon-trash float-right ml-4 mt-1">delete</button
              >
            </td>
          </tr>
        {/each}
      </tbody>
    </table>
  </div>

  <button class="button mt-4" on:click={addRegion}>Add Region</button>
</div>

{#if showForm}
  <div
    transition:fade
    class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 bg-black bg-opacity-75 fixed z-10 top-0 right-0 bottom-0 left-0"
  >
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" />
    <div
      class="inline-block align-middle bg-white rounded-lg p-6 text-left shadow-xl transform transition-all my-8  max-w-lg my-8 sm:align-middle max-w-2xl w-full "
    >
      <button
        on:click={hideForm}
        class="absolute top-0 right-0 -mt-2 -mr-2 p-1 text-center h-8 w-8 bg-white rounded-full"
        >×</button
      >

      <h3 class="text-lg font-semibold mb-2">New Region</h3>

      <form on:submit={cancelSubmit} action=".">
        <div class="">
          <label class="label block" for="sku">Name</label>
          <input type="text" id="sku" class="w-full" bind:value={region.name} />
        </div>

        <div class="grid gap-4 grid-cols-2 my-2 mt-6">
          <div class="">
            <label class="label block" for="list-price"
              >Pincode range start</label
            >
            <input
              type="text"
              id="list-price"
              class="w-full"
              bind:value={region.from_value}
            />
          </div>

          <div class="">
            <label class="label block" for="sku">Pincode range end</label>
            <input
              type="text"
              id="stock"
              class="w-full"
              bind:value={region.to_value}
            />
          </div>
        </div>

        <button class="button-primary mt-6" on:click={save}>{buttonText}</button
        >
      </form>
    </div>
  </div>
{/if}
