<style>
  .stock-grid {
    grid-template-columns: 4rem 2rem;
  }

  .stock-grid-full {
    grid-template-columns: 3rem 4rem 7rem;
  }
</style>

<script>
  import { jsonHeaders } from "../shared/utils.js";
  import { onMount } from "svelte";
  import RadioButtonsPill from "../forms/RadioButtonsPill.svelte";

  export let variant,
    stock = [],
    collapse = false;

  let active = !collapse;

  let stockItem = {},
    errors = {},
    quantity = "0",
    stockType = "stock-added",
    url = `/admin/variants/${variant.id}/stock`,
    stockTypes = [
      { value: "stock-added", label: "+" },
      { value: "stock-removed", label: "-" },
    ];

  onMount(() => {
    if (stock.length > 0) {
      stockItem = stock[0];
    } else {
      stockItem = { quantity: 0 };
    }
  });

  const addStock = () => {
    let quantityInt = parseInt(quantity);

    if (stockType === "stock-removed") {
      quantityInt = -quantityInt;
    }

    errors = {};

    fetch(url, {
      method: "POST",
      headers: jsonHeaders(),
      body: JSON.stringify({ quantity: quantityInt, kind: stockType }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.errors) {
          errors = data.errors;
          console.log(data.errors);
        } else {
          stockItem = data.stock_item;
          stock[0].quantity = stockItem.quantity;
          quantity = 0;

          if (collapse) {
            active = false;
          }
        }
      });
  };
</script>

<div class="grid gap-4 m-0 p-0 {active ? 'stock-grid-full' : 'stock-grid'}">
  <span class="text-gray-900 py-1 text-right">{stockItem.quantity}</span>

  {#if active}
    <RadioButtonsPill options={stockTypes} bind:field={stockType} />

    <div class="block relative">
      <input
        bind:value={quantity}
        on:input|stopPropagation
        type="text"
        id="stock"
        class="w-full h-8"
      />
      <button
        on:click|preventDefault={addStock}
        class="icon h-8 w-10 right-0 top-0 bg-primary-800 rounded absolute icon-check-white"
      >
        save
      </button>
    </div>
  {:else}
    <button
      class="icon icon-edit opacity-50 hover:opacity-100 w-8 h-6 mt-1"
      on:click={() => (active = true)}
      >Edit
    </button>
  {/if}
</div>

{#if errors.quantity}
  <div class="gap-4 grid m-0 p-0">
    <span class="field-error">{errors.quantity.join(",")}</span>
  </div>
{/if}
