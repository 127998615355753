<style>
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
</style>

<script>
  import debounce from "lodash/debounce";
  import isEqual from "lodash/isEqual";
  import clone from "lodash/clone";
  import { createEventDispatcher, onMount } from "svelte";
  import { jsonHeaders } from "../shared/utils.js";
  import { fade, slide } from "svelte/transition";
  import MoneyField from "./../forms/MoneyField.svelte";
  import Field from "./../forms/Field.svelte";
  import ToggleButton from "../forms/ToggleButton.svelte";
  import AddStock from "./AddStock.svelte";
  import FileUpload from "../forms/FileUpload.svelte";

  export let product,
    variant,
    properties,
    taxNumbers,
    storeTaxCodes = [],
    formUrl,
    errors = {},
    closeVariant = true,
    deactivatedProperties = [],
    reorder = false;

  let showVariant = true,
    isEnabled = true,
    medium,
    list_price_units_rupees = 0,
    price_units_rupees = 0,
    prevVariant = {};
  const dispatch = createEventDispatcher();

  onMount(() => {
    prevVariant = clone(variant);
    setEnabled();
    setImage();
    list_price_units_rupees = variant.list_price_units / 100;
    price_units_rupees = variant.price_units / 100;
  });

  const setImage = () =>
    (medium = variant.media.find((media) => media.kind === "primary"));

  const setProperties = () => {
    variant.properties = Object.assign({ length: 0 }, variant.properties);
    variant.properties = Object.assign({ width: 0 }, variant.properties);
    variant.properties = Object.assign({ height: 0 }, variant.properties);
    variant.properties = Object.assign({ weight: 0 }, variant.properties);
  };

  const url = `${formUrl}/${variant.id}`;
  const activateVariant = () => submit(`${url}/activate`, "POST");
  const deactivateVariant = () => submit(`${url}/deactivate`, "POST");
  const toggleVisibility = () => (closeVariant = !closeVariant);

  const toggleEnabled = (event) =>
    event.detail.checked ? activateVariant() : deactivateVariant();

  const save = debounce(() => {
    if (!isEqual(prevVariant, variant)) {
      console.log("saving...");
      submit(url, "PATCH");
    }
  }, 1000);

  const submit = (url, method) => {
    fetch(url, {
      method: method,
      headers: jsonHeaders(),
      body: JSON.stringify(variant),
    })
      .then((response) => response.json())
      .then((data) => {
        if ("errors" in data) {
          errors = data.errors;
          // variant = data.variant;
          console.log(errors);
        } else {
          errors = {};
          variant = data.variant;
          prevVariant = clone(variant);
          console.log("success");
        }

        setEnabled();
      });
  };

  const remove = () => {
    let confirmed = confirm("Are you sure you want to remove this variant");

    if (confirmed) {
      fetch(url, {
        method: "DELETE",
        headers: jsonHeaders(),
      }).then((response) => {
        if (response.status === 200) {
          dispatch("remove", {});
        }
      });
    }
  };

  const setEnabled = () => {
    if (variant.status === "active") {
      closeVariant = false;
      isEnabled = true;
    } else {
      closeVariant = true;
      isEnabled = false;
    }
  };

  const setVisibility = () => {
    showVariant = true;

    for (let property of deactivatedProperties) {
      for (let attributes of variant.attribute_values) {
        if (attributes.value.indexOf(property) != -1) {
          showVariant = false;
        }
      }
    }
  };

  $: setVisibility(deactivatedProperties);
  $: setProperties(variant);
</script>

{#if showVariant}
  <form
    on:submit|preventDefault
    on:input={save}
    action={formUrl}
    method="post"
    class="pb-5 p-0 border-t border-primary-200 border-solid relative"
  >
    {#if !variant.is_default}
      <h2 class="col-span-full text-md font-medium mt-5 capitalize ">
        {#if reorder}
          <button
            class="icon icon-drag-handle cursor-move float-left mx-4 top-1 focus:outline-none"
            >reorder</button
          >
        {:else}
          <a
            href="#"
            on:click|preventDefault={toggleVisibility}
            class="icon {closeVariant
              ? 'icon-arrow-right'
              : 'icon-arrow-down'} absolute top-6 left-0 -ml-6 focus:outline-none"
          >
            toggle visibility
          </a>
          <ToggleButton
            bind:checked={isEnabled}
            on:change={toggleEnabled}
            checkedLabel="Active"
            uncheckedLabel="Inactive"
            containerCss="float-right -mt-1"
          />
          {#if !isEnabled && product.status == "draft"}
            <button
              on:click|preventDefault={remove}
              class="button-subtle float-right mr-4">Remove Permanently</button
            >
          {/if}
        {/if}

        {#each variant.attribute_values as attribute, i}
          {attribute.value}
          {#if i + 1 !== variant.attribute_values.length}
            <span
              class="inline-block w-2 h-2 ml-1 mr-2 rounded-full bg-secondary-300"
            />
          {/if}
        {/each}
      </h2>
    {/if}

    {#if !(closeVariant || reorder)}
      <div class="grid grid-cols-3 gap-y-6 gap-x-16 py-4" transition:slide>
        <div class="row-span-3 pt-6 col-start-3">
          <p class="label">Variant Image</p>
          <FileUpload
            attachmentType="Variant"
            kind="primary"
            id={variant.id}
            {medium}
          />
        </div>

        {#if properties.has_price}
          <div class="col-span-2 grid gap-y-2 gap-x-4 grid-cols-2 ">
            <h4 class="text-xs uppercase tracking-wide col-span-2 font-medium">
              Price
            </h4>

            <MoneyField
              bind:amountInRupees={list_price_units_rupees}
              bind:amountInPaisa={variant.list_price_units}
              label="List Price"
            />
            <MoneyField
              bind:amountInRupees={price_units_rupees}
              bind:amountInPaisa={variant.price_units}
              label="Selling Price"
            />
          </div>
        {/if}

        {#if properties.maintain_stock}
          <div
            class="col-span-2 grid gap-y-2 gap-x-4 grid-cols-2 py-4 border-t border-primary-200 border-solid "
          >
            <h4 class="text-xs uppercase tracking-wide col-span-2 font-medium">
              Stock
            </h4>
            <Field bind:field={variant.sku} label="SKU" error={errors.sku} />

            <div>
              {#if variant.sku}
                <label class="label block ml-6" for="stock">Stock</label>
                <AddStock {variant} stock={variant.stock_items} />
              {/if}
            </div>

            <div>
              <input
                type="checkbox"
                name="is_backorderable"
                bind:checked={variant.is_backorderable}
              />
              <label class="label inline-block" for="is_backorderable"
                >Backorderable</label
              >
            </div>
          </div>
        {/if}

        <div
          class="col-span-2 grid gap-y-2 gap-x-4 grid-cols-2 py-4 border-t border-primary-200 border-solid "
        >
          <h4 class="text-xs uppercase tracking-wide col-span-2 font-medium">
            Tax
          </h4>
          <Field bind:field={variant.tax_number} label="HSN/SAC" />

          <label class=""
            >GST Rate(%)
            <select
              bind:value={variant.store_tax_code_id}
              name="store_tax_code"
              class="mt-1"
            >
              <option name="null" value=""> None </option>
              {#each storeTaxCodes as storeTaxCode}
                <option
                  selected={variant.store_tax_code_id == storeTaxCode.id}
                  name={storeTaxCode.name}
                  value={storeTaxCode.id}
                >
                  {storeTaxCode.name}
                </option>
              {/each}
            </select>
          </label>
        </div>

        <div
          class="col-span-2 grid gap-y-2 gap-x-4 grid-cols-2 py-4 border-t border-primary-200 border-solid "
        >
          <h4 class="text-xs uppercase tracking-wide col-span-2 font-medium">
            Shipping
          </h4>

          <div>
            <label class="label block" for="sku"
              >Dimensions(Length x Width x Height in mm)</label
            >
            <div class="input-field grid grid-cols-5 gap-1 m-0 p-0">
              <input
                type="number"
                id="dimensions"
                name="length"
                bind:value={variant.properties.length}
                class="w-full border-none text-center px-0"
              />
              <span class="text-center mt-2 text-primary-300">×</span>
              <input
                type="number"
                id="dimensions"
                name="width"
                bind:value={variant.properties.width}
                class="w-full border-none text-center px-0"
              />
              <span class="text-center mt-2 text-primary-300">×</span>
              <input
                type="number"
                id="dimensions"
                name="height"
                bind:value={variant.properties.height}
                class="w-full border-none text-center px-0"
              />
            </div>
          </div>
          <div>
            <label class="label block">Weight (grams)</label>
            <input
              type="number"
              bind:value={variant.properties.weight}
              class="w-full  px-2"
            />
          </div>
        </div>
      </div>
    {/if}
  </form>
{/if}
