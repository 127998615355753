<script>
  import debounce from "lodash/debounce";
  import isEqual from "lodash/isEqual";
  import clone from "lodash/clone";
  import { jsonHeaders, securityToken } from "../shared/utils.js";

  import RadioButtonsPill from "./../forms/RadioButtonsPill.svelte";
  import MoneyField from "./../forms/MoneyField.svelte";
  import { onMount } from "svelte";

  export let campaign,
    url,
    activatePath,
    deactivatePath,
    coupons,
    extendExpiryPath;

  let discountTypes = [
    { value: "flat", label: "₹" },
    { value: "percentage", label: "%" },
  ];

  let disableMakeLive = true;
  let disableExtendExpiry = false;

  let minValue = 0,
    discountValue = 0;

  let prevCampaign = {};

  if (campaign.starts_at) {
    campaign.starts_at = campaign.starts_at.slice(0, 16);
  }

  if (campaign.ends_at) {
    campaign.ends_at = campaign.ends_at.slice(0, 16);
  }

  if (campaign.kind === "single-use") {
    campaign.settings = Object.assign(
      { single_use_quantity: 0 },
      campaign.settings
    );
    campaign.settings = Object.assign(
      { single_use_min_cart_value: 0 },
      campaign.settings
    );
    campaign.settings = Object.assign(
      { discount_type: "flat" },
      campaign.settings
    );
    campaign.settings = Object.assign({ discount_value: 0 }, campaign.settings);
  }

  const destroy = () => {
    let confirmed = confirm(
      "Are you sure you want to delete this coupon campaign?"
    );

    if (confirmed) {
      fetch(url, {
        method: "DELETE",
        headers: jsonHeaders(),
      }).then((response) => {
        if (response.status === 200) {
          window.location.href = "/admin/coupon_campaigns?status=draft";
        }
      });
    }
  };

  const save = debounce(() => {
    if (!isEqual(prevCampaign, campaign)) {
      console.log("saving campaign...");
      fetch(url, {
        method: "PATCH",
        headers: jsonHeaders(),
        body: JSON.stringify({ coupon_campaign: campaign }),
      })
        .then((response) => response.json())
        .then((data) => {
          if ("errors" in data) {
          } else {
            prevCampaign = JSON.parse(JSON.stringify(campaign));
          }
        });
    }
  }, 1000);

  $: save(campaign);

  $: {
    if (campaign.starts_at && campaign.ends_at) {
      disableMakeLive = false;
    }
  }

  onMount(() => {
    prevCampaign = JSON.parse(JSON.stringify(campaign));
    minValue = campaign.settings.single_use_min_cart_value / 100;
    discountValue = campaign.settings.discount_value / 100;
  });
</script>

<div class="p-8 grid grid-cols-5 gap-0">
  <h2 class="h2 mt-4 mb-8 col-span-3 overflow-hidden">
    <a href="/admin/coupon_campaigns" class="float-left w-8 pt-2">←</a>
    <input
      placeholder="Enter Campaign Name"
      bind:value={campaign.name}
      type="text"
      id="name"
      class="w-10/12 bg-transparent border-none ring-0 shadow-none"
    />
  </h2>
  <div class="col-span-3 grid gap-y-8 gap-x-6 grid-cols-2 mt-6">
    <div>
      <label class="label block" for="starts-at">Start Date</label>
      <input
        type="datetime-local"
        id="starts-at"
        bind:value={campaign.starts_at}
        class="w-full"
      />
    </div>

    <div>
      <label class="label block" for="ends-at">End Date</label>
      <input
        type="datetime-local"
        id="ends-at"
        bind:value={campaign.ends_at}
        class="w-full"
      />
    </div>
    {#if campaign.status === "draft" && coupons.length === 0}
      <div>
        <label class="label block" for="prefix">Coupon Code Prefix</label>
        <input
          type="text"
          id="prefix"
          bind:value={campaign.prefix}
          class="w-full"
        />
      </div>

      {#if campaign.kind == "single-use"}
        <div>
          <label class="label block" for="single_use_quantity"
            >Number of coupons</label
          >
          <input
            type="text"
            id="single_use_quantity"
            bind:value={campaign.settings.single_use_quantity}
            class="w-full"
          />
        </div>

        <div>
          <label class="label block" for="discount_value">Discount Type</label>
          <span class="block w-24">
            <RadioButtonsPill
              options={discountTypes}
              bind:field={campaign.settings.discount_type}
            />
          </span>
        </div>
        <div>
          {#if campaign.settings.discount_type === "flat"}
            <MoneyField
              bind:amountInRupees={discountValue}
              bind:amountInPaisa={campaign.settings.discount_value}
              label="Discount Value"
            />
          {:else}
            <label class="label block" for="discount_value"
              >Discount Value</label
            >
            <input
              type="text"
              id="discount_value"
              bind:value={campaign.settings.discount_value}
              class="w-full"
            />{/if}
        </div>

        <h3
          class="col-span-2 text-xs font-medium uppercase -mb-4 pt-4 border-t border-primary-300"
        >
          Condition
        </h3>
        <div>
          <MoneyField
            bind:amountInRupees={minValue}
            bind:amountInPaisa={campaign.settings.single_use_min_cart_value}
            label="Minimum Cart Value"
          />
        </div>
      {/if}
    {/if}
  </div>

  <div class="mt-6 pt-8 text-right col-span-2">
    {#if campaign.status === "active"}
      <form action={deactivatePath} method="post" class="inline">
        <input
          type="hidden"
          name="authenticity_token"
          value={securityToken()}
        />
        <button class="button-primary mr-2">Disable</button>
      </form>
    {:else}
      <form action={activatePath} method="post" class="inline">
        <input
          type="hidden"
          name="authenticity_token"
          value={securityToken()}
        />
        <button class="button-primary mr-2" disabled={disableMakeLive}
          >Make Live</button
        >
      </form>
      <form action={extendExpiryPath} method="post" class="inline">
        <input
          type="hidden"
          name="authenticity_token"
          value={securityToken()}
        />
        <button class="button-primary mr-2" disabled={disableExtendExpiry}
          >Extend Expiry
        </button>
      </form>
    {/if}

    {#if campaign.status === "draft"}
      <a href="#" class="button" on:click={destroy}>Discard</a>
      <p class="text-sm mt-2 text-gray-500">
        Your discount is autosaved as a draft <br />till you make it live.
      </p>
    {/if}
  </div>
</div>
