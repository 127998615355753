<script>
  import Field from "./../forms/Field.svelte";
  export let selectedCustomer,
    selectedVariants,
    paymentDetails,
    shippingAddress,
    showReviewOrder;
</script>

<div>
  {#if showReviewOrder}
    <div class="flex items-start text-xs ml-4 w-2/5">
      {shippingAddress.name},
      {shippingAddress.line1},
      {shippingAddress.line2},
      {shippingAddress.city},
      {shippingAddress.state_name},
      {shippingAddress.postal_code}
    </div>
  {:else}
    <form on:submit|preventDefault>
      <Field
        bind:field={shippingAddress.name}
        label="Customer Name"
        name="shipping_address[name]"
      />
      <Field
        bind:field={shippingAddress.phone}
        label="Phone Number"
        name="shipping_address[phone]"
      />
      <Field
        bind:field={shippingAddress.email}
        label="Email"
        name="shipping_address[email]"
      />
      <Field
        bind:field={shippingAddress.line1}
        label="Address Line 1"
        name="shipping_address[line1]"
      />
      <Field
        bind:field={shippingAddress.line2}
        label="Address Line 2"
        name="shipping_address[line2]"
      />
      <Field
        bind:field={shippingAddress.postal_code}
        label="Postal Code"
        name="shipping_address[postal_code]"
      />
      <Field
        bind:field={shippingAddress.city}
        label="City"
        name="shipping_address[city]"
      />
      <Field
        bind:field={shippingAddress.state_name}
        label="State"
        name="shipping_address[state_name]"
      />
      <Field
        bind:field={shippingAddress.country_code}
        label="Country Code"
        name="shipping_address[country_code]"
      />
    </form>
  {/if}
</div>
