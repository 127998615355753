<style>
  .path-previous {
    background-color: #b1aafd;
  }

  .path-next {
    background-color: #f65a93;
  }
</style>

<script>
  import Callout from "./Callout.svelte";
  import ChartContainer from "./ChartContainer.svelte";
  import TableContainer from "./TableContainer.svelte";
  import DatePicker from "../forms/DatePicker.svelte";
  import FilterSelect from "./FilterSelect.svelte";
  import AccountSelect from "./AccountSelect.svelte";

  export let filters, blocks;

  const nanoSecondsInDay = 24 * 60 * 60 * 1000;

  const parseDate = (date) => {
    let timezoneOffset = new Date().getTimezoneOffset() * 60000;
    return new Date(date - timezoneOffset).toISOString().split("T")[0];
  };

  const updateDate = (event) => {
    let start_date = event.detail.start;
    let end_date = event.detail.end;
    let difference = end_date - start_date;

    let compare_end_date = new Date(start_date - nanoSecondsInDay);
    let compare_start_date = new Date(compare_end_date - difference);

    filters.compare_start_date = parseDate(compare_start_date);
    filters.compare_end_date = parseDate(compare_end_date);
  };

  const updateCompareDate = () => {
    let difference = new Date(filters.end_date) - new Date(filters.start_date);

    let compareDateDifference =
      new Date(filters.compare_end_date) - new Date(filters.compare_start_date);

    if (
      difference > compareDateDifference ||
      difference < compareDateDifference
    ) {
      filters.compare_end_date = parseDate(
        new Date(new Date(filters.compare_start_date) - -difference)
      );
    }
  };
</script>

<div class="p-8 gap-0 grid product-container grid-cols-12 gap-8">
  <h2 class="h2 mt-4 overflow-hidden font-medium col-span-4">Analytics</h2>
  <div class="actions col-span-8 text-right">
    <span class="w-4 h-4 path-next  rounded-lg inline-block" />
    <DatePicker
      bind:start_date={filters.start_date}
      bind:end_date={filters.end_date}
      on:change={updateDate}
    />
    <span class="px-2 py-1">vs</span>
    <span class="w-4 h-4 path-previous rounded-lg inline-block" />
    <DatePicker
      bind:start_date={filters.compare_start_date}
      bind:end_date={filters.compare_end_date}
      on:change={updateCompareDate}
      extradata={filters}
    />
  </div>

  {#each blocks as block}
    {#if block.kind === "account-select"}
      <AccountSelect
        url={block.url}
        account_id={block.account_id}
        account_details={block.account_details}
        state={"account-selected"}
        bind:filters
      />
    {:else if block.kind === "chart"}
      <ChartContainer
        title={block.title}
        url={block.url}
        colspan={block.span}
        {filters}
      />
    {:else if block.kind === "table"}
      <TableContainer
        title={block.title}
        url={block.url}
        colspan={block.span}
        {filters}
      />
    {:else if block.kind === "callout"}
      <Callout
        title={block.title}
        url={block.url}
        icon={block.icon}
        colspan={block.span}
        {filters}
      />
    {:else if block.kind === "select"}
      <FilterSelect
        filter={block.filter}
        options={block.options}
        bind:filters
      />
    {/if}
  {/each}
</div>
