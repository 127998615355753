<script>
  import { onMount } from "svelte";
  import { jsonHeaders } from "../../shared/utils.js";

  export let product, url, should_redirect_to_edit_after_create;

  let nameInput,
    valid = true;

  onMount(() => nameInput.focus());

  const createProduct = () => {
    if (valid) {
      fetch(url, {
        method: "POST",
        headers: jsonHeaders(),
        body: JSON.stringify({ product: product }),
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          }
        })
        .then((data) => {
          product = data.product;
          if (should_redirect_to_edit_after_create) {
            console.log("Redirecting to edit");
            location.href = `${url}/${product.id}/edit`;
          } else {
            console.log("Using location refresh");
            window.history.replaceState(
              null,
              null,
              `${url}/${product.id}/edit`
            );
          }
        });
    }
  };

  const isValid = () => {
    valid = true;

    if (!(product.name && product.name.length > 0)) {
      valid = false;
    }

    if (!(product.sku && product.sku.length > 0)) {
      valid = false;
    }
  };

  $: isValid(product);
</script>

<form action={url} class="p-8">
  <h2 class="h2 mt-4 mb-8 col-span-3 overflow-hidden">
    <a href="/admin/products?kind=material" class="float-left w-8 pt-2">←</a>
    <input
      placeholder="Enter Product Name"
      bind:this={nameInput}
      bind:value={product.name}
      type="text"
      class="w-10/12 bg-transparent border-none ring-0 shadow-none"
    />
    <input
      placeholder="Enter SKU"
      bind:value={product.sku}
      type="text"
      class="w-10/12 bg-transparent border-none ring-0 shadow-none"
    />
  </h2>

  <div class="col-span-2 text-center">
    <button
      disabled={!valid}
      on:click|preventDefault={createProduct}
      class="button-primary w-48 mt-4">Next</button
    >
    <a href="/admin/products?kind=material" class="block mt-12 text-sm"
      >Cancel</a
    >
  </div>
</form>
