<script>
  import debounce from "lodash/debounce";
  import { fade } from "svelte/transition";
  import { jsonHeaders, jsonFileUploadHeaders } from "../../shared/utils.js";
  import ProductTabs from "../../product/ProductTabs.svelte";

  import ProductCategory from "../../product/ProductCategory.svelte";
  import Field from "../../forms/Field.svelte";
  import ReplaceTileImage from "./ReplaceTileImage.svelte";
  import MaterialMedia from "./MaterialMedia.svelte";

  export let material_extra,
    url,
    originalProductUrl,
    categoriesUrl,
    materialImageUploadUrl,
    mediaUploadUrl,
    mediaDeleteUrl,
    taxNumbers,
    storeTaxCodes;

  let fileName = "";
  let imageSrc = "";

  const readFileName = (input) => {
    if (input.target.files && input.target.files[0]) {
      fileName = input.target.files[0].name;

      const reader = new FileReader();

      reader.onload = function (e) {
        imageSrc = e.target.result;
      };

      reader.readAsDataURL(input.target.files[0]);
    }
  };

  let errors = {},
    fileUploadErrors = {},
    showImageReplaceForm = false,
    imageUploadInProgress = false,
    productUrl = `${url}`,
    imageUploadInProgressCount = 0,
    imageUploadInProgressTimer = null;

  const save = debounce(() => {
    console.log("save product");
    submitUpdate(`${productUrl}`, "PATCH");
  }, 2000);

  const submitUpdate = (url, method) => {
    fetch(url, {
      method: method,
      headers: jsonHeaders(),
      body: JSON.stringify({ material_extra: material_extra }),
    })
      .then((response) => response.json())
      .then((data) => {
        if ("errors" in data) {
          errors = data.errors;
          material_extra = data;
          console.log(errors);
        } else {
          errors = {};
          material_extra = data;
          console.log("success");
        }
      });
  };

  const toggleReplaceFile = () => {
    showImageReplaceForm = !showImageReplaceForm;
    imageUploadInProgressTimer && clearInterval(imageUploadInProgressTimer);
  };

  const uploadFile = (event) => {
    const formData = new FormData(event.target);
    imageUploadInProgress = true;
    fileUploadErrors = {};
    imageUploadInProgressTimer && clearInterval(imageUploadInProgressTimer);
    imageUploadInProgressCount = 5;
    imageUploadInProgressTimer = setInterval(() => {
      imageUploadInProgressCount = imageUploadInProgressCount + 5;
      if (imageUploadInProgressCount > 100) {
        imageUploadInProgressCount = 100;
      }
    }, 500);
    fetch(materialImageUploadUrl, {
      method: "POST",
      headers: jsonFileUploadHeaders(),
      body: formData,
    })
      .then((response) =>
        response.json().then((data) => ({
          code: response.status,
          responseJson: data,
        }))
      )
      .then((data) => {
        if (data.code === 200) {
          location.href = `${productUrl}/edit`;
          fileUploadErrors = {};
        } else {
          fileUploadErrors = data.responseJson.errors;
        }
      })
      .finally(() => {
        console.log("Finally invoked");
        imageUploadInProgress = false;
        imageUploadInProgressCount = 0;
        imageUploadInProgressTimer && clearInterval(imageUploadInProgressTimer);
      });
  };
</script>

<div transition:fade class="p-8 gap-0 grid product-container">
  <h2 class="h2 mt-4 mb-8 overflow-hidden col-span-2">
    <a href="/admin/products?kind=material" class="float-left w-8 pt-2">←</a>
    <input
      placeholder="Enter Product Name"
      bind:value={material_extra.name}
      on:input={save}
      type="text"
      class="w-10/12 bg-transparent border-none ring-0 shadow-none"
    />
  </h2>
  <ProductTabs {url} selectedTab={"details"} showMarketing={false} />

  <div
    class="col-span-3 grid product-container gap-y-8 gap-x-16 bg-white text-primary-800 border border-primary-300 p-8"
  >
    <form action={productUrl} class="col-span-2" on:input={save}>
      <Field bind:field={material_extra.sku} label="SKU" error={errors.sku} />
      <Field bind:field={material_extra.tax_number} label="HSN/SAC" />

      <label class="">GST Rate(%)</label>
      <select
        bind:value={material_extra.store_tax_code_id}
        name="store_tax_code"
        class=""
      >
        <option name="null" value=""> None </option>
        {#each storeTaxCodes as storeTaxCode}
          <option
            selected={material_extra.store_tax_code_id == storeTaxCode.id}
            name={storeTaxCode.name}
            value={storeTaxCode.id}
          >
            {storeTaxCode.name}
          </option>
        {/each}
      </select>
      <Field
        bind:field={material_extra.summary}
        label="Summary"
        error={errors.summary}
      />
      <Field
        bind:field={material_extra.description}
        label="Description"
        error={errors.description}
      />
      <Field
        bind:field={material_extra.price_units}
        label="Price (in Rupees)"
        error={errors.price_units}
      />
      <Field
        bind:field={material_extra.brand_name}
        label="Brand Name"
        error={errors.brand_name}
      />
      <Field
        bind:field={material_extra.tags}
        label="Tags"
        error={errors.tags}
      />
      <Field
        bind:field={material_extra.color}
        label="Color"
        error={errors.color}
      />
      <div class="grid grid-cols-3 gap-4">
        <Field
          bind:field={material_extra.length}
          label="Length"
          error={errors.length}
        />
        <Field
          bind:field={material_extra.width}
          label="Width"
          error={errors.width}
        />
        <Field
          bind:field={material_extra.height}
          label="Height"
          error={errors.height}
        />
      </div>
      <Field
        bind:field={material_extra.size}
        label="Size"
        error={errors.size}
      />
      <Field
        bind:field={material_extra.size_measurement_units}
        label="Size Measurement Units"
        error={errors.size_measurement_units}
      />
      <Field
        bind:field={material_extra.material_finish}
        label="Material finish"
        error={errors.material_finish}
      />
      <label>Status</label>
      <select
        bind:value={material_extra.status}
        class="select-small"
        name=""
        id=""
      >
        <option value="active">Active</option>
        <option value="inactive">Inactive</option>
      </select>
    </form>
    <div>
      <h4 class="text-xs uppercase tracking-wide col-span-2 font-medium mt-4">
        ORGANIZATION
      </h4>
      <ProductCategory
        bind:product={material_extra}
        {categoriesUrl}
        productUrl="{originalProductUrl}/{material_extra.id}"
        on:save={save}
      />
      <h4
        class="text-xs uppercase tracking-wide col-span-2 font-medium mt-16 mb-2"
      >
        Variant Image
      </h4>
      <div class="border border-primary-300 bg-white p-8 col-span-1">
        <img
          src=" {material_extra.image}"
          class="w-full h-full object-contain"
        />
      </div>
      <div>
        <button
          class="button-primary mt-6"
          on:click|preventDefault={toggleReplaceFile}>Replace Image</button
        >
      </div>
    </div>
  </div>
</div>
{#if showImageReplaceForm}
  <ReplaceTileImage {materialImageUploadUrl} {toggleReplaceFile} {productUrl} />
{/if}

<MaterialMedia
  {mediaDeleteUrl}
  {mediaUploadUrl}
  {productUrl}
  material={material_extra}
/>

<style>
  .product-container {
    grid-template-columns: 1fr 1fr 16rem;
  }
</style>
