<script>
  export let category, selectedCategory, select, reorder, url, errors;
  import kebabIcon from "./../../images/icons/kebab.svg";
  import EditCategory from "./EditCategory.svelte";
  import { createEventDispatcher } from "svelte";
  import { jsonHeaders } from "../shared/utils.js";

  let apiUpdateInProgress = false;

  let showMenu = false,
    edit = false;
  const dispatch = createEventDispatcher();
  const selectCategory = () => dispatch("message", {});
  const onEditSuceeded = () =>
    dispatch("categoryChange", { category: category });
  const onDeleteSucceeded = () =>
    dispatch("categoryDeleted", { category: category });
  const toggleMenu = () => (showMenu = !showMenu);
  const hideMenu = () => (showMenu = false);
  const editCategory = () => {
    hideMenu();
    edit = true;
  };
  const cancelEditCategory = () => (edit = false);
  const sendCategory = () => {
    hideMenu();
    dispatch("moveCategory", { category: category });
  };
  const deleteCategory = () => {
    if (window.confirm("Are you sure?")) {
      apiUpdateInProgress = true;

      fetch(`${url}/${category.id}`, {
        method: "DELETE",
        headers: jsonHeaders(),
      })
        .then((response) => response.json())
        .then((data) => {
          if ("errors" in data) {
            errors = data.errors;
          } else {
            errors = {};
            onDeleteSucceeded(category);
            location.href = "/admin/categories";
          }
        });
    }
  };
</script>

<div
  on:click={selectCategory}
  class="bg-white relative cursor-pointer px-8 py-2 border-b border-gray-300 {!selectedCategory ||
  selectedCategory === category
    ? 'text-gray-800'
    : 'text-gray-500'}"
>
  {#if !selectedCategory}
    <span>
      {#if select}
        <input
          type="checkbox"
          id="checkbox_{category.id}"
          bind:checked={category.checked}
          on:click|stopPropagation
        />
      {/if}
      {#if reorder}<button
          class="absolute left-1 top-7 icon icon-drag-handle cursor-move float-left focus:outline-none"
          >reorder</button
        >{/if}
    </span>
  {/if}

  <label for="checkbox_{category.id}">
    {category.name}
  </label>

  <span class="text-sm block">
    {category.sub_categories_count} sub categories • {category.products_count} products
  </span>

  {#if !selectedCategory}
    <button
      on:click|stopPropagation={toggleMenu}
      class="absolute top-0 right-0 h-full p-2 outline-none"
    >
      <img src={kebabIcon} alt="" />
    </button>
  {/if}

  {#if showMenu}
    <div
      class="fixed opacity-5 bg-black inset-0 z-10"
      on:click|stopPropagation={toggleMenu}
    >
      &nbsp
    </div>
    <ul
      class="nav absolute top-1/2 mt-4 py-2 left-60 w-48 rounded-lg bg-white shadow-xl text-sm border border-primary-200 z-20"
    >
      <li>
        <button
          on:click|stopPropagation={editCategory}
          class="block outline-none w-full px-4 py-1 text-left">Edit...</button
        >
      </li>
      <li>
        <button
          on:click|stopPropagation={sendCategory}
          class="block outline-none w-full px-4 py-1 text-left"
          >Move To...</button
        >
      </li>
      {#if category.products_count === 0 && category.sub_categories_count === 0}
        <li>
          <button
            on:click|stopPropagation={deleteCategory}
            class="block outline-none w-full px-4 py-1 text-left">Delete</button
          >
        </li>
      {/if}
    </ul>
  {/if}

  <EditCategory {category} {onEditSuceeded} bind:showForm={edit} {url} />
</div>
