<script>
  import debounce from "lodash/debounce";
  import isEqual from "lodash/isEqual";
  import clone from "lodash/clone";
  import { onMount } from "svelte";
  import { jsonHeaders } from "../shared/utils.js";
  import { fade } from "svelte/transition";
  import Field from "./../forms/Field.svelte";
  import FileUpload from "../forms/FileUpload.svelte";

  export let variant, url, product, properties;

  let medium,
    googleImage,
    fbImage,
    prevVariant = {};

  variant.metadata = Object.assign({ google_title: "" }, variant.metadata);
  variant.metadata = Object.assign(
    { google_description: "" },
    variant.metadata
  );
  variant.metadata = Object.assign({ facebook_title: "" }, variant.metadata);
  variant.metadata = Object.assign(
    { facebook_description: "" },
    variant.metadata
  );

  onMount(() => {
    prevVariant = clone(variant);
    setImage();
  });

  const setImage = () => {
    googleImage = variant.media.find(
      (media) => media.kind === "google-catalog"
    );
    fbImage = variant.media.find((media) => media.kind === "facebook-catalog");
  };

  const save = debounce(() => {
    if (!isEqual(prevVariant, variant)) {
      console.log("saving...");
      fetch(url(variant), {
        method: "PATCH",
        headers: jsonHeaders(),
        body: JSON.stringify(variant),
      })
        .then((response) => response.json())
        .then((data) => {
          if ("errors" in data) {
          } else {
            prevVariant = clone(variant);
          }
        });
    }
  }, 1000);
</script>

<form
  on:submit|preventDefault
  on:input={save}
  transition:fade
  action="../../../.."
  method="post"
  class="border-t border-primary-200 border-solid mb-8 relative grid gap-y-2 gap-x-4 grid-cols-3 mt-6"
>
  {#if properties.has_variants}
    <h2 class="text-md font-medium mb-2 capitalize mt-4 col-span-3">
      {#each variant.attribute_values as attribute, i}
        {attribute.value}
        {#if i + 1 !== variant.attribute_values.length}
          <span class="text-primary-300 px-1"> | </span>
        {/if}
      {/each}
    </h2>
  {/if}

  <div class="col-span-2">
    <Field
      bind:field={variant.metadata.google_title}
      label="G Title"
      placeholder={`${product.name} (${variant.name})`}
    />
    <Field
      bind:field={variant.metadata.google_description}
      label="G Description"
      placeholder={product.description}
    />
  </div>

  <div>
    <p class="label">Google Catalog Image</p>
    <FileUpload
      attachmentType="Variant"
      kind="google-catalog"
      id={variant.id}
      medium={googleImage}
    />
  </div>

  <div class="col-span-2">
    <Field
      bind:field={variant.metadata.facebook_title}
      label="FB Title"
      placeholder={`${product.name} (${variant.name})`}
    />
    <Field
      bind:field={variant.metadata.facebook_description}
      label="FB Description"
      placeholder={product.description}
    />
  </div>

  <div>
    <p class="label">FB Catalog Image</p>
    <FileUpload
      attachmentType="Variant"
      kind="facebook-catalog"
      id={variant.id}
      medium={fbImage}
    />
  </div>
</form>
