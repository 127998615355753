<script>
  import EditVariant from "./EditVariant.svelte";
  import FilterAttributes from "./FilterAttributes.svelte";
  import Reorder from "../shared/Reorder.svelte";

  export let variants, product, formUrl, properties, taxNumbers, storeTaxCodes;
  let deactivatedProperties = [],
    reorder = false;

  const remove = (variant) => {
    let index = variants.indexOf(variant);

    variants = [
      ...variants.slice(0, index),
      ...variants.slice(index + 1, variants.length),
    ];
  };

  const filterVariants = (event) =>
    (deactivatedProperties = event.detail.deactivatedProperties);
  const toggleReorder = () => (reorder = !reorder);
</script>

{#if properties.has_variants}
  <h3 class="text-lg font-semibold mb-4 mt-8 pt-4 border-t border-primary-300">
    Variants
  </h3>

  <div class="flex justify-between">
    <FilterAttributes
      attributes={product.variant_attributes}
      on:filter={filterVariants}
    />
    <div class="actions pt-2">
      <button on:click={toggleReorder} class="button">Reorder</button>
    </div>
  </div>
{/if}

<Reorder
  bind:items={variants}
  enable={reorder}
  url={`${formUrl}/reorder`}
  let:item
  let:index
>
  <EditVariant
    variant={item}
    {product}
    {formUrl}
    {properties}
    {taxNumbers}
    {storeTaxCodes}
    {deactivatedProperties}
    {reorder}
    on:remove={(e) => remove(item)}
  />
</Reorder>
