<script>
  import { fade } from "svelte/transition";
  import { jsonFileUploadHeaders } from "../../shared/utils.js";

  export let materialImageUploadUrl, productUrl, toggleReplaceFile;
  let fileName = "";
  let imageSrc = "";

  const readFileName = (input) => {
    if (input.target.files && input.target.files[0]) {
      fileName = input.target.files[0].name;

      const reader = new FileReader();

      reader.onload = function (e) {
        imageSrc = e.target.result;
      };

      reader.readAsDataURL(input.target.files[0]);
    }
  };

  let fileUploadErrors = {},
    imageUploadInProgress = false,
    imageUploadInProgressCount = 0,
    imageUploadInProgressTimer = null;

  const uploadFile = (event) => {
    const formData = new FormData(event.target);
    imageUploadInProgress = true;
    fileUploadErrors = {};
    imageUploadInProgressTimer && clearInterval(imageUploadInProgressTimer);
    imageUploadInProgressCount = 5;
    imageUploadInProgressTimer = setInterval(() => {
      imageUploadInProgressCount = imageUploadInProgressCount + 5;
      if (imageUploadInProgressCount > 100) {
        imageUploadInProgressCount = 100;
      }
    }, 500);
    fetch(materialImageUploadUrl, {
      method: "POST",
      headers: jsonFileUploadHeaders(),
      body: formData,
    })
      .then((response) =>
        response.json().then((data) => ({
          code: response.status,
          responseJson: data,
        }))
      )
      .then((data) => {
        if (data.code === 200) {
          location.href = `${productUrl}/edit`;
          fileUploadErrors = {};
        } else {
          fileUploadErrors = data.responseJson.errors;
        }
      })
      .finally(() => {
        console.log("Finally invoked");
        imageUploadInProgress = false;
        imageUploadInProgressCount = 0;
        imageUploadInProgressTimer && clearInterval(imageUploadInProgressTimer);
      });
  };
</script>

<div
  on:click|stopPropagation
  transition:fade
  class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 bg-black bg-opacity-75 fixed z-10 top-0 right-0 bottom-0 left-0"
>
  <span class="hidden sm:inline-block sm:align-middle sm:h-screen" />
  <div
    class="inline-block align-bottom bg-white rounded-lg p-6 text-left shadow-xl transform transition-all my-8 align-middle max-w-lg my-8 sm:align-middle w-160"
  >
    <form on:submit|preventDefault={uploadFile} action=".">
      <div class="row-span-3 pt-2 col-start-3">
        <h3 class="text-lg font-semibold mb-4">Upload new image</h3>
        <div
          class="image-upload-wrap bg-primary-100 border border-gray-300 shadow-sm rounded-md relative h-36 flex flex-wrap justify-center"
        >
          {#if fileName}
            <div class="text-center mt-2">
              <img
                id="preview-image"
                src={imageSrc}
                alt="preview image"
                class="w-24 h-24 object-contain overflow-hidden"
              />
              {fileName}
            </div>
          {:else}
            <span class=" icon icon-file mt-6 h-10 w-full" />
            <div class="text-center text-primary-800">
              <p>Drag and drop an image file</p>
              <p>or <span class="font-bold underline">Browse</span></p>
            </div>
          {/if}
          <input
            class="file-upload-input"
            type="file"
            id="file"
            name="file"
            on:change={readFileName}
            required
          />
        </div>
        {#if fileUploadErrors.file}
          <span class="field-error">{fileUploadErrors.file[0]}</span>
        {/if}
      </div>
      {#if !imageUploadInProgress}
        <div class="flex items-center">
          <button class="button-primary mt-6" type="submit">Upload File</button>
          <div class="flex-auto" />
          <button
            class="button mt-6"
            on:click|preventDefault={toggleReplaceFile}>Cancel</button
          >
        </div>
      {:else}
        <div class="relative mt-16">
          <div
            class="h-3 rounded-md bg-gray-200 absolute left-6 right-6 bottom-8 border border-white"
          >
            <div
              class="absolute top-0 left-0 h-full bg-primary-800 rounded-md"
              style="width: {imageUploadInProgressCount}%"
            />
          </div>
        </div>
      {/if}
    </form>
  </div>
</div>

<style>
  .file-upload-input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
  }
</style>
