<script>
  import { jsonHeaders } from "./shared/utils";

  export let order,
    regenerate = false;

  let invoice = {},
    download_path,
    showInvoice = false,
    regenrateTriggered = false;

  const getInvoice = () => {
    let url = `/admin/orders/${order.id}/invoice?regenerate=${regenerate}`;
    fetch(url, {
      method: "GET",
      headers: jsonHeaders(),
    })
      .then((response) => response.json())
      .then((data) => {
        if (regenerate === true) {
          regenrateTriggered = true;
        }
        invoice = data.invoice;
        download_path = data.download_path;
        showInvoice = true;
      });
  };
</script>

{#if regenrateTriggered}
  <span class="text-xs">Please check in a while</span>
{:else}
  <a href="#" on:click|preventDefault={getInvoice} class="button button-subtle"
    >{regenerate === true ? "Regenerate Invoice" : "View Invoice"}</a
  >
{/if}
{#if showInvoice}
  <div
    class="fixed z-10 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      />

      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true">&#8203;</span
      >

      <div
        class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle  sm:w-3/5 sm:p-6"
      >
        <div class="absolute -top-4 -right-4 ">
          <button
            type="button"
            class="bg-white px-2 pt-2 shadow-md rounded-full  text-gray-400 hover:text-gray-500"
            on:click|preventDefault={() => (showInvoice = false)}
          >
            <span class="icon icon-cross" />
          </button>
        </div>
        <div class="flex items-start border-b border-primary-900 pb-4 mb-4">
          <div class="flex-auto items-start">
            <h3 class="h2 text-primary-800">Tax Invoice</h3>
            <p class="text-primary-800 underline">{invoice.number}</p>
          </div>
          <a href={download_path} target="_blank" class="button-primary"
            >Download PDF</a
          >
        </div>
        <div class="flex  border-b border-primary-900 pb-4 mb-4">
          <div class="flex-auto">
            <p class="text-primary-500 mb-0">
              Order Number: <span class="font-bold text-primary-900"
                >{invoice.order_number}</span
              >
            </p>
            <p class="text-primary-500 mb-0">
              Order Date: <span class="font-bold text-primary-900"
                >{new Date(order.confirmed_at).toUTCString()}</span
              >
            </p>
            <p class="text-primary-500 mb-0">
              Invoice Date: <span class="font-bold text-primary-900"
                >{new Date(invoice.created_at).toUTCString()}</span
              >
            </p>
            <p class="text-primary-500 mb-0">
              Payment Method: <span class="font-bold text-primary-900"
                >{invoice.payments[0].payment_mode}</span
              >
            </p>
            <p class="text-primary-500 mb-0">
              GST: <span class="font-bold text-primary-900"
                >{invoice.business_details.gst_number}</span
              >
            </p>
            <p class="text-primary-500 mb-0">
              PAN: <span class="font-bold text-primary-900"
                >{invoice.business_details.pan_number}</span
              >
            </p>
            <p class="text-primary-500 mb-0">
              CIN: <span class="font-bold text-primary-900"
                >{invoice.business_details.cin_number}</span
              >
            </p>
          </div>
          <div class="text-right flex flex-col">
            <p class="text-primary-500 mb-0 flex-auto">
              Place of Supply: <span class="font-bold text-primary-900"
                >{invoice.business_details.address.state_name}</span
              >
            </p>
            <div>
              <p class="font-bold mb-0 capitalize">
                {invoice.business_details.address.name}
              </p>
              <p class="mb-0">{invoice.business_details.address.line1}</p>
              <p class="mb-0">
                {invoice.business_details.address.city}, {invoice
                  .business_details.address.state_name}, {invoice
                  .business_details.address.postal_code}
              </p>
              <p class="text-primary-500 mb-0">
                Phone: <span class="font-bold text-primary-900"
                  >{invoice.business_details.address.phone}</span
                >
              </p>
            </div>
          </div>
        </div>
        <table class="w-full">
          <thead>
            <tr
              class="border-b border-gray-300 text-xs text-primary-500 uppercase tracking-wider"
            >
              <th />
              <th class="font-medium  px-2 py-3 text-left">Product</th>
              <th class="font-medium  px-2 py-3 text-left">Price</th>
              <th class="font-medium  px-2 py-3 text-left">QTY</th>
              <th class="font-medium  px-2 py-3 text-left">Amount</th>
              <th class="font-medium  px-2 py-3 text-left">Tax</th>
              <th class="font-medium  px-2 py-3 text-left">Total</th>
            </tr>
          </thead>
          <tbody class="text-primary-800">
            {#each invoice.order_details.line_items as item, index (item.order_item.sku)}
              <tr class="border-b border-gray-300">
                <td class="px-2 py-1">{index + 1}</td>
                <td class="px-2 py-1">
                  <p class="font-bold mb-0">{item.order_item.product_name}</p>
                  {#if item.order_item.attributes}
                    {#each item.order_item.attributes as variant}
                      <p class="text-primary-400 text-xs m-0">
                        {variant.kind}: {variant.value}
                      </p>
                    {/each}
                  {/if}
                </td>
                <td class="px-2 py-1">₹{item.order_item.unit_price}</td>
                <td class="px-2 py-1">{item.order_item.quantity}</td>
                <td class="px-2 py-1">₹{item.order_item.net_amount}</td>
                <td class="px-2 py-1">
                  {#if item.order_item && item.order_item.taxes}
                    {#each item.order_item.taxes as tax}
                      <p>
                        {tax.rate}%
                        <span class="uppercase" />{tax.type}
                      </p>
                      <p class="font-bold">₹{tax.amount}</p>
                    {/each}
                  {/if}
                </td>
                <td class="px-2 py-1">₹{item.order_item.total_amount}</td>
              </tr>
              {#if item.shipping_charges}
                <tr>
                  <td />
                  <td>Shipping</td>
                  <td class="font-bold my-4"
                    >₹{item.shipping_charges.unit_price}</td
                  >
                  <td class="font-bold my-4"
                    >{item.shipping_charges.quantity}</td
                  >
                  <td class="font-bold my-4"
                    >₹{item.shipping_charges.net_amount}</td
                  >
                  <td class="text-right font-bold">
                    {#if item.shipping_charges && item.shipping_charges.taxes}
                      {#each item.shipping_charges.taxes as tax}
                        <p>
                          {tax.rate}%
                          <span class="uppercase" />{tax.type}
                        </p>
                        <p class="font-bold">₹{tax.amount}</p>
                      {/each}
                    {/if}
                  </td>
                  <td
                    ><span class="font-bold"
                      >₹{item.shipping_charges.total_amount}</span
                    ></td
                  >
                </tr>
              {/if}
            {/each}
          </tbody>
        </table>
        <div class="flex mt-4 border-t border-primary-900 mt-2 pl-8 pr-2">
          <p class="flex-auto font-bold">Total</p>
          <p class="">₹{invoice.order_details.total_amount}</p>
        </div>
        <p class="text-right text-sm text-primary-500">
          Whether tax is payable under reverse charge: {invoice.tax_payable_under_reverse_charge}
        </p>
      </div>
    </div>
  </div>
{/if}
