<script>
  export let filters, options;
</script>

<select name="staff" id="" bind:value={filters.staff} class="col-span-full">
  <option value="all">All Staff</option>

  {#each options as option}
    <option value={option.value}>{option.label}</option>
  {/each}
</select>
