<style>
  .product-list {
    height: 60vh;
    max-height: 360px;
  }
</style>

<script>
  import { jsonHeaders } from "../shared/utils.js";
  import { fade } from "svelte/transition";
  import { onMount } from "svelte";

  export let showForm, url, productsUrl, coupons;

  let search = "",
    products = [],
    newCouponUrl = `${url}/coupons/`;
  const productIds = coupons.map((coupon) => coupon.variant.product.id);

  const getProducts = (url = productsUrl) => {
    if (search) {
      url = `${productsUrl}?search=${search}`;
    }
    fetch(url, {
      method: "GET",
      headers: jsonHeaders(),
    })
      .then((response) => response.json())
      .then((data) => {
        products = data;
      });
  };

  const addCoupon = (product) => {
    if (!productIds.includes(product.id)) {
      fetch(newCouponUrl, {
        method: "POST",
        headers: jsonHeaders(),
        body: JSON.stringify({
          product_id: product.id,
          discount_type: "flat",
          type: "VariantCoupon",
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          coupons = coupons.concat(data);
          showForm = false;
        });
    }
  };

  const searchProducts = () => {
    getProducts();
  };

  $: getProducts();
</script>

<div
  transition:fade
  class="flex items-center justify-center bg-black bg-opacity-75 fixed z-10 top-0 right-0 bottom-0 left-0"
>
  <form
    action="."
    method="post"
    class="container w-96 bg-white rounded-lg text-left shadow-xl transform transition-all border border-primary-200"
  >
    <button
      on:click|preventDefault={(e) => {
        showForm = false;
      }}
      class="absolute icon icon-cross p-1 top-0 right-0 -mt-2 -mr-2 px-1 text-center h-8 w-8 bg-white rounded-full"
      >delete
    </button>

    <header class="p-8 border-b border-primary-300">
      <h2 class="font-medium capitalize text-primary-800">Select Products</h2>
      <input
        bind:value={search}
        on:keyup={searchProducts}
        type="search"
        name="search"
        placeholder="Search"
        class="mt-4 w-full rounded-full text-sm"
      />
    </header>

    <ul class="product-list max-h-72 overflow-y-scroll">
      {#each products as product}
        <li
          class="py-3 px-8 relative {productIds.includes(product.id)
            ? 'bg-gray-100 text-gray-500'
            : ''}"
        >
          {product.name}
          <button
            class="button-transparent absolute right-8"
            on:click|preventDefault={(e) => addCoupon(product)}
            >{productIds.includes(product.id) ? "Added" : "Add"}</button
          >
        </li>
      {/each}
    </ul>
  </form>
</div>
