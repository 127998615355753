<script>
  import { jsonHeaders } from "../shared/utils.js";

  export let selectedVariants, showReviewOrder;

  let search = "",
    isSearchInProgress = false,
    variantList = [],
    stocks = {},
    searchActive = false,
    showProductsModal = false,
    total;

  const getVariants = () => {
    isSearchInProgress = true;
    const searchUrl = `/admin/variants?search=${search}`;
    const stocksUrl = `/admin/variants/stock_availability`;
    const variantsPromise = fetch(searchUrl, {
      method: "GET",
      headers: jsonHeaders(),
    });
    const stockPromise = (skus) =>
      fetch(stocksUrl, {
        method: "POST",
        headers: jsonHeaders(),
        body: JSON.stringify({ skus }),
      });

    variantsPromise
      .then((resp) => resp.json())
      .then((data) => {
        return stockPromise(data.map((item) => item.sku))
          .then((resp) => resp.json())
          .then((stocks) => ({
            variants: data,
            stocks,
          }));
      })
      .then((data) => {
        variantList = data.variants;
        stocks = data.stocks;
      })
      .finally(() => {
        isSearchInProgress = false;
      });
  };

  const onFocus = () => {
    searchActive = true;
    showProductsModal = !showProductsModal;
  };
  const onBlur = () => {
    searchActive = false;
    variantList = [];
    showProductsModal = !showProductsModal;
  };
  const selectvariant = (variant) => {
    const index = selectedVariants.findIndex((item) => item.id === variant.id);
    if (index < 0) {
      variant.quantity = 1;
      variant.total = variant.price_units;
      selectedVariants = [...selectedVariants, variant];
    }
    variantList = [];
    searchActive = false;
    search = "";
    showProductsModal = !showProductsModal;
  };

  const removeVariant = (variant) => {
    selectedVariants = [
      ...selectedVariants.filter((item) => item.id !== variant.id),
    ];
  };

  const reduceQuantity = (variant) => {
    const index = selectedVariants.findIndex((item) => item.id === variant.id);

    if (variant.quantity > 1) {
      if (index >= 0) {
        const item = selectedVariants[index];
        item.quantity = item.quantity - 1;
        item.total = Number.parseInt(item.price_units) * item.quantity;
        selectedVariants = [...selectedVariants];
      }
    }
  };
  const increaseQuantity = (variant) => {
    const index = selectedVariants.findIndex((item) => item.id === variant.id);
    if (index >= 0) {
      const item = selectedVariants[index];
      item.quantity = item.quantity + 1;
      item.total = Number.parseInt(item.price_units) * item.quantity;
      selectedVariants = [...selectedVariants];
    }
  };
  $: {
    total = selectedVariants.reduce(
      (previousValue, currentValue) => previousValue + currentValue.total,
      0
    );
  }
</script>

{#if selectedVariants.length > 0 || showReviewOrder}
  {#each selectedVariants as variant}
    {#if variant.status === "active"}
      <div class="py-4 pl-2 border-b border-secondary-200 mb-4">
        <div class="flex">
          <div class="flex-auto">
            <p class="text-primary-800 capitalize text-md">
              {variant.product.name} | {variant.name}
            </p>
            <p class="text-xs text-primary-800 capitalize leading-0 mb-0">
              Rs. {variant.total / 100}
            </p>
            <p class="text-sm text-primary-600 mt-1">
              Quantity {showReviewOrder ? variant.quantity : ""}
            </p>
            {#if !showReviewOrder}
              <div
                class="flex items-center w-24 rounded-md overflow-hidden border border-secondary-200 "
              >
                <a href="#" on:click|preventDefault={reduceQuantity(variant)}
                  ><span class="bg-primary-200 text-primary-600 px-2 py-1"
                    >-</span
                  ></a
                ><span class="text-primary-800 w-12 text-center text-sm"
                  >{variant.quantity}</span
                ><a href="#" on:click|preventDefault={increaseQuantity(variant)}
                  ><span class=" bg-primary-200 text-primary-600 px-2 py-1"
                    >+</span
                  ></a
                >
              </div>
            {/if}
          </div>
          <a
            href="#"
            class={showReviewOrder ? "hidden" : ""}
            on:click|preventDefault={removeVariant(variant)}
            ><span class="icon icon-cross bg-secondary-200 rounded-full" /></a
          >
        </div>
      </div>
    {/if}
  {/each}
  <p class="mx-2 flex items-center">
    <span class="flex-auto text-xs uppercase text-primary-500 ">Total</span
    ><span class="text-lg text-primary-800 font-bold">₹{total / 100}</span>
  </p>
{/if}
{#if showProductsModal}
  <div
    class="fixed z-10 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
        on:click={onBlur}
      />

      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen "
        aria-hidden="true">&#8203;</span
      >
      <div
        class="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full"
      >
        <button
          on:click={onBlur}
          class="absolute top-0 right-0 -mt-2 -mr-2 p-1 text-center h-8 w-8 icon icon-cross bg-50 bg-white rounded-full border border-primary-200"
          >close</button
        >
        <p class="text-sm text-secondary-400 ml-4 mt-4">Search for Variants</p>
        <div
          class="transform transition-all {searchActive
            ? 'searchActive'
            : 'pb-4'}"
        >
          <form action="" method="GET" class="m-4">
            <input
              bind:value={search}
              on:keyup={getVariants}
              type="search"
              autocomplete="off"
              autofocus
              name="search"
              placeholder="Search"
              class=" w-full rounded-full text-sm"
            />
          </form>
          <div
            class={searchActive
              ? "pl-4 max-h-96 overflow-y-scroll customers-container"
              : "hidden"}
          >
            {#each variantList as variant}
              <a
                href="#"
                on:click|preventDefault={selectvariant(variant)}
                class="py-4 px-8 border-b border-secondary-200 block"
              >
                <p class="text-primary-800 capitalize mb-0">
                  {variant.product.name} | {variant.name}
                </p>
                <p class="text-xs text-primary-800 capitalize leading-0 mb-0">
                  Rs. {variant.price_units / 100}
                </p>
                <p
                  class="flex items-start text-primary-600 text-xs leading-0 mb-0"
                >
                  <span class="icon icon-stock mr-2" />{stocks[variant.sku]} units
                </p>
              </a>
            {/each}
          </div>
        </div>
      </div>
    </div>
  </div>
{:else if !showReviewOrder}
  <a
    href="#"
    class="underline font-medium text-primary-800 text-sm ml-2 mt-4"
    on:click|preventDefault={onFocus}>+ Add Product</a
  >
{/if}
